export const handleCurrencySymbol = (currency, symbol = "£") => {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return symbol;
  }
};

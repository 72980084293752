import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// redux
import { closeMobileDropdown } from "../../redux/general/generalActions";

// assets
import "../../assets/styles/shared/footer.scss";

// components
const CookiesNotification = loadable(() => import("./CookiesNotification"));
const MobileDropdown = loadable(() => import("./MobileDropdown"));

const Footer = ({
  cookiePolicyAccepted,
  isDrawerOpened,
  closeDrawer,
  drawerChildren,
  drawerQueryToSet,
  defaultSelected,
}) => {
  return (
    <footer className="footer">
      <div className="footer--row footer--row__border">
        <div className="page-container">
          <Row>
            <Col
              md={{ span: 6, order: 1 }}
              xs={{ span: 24, order: 2 }}
              className="footer--logo-holder"
            >
              <Link to="/" className="footer--logo" aria-label="homepage">
                <img
                  src={`${process.env.REACT_APP_IMAGES_URL}la-new-logo-white.svg`}
                  alt="LoveAntiques"
                  title="LoveAntiques"
                  width={144}
                  height={20}
                  loading="lazy"
                />
              </Link>
            </Col>

            <Col md={{ span: 18, order: 2 }} xs={{ span: 24, order: 1 }}>
              <Row>
                <Col md={6} sm={12} xs={24} className="footer--column">
                  <p className="footer--column-title">More Information</p>

                  <Link to="/about-us" className="footer--column-link">
                    About Us
                  </Link>
                  <Link to="/faq" className="footer--column-link">
                    FAQ’s
                  </Link>
                  <Link
                    to="/dealer-testimonials"
                    className="footer--column-link"
                  >
                    Dealers Testimonials
                  </Link>
                  <Link
                    to="/web-design-for-antiques-dealers"
                    className="footer--column-link"
                  >
                    Antique dealers websites
                  </Link>
                  <Link to="/antiques/sold" className="footer--column-link">
                    Sold antiques archive
                  </Link>
                  <Link to="/sitemap" className="footer--column-link">
                    Site Map
                  </Link>
                </Col>

                <Col md={6} sm={12} xs={24} className="footer--column">
                  <p className="footer--column-title">Join Us</p>

                  <Link
                    to="/registration/dealers"
                    className="footer--column-link"
                  >
                    Join as Dealer
                  </Link>
                  <Link
                    to="/registration/collector"
                    className="footer--column-link"
                  >
                    Join as Collector
                  </Link>
                  <Link
                    to="/registration/trader"
                    className="footer--column-link"
                  >
                    Join Our Business Directory
                  </Link>
                </Col>

                <Col md={6} sm={12} xs={24} className="footer--column">
                  <p className="footer--column-title">Contact Us</p>

                  <Link to="/contact-us" className="footer--column-link">
                    Contact Us
                  </Link>
                  <Link to="/press-office" className="footer--column-link">
                    Press Office
                  </Link>
                </Col>

                <Col md={6} sm={12} xs={24} className="footer--column">
                  <p className="footer--column-title">Helpful Links</p>

                  <Link to="/blog" className="footer--column-link">
                    Blog
                  </Link>

                  <Link to="/directory/dealers" className="footer--column-link">
                    Dealer List
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <div className="footer--row footer--row__last">
        <div className="page-container">
          <Row className="footer--content-row">
            <Col
              md={{ span: 8, order: 1 }}
              xs={{ span: 24, order: 2 }}
              className="footer--content-column"
            >
              <div className="flex justify-center">
                <span className="footer--text">
                  ©{new Date().getFullYear()} LoveAntiques.com. All rights
                  reserved.
                </span>
              </div>
            </Col>

            <Col
              md={{ span: 8, order: 2 }}
              xs={{ span: 24, order: 1 }}
              className="footer--content-column"
            >
              <div className="flex justify-center">
                <a
                  href="https://www.facebook.com/loveantiquescom"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer--social-icon"
                  aria-label="Facebook"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/social/fb-icon.png`}
                    alt="Facebook"
                    title="Facebook"
                    width={24}
                    height={24}
                    loading="lazy"
                  />
                </a>

                <a
                  href="https://twitter.com/loveantiques"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer--social-icon"
                  aria-label="Twitter"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/social/twitter.png`}
                    alt="Twitter"
                    title="Twitter"
                    width={24}
                    height={24}
                    loading="lazy"
                  />
                </a>

                <a
                  href="https://www.pinterest.com/loveantiquescom/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer--social-icon"
                  aria-label="Pinterest"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/social/pinterest.png`}
                    alt="Pinterest"
                    title="Pinterest"
                    width={24}
                    height={24}
                    loading="lazy"
                  />
                </a>

                <a
                  href="https://www.instagram.com/loveantiques_com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer--social-icon"
                  aria-label="Instagram"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/social/instagram.png`}
                    alt="Instagram"
                    title="Instagram"
                    width={24}
                    height={24}
                    loading="lazy"
                  />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCzObXljtikONwkvmmhBeqJQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer--social-icon"
                  aria-label="YouTube"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/social/youtube.png`}
                    alt="YouTube"
                    title="YouTube"
                    width={24}
                    height={24}
                    loading="lazy"
                  />
                </a>
              </div>
            </Col>

            <Col
              md={{ span: 8, order: 3 }}
              xs={{ span: 24, order: 3 }}
              className="footer--content-column"
            >
              <div className="flex footer--text justify-center">
                <Link to="/privacy-policy" className="footer--link">
                  Privacy & Cookie Policy
                </Link>
                <span className="footer--divider">•</span>
                <Link to="/terms-and-conditions" className="footer--link">
                  Terms & Conditions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {!cookiePolicyAccepted && <CookiesNotification />}

      <MobileDropdown
        isDrawerOpened={isDrawerOpened}
        closeDrawer={closeDrawer}
        drawerChildren={drawerChildren}
        queryToSet={drawerQueryToSet}
        defaultSelected={defaultSelected}
      />
    </footer>
  );
};

Footer.propTypes = {
  cookiePolicyAccepted: PropTypes.bool,
  isDrawerOpened: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  drawerChildren: PropTypes.array,
  drawerQueryToSet: PropTypes.string,
  defaultSelected: PropTypes.string.isRequired,
};
export default connect(
  (state) => ({
    cookiePolicyAccepted: state.auth.isCookieAccepted,
    isDrawerOpened: state.general.isMobileDropdownOpened,
    drawerChildren: state.general.mobileDropdownChildren,
    drawerQueryToSet: state.general.dropdownQueryToSet,
    defaultSelected: state.general.dropdownDefaultSelected,
  }),
  {
    closeDrawer: closeMobileDropdown,
  }
)(Footer);

import { all, put, call, takeLatest } from "redux-saga/effects";
import { message } from "antd";

import * as types from "./generalActionsTypes";
import general from "../../api/general";
import profiles from "../../api/profiles";

import {
  requestSendMessageError,
  requestSendMessageSuccess
} from "./generalActions";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";


function* sendMessageSaga({ payload }) {
  try {
    const response = yield call(general.contactUs, payload);
    yield put(requestSendMessageSuccess(response));
  }
  catch (error) {
    yield put(requestSendMessageError(error.message));
  }
}

function* contactUsUserFormSaga({ payload }) {
  try {
    const response = yield call(general.contactUs, payload);
    yield put(requestSendMessageSuccess(response));
  }
  catch (error) {
    yield put(requestSendMessageError(error));
  }
}

function* createNewSearchEmailPreference({ payload }) {
  try {
    yield call(profiles.createNewEmailPreference, payload);
    message.success("New email preference was successfully added to the list!", 3);
  } catch (error) {
    message.error("Could not create new search email preference", 3);
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.SEND_MESSAGE, serverSagaWrapper(sendMessageSaga)),
    yield takeLatest(types.CONTACT_US_REQUEST, serverSagaWrapper(contactUsUserFormSaga)),
    yield takeLatest(types.CREATE_NEW_SEARCH_EMAIL_PREFERENCE, createNewSearchEmailPreference)
  ]);
}

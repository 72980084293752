import { all } from "redux-saga/effects";

import authSagas from "./auth/authSagas";
import businessSagas from "./business/businessSagas";
import homeSagas from "./home/homeSagas";
import profilesSagas from "./profiles/profilesSagas";
import dealersSagas from "./dealers/dealersSagas";
import directorySagas from "./directory/directorySagas";
import itemsSagas from "./items/itemsSagas";
import categoryPageSagas from "./categoryPage/categoryPageSagas";
import catalogsSaga from "./catalogs/catalogsSaga";
import storefrontSaga from "./storefront/storefrontSaga";
import periodsSaga from "./periods/periodsSaga";
import blogSagas from "./blog/blogSagas";
import generalSagas from "./general/generalSagas";
import homeAndLiving from "./homeAndLiving/hnlSagas";
import artisansSaga from "./artisans/artisansSaga";
import ordersSagas from "./orders/ordersSagas";
import notificationsSagas from "./notifications/notificationsSagas";


export default function* rootSaga() {
  yield all([
    authSagas(),
    businessSagas(),
    homeSagas(),
    profilesSagas(),
    dealersSagas(),
    directorySagas(),
    itemsSagas(),
    categoryPageSagas(),
    catalogsSaga(),
    storefrontSaga(),
    periodsSaga(),
    blogSagas(),
    generalSagas(),
    homeAndLiving(),
    artisansSaga(),
    ordersSagas(),
    notificationsSagas(),
  ]);
}

import fetch from "./customFetch";


const orders = {
  async getDealerOrders({ page, pageSize, status, payment, fromDate, toDate }) {
    const query = new URLSearchParams();

    if (page) {
      query.set("page", page);
    }
    if (pageSize) {
      query.set("page_size", pageSize);
    }
    if (status) {
      query.set("status", status);
    }
    if (payment) {
      query.set("payment", payment);
    }
    if (fromDate) {
      query.set("from_date", fromDate);
    }
    if (toDate) {
      query.set("to_date", toDate);
    }

    try {
      return await fetch({
        url: `/profiles/dealer/orders/?${query}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default orders;

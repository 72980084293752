import * as types from "./categoryPageActionsTypes";


export const initialState = {
  loading: false,
  topCategory: null,
  category: null,
  categoryChildren: [],
  categoryChildrenLoading: false,
  error: null,
  items: [],
  itemsCount: 0,
  materials: [],
  topMaterials: [],
  itemsLoading: false,
  breadcrumb: [],
  breadcrumbLoading: false,
  lastItems: [],
  categoriesSearchResult: [],
  searchLoading: false,
  categoryRelatedPeriods: [],
  categoryRelatedOrigins: [],
  categoryRelatedMaterials: [],
};


const categoryPage = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case types.SHOW_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: true,
      };

    case types.HIDE_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: false,
      };

    case types.REQUEST_MATERIALS_ERROR:
    case types.CATEGORY_REQUEST_ERROR:
    case types.SEARCH_CATEGORIES_REQUEST_ERROR:
    case types.GET_CATEGORIES_CHILDREN_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.REQUEST_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: action.payload
      };

    case types.REQUEST_MOST_POPULAR_MATERIALS_SUCCESS:
      return {
        ...state,
        topMaterials: action.payload
      };

    case types.CATEGORY_RESET_ERROR:
      return {
        ...state,
        error: null
      };

    case types.TOGGLE_CATEGORY_ITEMS_LOADING:
      return {
        ...state,
        itemsLoading: action.payload
      };

    case types.REQUEST_CATEGORY_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        itemsCount: action.payload.count
      };

    case types.REQUEST_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload
      };

    case types.REQUEST_TOP_CATEGORY_SUCCESS:
      return {
        ...state,
        topCategory: action.payload
      };

    case types.REQUEST_BREADCRUMB_SUCCESS:
      return {
        ...state,
        breadcrumb: action.payload
      };

    case types.REQUEST_CATEGORY_LAST_ITEMS_SUCCESS:
      return {
        ...state,
        lastItems: action.payload.results
      };

    case types.SEARCH_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        categoriesSearchResult: action.payload.results,
        error: null,
      };

    case types.REQUEST_TOP_CATEGORY_PERIODS_SUCCESS:
      return {
        ...state,
        categoryRelatedPeriods: action.payload,
      };

    case types.REQUEST_TOP_CATEGORY_ORIGINS_SUCCESS:
      return {
        ...state,
        categoryRelatedOrigins: action.payload,
      };

    case types.REQUEST_TOP_CATEGORY_MATERIALS_SUCCESS:
      return {
        ...state,
        categoryRelatedMaterials: action.payload,
      };

    case types.TOGGLE_BREADCRUMB_LOADING:
      return {
        ...state,
        breadcrumbLoading: action.payload,
      };

    case types.CLEAR_CATEGORY_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        items: [],
      };

    case types.GET_CATEGORIES_CHILDREN_REQUEST_SUCCESS:
      return {
        ...state,
        categoryChildren: action.payload,
      };

    case types.TOGGLE_GET_CATEGORY_CHILDREN_LOADING:
      return {
        ...state,
        categoryChildrenLoading: action.payload,
      };

    default:
      return state;
  }
};

export default categoryPage;

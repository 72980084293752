import * as types from "../general/generalActionsTypes";

export const requestSendMessage = payload => {
  return { type: types.SEND_MESSAGE, payload };
};

export const requestSendMessageSuccess = () => {
  return { type: types.SEND_MESSAGE_SUCCESS };
};

export const requestSendMessageError = (payload) => {
  return { type: types.SEND_MESSAGE_ERROR, payload };
};

export const resetMessageStatus = () => {
  return { type: types.RESET_MESSAGE_STATUS };
};

export const contactUsUserFormRequest = (payload) => ({ type: types.CONTACT_US_REQUEST, payload });

export const openMobileDropdown = (payload) => ({
  type: types.OPEN_MOBILE_DROPDOWN,
  payload
});

export const closeMobileDropdown = () => ({
  type: types.CLOSE_MOBILE_DROPDOWN
})

export const setMobileDropdownKey = (payload) => ({
  type: types.SET_MOBILE_DROPDOWN_SELECTED_KEY,
  payload
});

export const createNewSearchEmailPreferenceRequest = (payload) => ({
  type: types.CREATE_NEW_SEARCH_EMAIL_PREFERENCE,
  payload
});

export const setSearchPreferencesValue = (payload) => ({
  type: types.SET_SEARCH_PREFERENCES_VALUE,
  payload
});

export const clearSearchPreferencesValue = () => ({
  type: types.CLEAR_SEARCH_PREFERENCES_VALUE
});

export const openNotificationDrawer = () => ({
  type: types.NOTIFICATION_DRAWER_OPEN,
});

export const closeNotificationDrawer = () => ({
  type: types.NOTIFICATION_DRAWER_CLOSE,
});

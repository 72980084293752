import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const PhoneIcon = ({ className, outlined, fill, strokeWidth, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 22 22"
        focusable="false"
        data-icon="phone"
        stroke="currentColor"
        {...(outlined ? {
          fill: fill || "none",
          strokeWidth: strokeWidth || 2,
        } : {
          fill: fill || "currentColor",
          strokeWidth: strokeWidth || 0,
        })}
        strokeLinecap="round"
        strokeLinejoin="round"
        aria-hidden="true"
      >
        <path
          d="M21 15.974v3.01a2.005 2.005 0 01-.652 1.488c-.417.382-.977.57-1.54.52a19.926 19.926 0 01-8.68-3.081 19.59 19.59 0 01-6.033-6.022 19.836 19.836 0 01-3.087-8.701 2.005 2.005 0 01.518-1.534c.38-.416.919-.653 1.483-.654h3.017a2.01 2.01 0 012.011 1.726c.128.964.364 1.91.704 2.82.276.734.1 1.56-.452 2.118L7.012 8.94a16.074 16.074 0 006.033 6.021l1.277-1.274a2.014 2.014 0 012.122-.452c.912.34 1.86.575 2.826.703A2.008 2.008 0 0121 15.974h0z"
        />
      </svg>
    </span>
  );
};

PhoneIcon.propTypes = {
  className: PropTypes.string,
  outlined: PropTypes.bool,
  fill: PropTypes.string,
  strokeWidth: PropTypes.number,
};

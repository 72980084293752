import React from "react";

// components
import { SocialIcon } from "../../app-shared/components";

const ICONS_MAP = [
  { type: "Facebook2", url: "https://www.facebook.com/loveantiquescom" },
  { type: "Twitter", url: "https://twitter.com/loveantiques" },
  { type: "YouTube", url: "https://www.youtube.com/@LoveAntiques" },
  { type: "Pinterest", url: "https://www.pinterest.com/loveantiquescom/" },
  { type: "Instagram", url: "https://www.instagram.com/loveantiques_com/" },
];

const SocialIconsGroup = () => {
  return (
    <div className="header--socials-group">
      {ICONS_MAP.map((icon, index) => (
        <SocialIcon key={index} {...icon} />
      ))}
    </div>
  );
};

export default SocialIconsGroup;

import * as React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const YouTubeIcon2 = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M0 56.6694V56.6727C0.0456367 58.36 0.0608489 60.057 0.344405 61.7336C0.646216 63.5074 1.07763 65.2372 1.90062 66.8473C3.01334 69.0264 4.53669 70.8672 6.47067 72.3699C7.8556 73.4491 9.3686 74.3061 11.0435 74.8466C13.4853 75.6337 16.0048 75.9353 18.5611 75.9502C20.1559 75.9602 21.7498 76 23.3453 75.9933C34.9315 75.9447 46.5181 76.0769 58.1049 75.9219C59.6423 75.9012 61.1547 75.7136 62.6637 75.4379C65.5559 74.9089 68.1118 73.6747 70.2988 71.7263C72.8474 69.455 74.567 66.6749 75.3434 63.3199C75.8512 61.1268 75.9784 58.902 76 56.6691V56.2237C76 56.0511 75.9343 18.2863 75.9282 17.7872C75.9115 16.4161 75.8102 15.0551 75.5531 13.7059C75.24 12.0618 74.7979 10.4597 74.0102 8.97247C73.1781 7.40075 72.1361 5.98803 70.8461 4.75315C68.8719 2.86132 66.5934 1.52368 63.9397 0.815956C61.5441 0.17754 59.1001 0.0352648 56.6379 0.0376969C56.6299 0.0266183 56.6252 0.01358 56.6242 0H19.3621C19.3621 0.0127683 19.3621 0.0252326 19.36 0.0376969C17.9626 0.0632335 16.5634 0.0525933 15.1733 0.224965C13.6505 0.414058 12.1421 0.698913 10.7009 1.23093C8.43062 2.06999 6.4579 3.37691 4.77299 5.12222C2.86385 7.09827 1.51757 9.38501 0.804423 12.0472C0.16703 14.4224 0.0282947 16.8447 0.0249481 19.2844"
            fill="white"
          />
          <path
            d="M27.0196 59.7172L39.3293 59.945C39.3488 59.945 39.3882 59.945 39.3879 59.9757C46.2427 60.1053 52.9215 59.6983 59.7571 59.1928C65.2128 58.8062 66.5056 54.8016 67.1813 50.0262C68.0235 44.2586 68.2095 38.4217 67.7689 32.6146C67.7477 32.3393 67.7272 32.0615 67.7065 31.782C67.2959 26.2256 66.8343 19.9811 60.3447 18.8299C58.9639 18.582 57.5539 18.3642 56.1535 18.3244C42.8941 17.8897 29.5168 17.7793 16.2278 18.711C12.2912 18.9677 9.74565 21.3268 9.09882 25.2803C7.95303 32.3263 7.71798 39.5605 8.32514 46.6658C8.33271 46.7527 8.34028 46.8398 8.34787 46.9272C8.78189 51.9255 9.28905 57.7661 15.131 58.9738C18.2761 59.6358 21.4783 59.6623 24.6865 59.6888C25.4641 59.6952 26.242 59.7017 27.0196 59.7172ZM38.482 44.1409C36.3616 45.3742 34.2362 46.6103 32.0938 47.8551V30.0372C34.4561 31.4084 36.8018 32.7733 39.15 34.1396C41.8929 35.7356 44.6392 37.3336 47.4197 38.9461C44.4281 40.6826 41.4599 42.4089 38.482 44.1409Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FF0000"
          />
        </g>
      </svg>
    </span>
  );
}

YouTubeIcon2.propTypes = {
  className: PropTypes.string,
};

import React, { useCallback } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { debounce } from "lodash";

const BurgerButton = ({ openMenuDrawer, mobileMenuOpen }) => {
  const handleButtonClick = useCallback(
    debounce(() => {
      openMenuDrawer();
    }, 100),
    [openMenuDrawer]
  );

  return (
    <div className="header--burger-menu-container">
      <Button
        className="header--mobile-menu-button xl-visible"
        size="large"
        aria-label="Mobile menu button"
        onClick={handleButtonClick}
        disabled={mobileMenuOpen}
      >
        <span className="header--mobile-menu-icon" />
        <span className="header--mobile-menu-icon" />
        <span className="header--mobile-menu-icon" />
      </Button>
    </div>
  );
};

BurgerButton.propTypes = {
  openMenuDrawer: PropTypes.func.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
};

export default BurgerButton;

import * as React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const FacebookIcon2 = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M40 22H38C34.8259 21.314 34.3849 22.436 34 24V28H40V34H34V48H28V34H24V28H28V24C28.7293 18.65 31.5927 16 36 16C37.7759 16 39.4974 16.154 40 16V22ZM32 0C14.328 0 0 14.326 0 32C0 49.672 14.328 64 32 64C49.674 64 64 49.672 64 32C64 14.326 49.674 0 32 0Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#3B5A98"
          opacity="0.9"
        />
      </svg>
    </span>
  );
}

FacebookIcon2.propTypes = {
  className: PropTypes.string,
};

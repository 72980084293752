import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const HouseIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.4 15V10.0588H9.6V15H13.6V8.41176H16L8 1L0 8.41176H2.4V15H6.4Z" />
      </svg>
    </span>
  );
};

HouseIcon.propTypes = {
  className: PropTypes.string,
};

import * as types from "./blogActionsTypes";


export const initialState = {
  processing: false,
  loading: false,
  blogArticlesLoading: false,
  blogCategoriesLoading: false,
  blogAuthorsLoading: false,
  blogArticles: [],
  blogArticlesCount: 0,
  blogAuthors: [],
  blogCategories: [],
  blogArticleByURL: {},
  archivedBlogCategories: [],
  archivedBlogCategoriesLoading: false,
  blogPostError: null,
  blogListError: null,
};


const blog = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_BLOG_LOADING:
      return {
        ...state,
        loading: true,
      };

    case types.HIDE_BLOG_LOADING:
      return {
        ...state,
        loading: false,
      };

    case types.SHOW_BLOG_ARTICLES_LOADING:
      return {
        ...state,
        blogArticlesLoading: true,
      };

    case types.HIDE_BLOG_ARTICLES_LOADING:
      return {
        ...state,
        blogArticlesLoading: false,
      };

    case types.SHOW_BLOG_CATEGORIES_LOADING:
      return {
        ...state,
        blogCategoriesLoading: true,
      };

    case types.HIDE_BLOG_CATEGORIES_LOADING:
      return {
        ...state,
        blogCategoriesLoading: false,
      };

    case types.SHOW_BLOG_AUTHORS_LOADING:
      return {
        ...state,
        blogAuthorsLoading: true,
      };

    case types.HIDE_BLOG_AUTHORS_LOADING:
      return {
        ...state,
        blogAuthorsLoading: false,
      };

    case types.TOGGLE_BLOG_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_BLOG_ARTICLES_REQUEST_SUCCESS:
      return {
        ...state,
        blogArticles: action.payload.results,
        blogArticlesCount: action.payload.count,
        error: null,
        blogListError: null,
      };

    case types.GET_BLOG_AUTHORS_REQUEST_SUCCESS:
      return {
        ...state,
        blogAuthors: action.payload.results,
        error: null,
      };

    case types.GET_BLOG_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        blogCategories: action.payload.results,
        error: null,
      };

    case types.GET_BLOG_ARTICLE_BY_URL_REQUEST_SUCCESS:
      return {
        ...state,
        blogArticleByURL: action.payload,
        error: null,
        blogPostError: null
      };
    case types.GET_BLOG_ARTICLES_REQUEST_ERROR:
      return {
        ...state,
        blogListError: action.payload,
      }
    case types.GET_BLOG_AUTHORS_REQUEST_ERROR:
    case types.GET_BLOG_CATEGORIES_REQUEST_ERROR:
    case types.GET_ARCHIVED_BLOG_ARTICLES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_BLOG_ARTICLE_BY_URL_REQUEST_ERROR:
      return {
        ...state,
        blogPostError: action.payload
      }

    case types.GET_ARCHIVED_BLOG_ARTICLES_REQUEST_SUCCESS:
      return {
        ...state,
        archivedBlogCategories: action.payload,
      };

    case types.SHOW_BLOG_ARCHIVES_LOADING:
      return {
        ...state,
        archivedBlogCategoriesLoading: true,
      };

    case types.HIDE_BLOG_ARCHIVES_LOADING:
      return {
        ...state,
        archivedBlogCategoriesLoading: false,
      };

    default:
      return state;
  }
};

export default blog;

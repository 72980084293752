import { combineReducers } from "redux";

import auth from "./auth/authReducer";
import business from "./business/businessReducer";
import general from "./general/generalReducer";
import home from "./home/homeReducer";
import profiles from "./profiles/profilesReducer";
import dealers from "./dealers/dealersReducer";
import directory from "./directory/directoryReducer";
import items from "./items/itemsReducer";
import categoryPage from "./categoryPage/categoryPageReducer";
import catalogs from "./catalogs/catalogsReducer";
import storefront from "./storefront/storefrontReducer";
import periods from "./periods/periodsReducer";
import blog from "./blog/blogReducer";
import hnl from "./homeAndLiving/hnlReducer";
import artisans from "./artisans/artisansReducer";
import orders from "./orders/ordersReducer";
import ssr from "./ssr/ssrReducer";
import notifications from "./notifications/notificationsReducer"


// Combine reducer into single one
const rootReducer = combineReducers({
  auth,
  business,
  general,
  home,
  profiles,
  dealers,
  directory,
  items,
  categoryPage,
  catalogs,
  storefront,
  periods,
  blog,
  hnl,
  artisans,
  orders,
  ssr,
  notifications,
});

export default rootReducer;

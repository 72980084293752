import * as React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const YouTubeIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle fill="#D23635" cx="12" cy="12" r="12" />
          <path
            d="M16.465 7.5h-8.93c-1.262 0-2.285.985-2.285 2.2v4.6c0 1.215 1.023 2.2 2.285 2.2h8.93c1.262 0 2.285-.985 2.285-2.2V9.7c0-1.215-1.023-2.2-2.285-2.2zm-1.726 4.766l-4.05 2a.322.322 0 01-.305-.01.298.298 0 01-.15-.256v-4c0-.105.058-.202.15-.256a.322.322 0 01.305-.01l4.05 2c.105.051.17.154.17.267a.299.299 0 01-.17.267v-.002z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </span>
  );
}

YouTubeIcon.propTypes = {
  className: PropTypes.string,
};

import fetch from "./customFetch";


const categories = {
  async getMostPopular(pageSize) {
    try {
      return await fetch({
        url: `/categories/most-popular/?page=1&page_size=${pageSize}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAll() {
    try {
      return await fetch({
        url: "/categories/all/",
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAllWithoutPagination() {
    try {
      return await fetch({
        url: "/categories/all/?ordering=title",
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getOne(category) {
    return fetch({
      url: `/categories/all/${category}/`,
      method: "GET"
    });
  },

  async getMultipleByUrls(urls) {
    try {
      return await fetch({
        url: `/categories/all/breadcrumb/?urls=${urls.join(",")}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchCategories({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("title", title);
    }

    try {
      return await fetch({
        url: `/categories/autocomplete/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCategoriesList({ page = 1, pageSize = 10 }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }

    try {
      return await fetch({
        url: `/categories/all/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCategoryChildren(url, isLinked = true) {
    try {
      return await fetch({
        url: `/categories/all/${url}/children/?linked=${isLinked}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default categories;

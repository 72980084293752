// form validation messages
export const MESSAGE_VALID_PASSWORD = "This password must contain at least 8 characters, should include at least one uppercase letter and one symbol";
export const MESSAGE_VALID_EMAIL = "Please enter valid email address";
export const MESSAGE_VALID_PHONE = "Please enter valid phone number";
export const MESSAGE_VALID_NUMBER = "Please enter valid number";
export const MESSAGE_REQUIRED_FIELD = "Field is required";
export const MESSAGE_EMPTY_FIELD = "Field can not be empty";
export const MESSAGE_EMPTY_COVER_IMAGE = "Cover image can not be empty";
export const MESSAGE_EMPTY_LOGO = "Logo can not be empty";

export const HINT_PASSWORD = "Password should be 8 characters long and include one uppercase and one symbol such as + ! $ ? & @ *";

// api response messages
export const MESSAGE_TRY_AGAIN = "Something went wrong. Please try again.";

// helpers
import fetch from "./customFetch";

const notifications = {
  getAll: async () => {
    try {
      return await fetch({
        url: "/profiles/dealer-notifications/",
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  markAsRead: async ({ notificationId }) => {
    const data = { isRead: true };

    try {
      return await fetch({
        url: `/profiles/dealer-notifications/${notificationId}/`,
        method: "PATCH",
        data,
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  markAsUnread: async ({ notificationId }) => {
    const data = { isRead: false };

    try {
      return await fetch({
        url: `/profiles/dealer-notifications/${notificationId}/`,
        method: "PATCH",
        data,
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  delete: async ({ notificationId }) => {
    try {
      return await fetch({
        url: `/profiles/dealer-notifications/${notificationId}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
}

export default notifications;

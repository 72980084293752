const isEncoded = (url = "") => url !== decodeURIComponent(url);

export const fullyDecodeURL = (url) => {
  let decodedUrl = url;

  while (isEncoded(decodedUrl)) {
    decodedUrl = decodeURIComponent(decodedUrl);
  }

  return decodedUrl;
};

export const objectToQueryString = (params) => {
  if (!params) {
    return "";
  }

  const query = new URLSearchParams();

  for (const key in params) {
    if (params[key]) {
      query.set(key, `${params[key]}`);
    }
  }

  return query.toString();
};

export const stringToUrl = (string) => {
  return typeof string === "string"
    ? string.toLowerCase().replace(/\W+/gi, "-").replace(/_/gi, "")
    : "";
};

export const urlToLabel = (string) => {
  if (typeof string !== "string" || !string) {
    return "";
  }

  try {
    const parsedUrl = new URL(string);

    return parsedUrl.hostname;
  } catch (error) {
    console.error("Invalid URL:", error);

    return "";
  }
};

import * as types from "./periodsActionsTypes";


export const getListOfAllPeriodsRequest = (payload) => ({
  type: types.GET_LIST_OF_PERIODS_REQUEST,
  payload
});

export const getListOfPeriodsSuccess = (payload) => ({
  type: types.GET_LIST_OF_PERIODS_REQUEST_SUCCESS,
  payload,
});

export const getListOfPeriodsError = (payload) => ({
  type: types.GET_LIST_OF_PERIODS_REQUEST_ERROR,
  payload,
});

export const searchPeriodsRequest = (payload) => ({
  type: types.SEARCH_PERIODS_REQUEST,
  payload,
});

export const searchPeriodsRequestError = (payload) => ({
  type: types.SEARCH_PERIODS_REQUEST_ERROR,
  payload,
});

export const searchPeriodsRequestSuccess = (payload) => ({
  type: types.SEARCH_PERIODS_REQUEST_SUCCESS,
  payload,
});

export const getPeriodDataRequest = (payload) => ({
  type: types.GET_PERIOD_DATA_REQUEST,
  payload,
});

export const getPeriodDataRequestError = (payload) => ({
  type: types.GET_PERIOD_DATA_REQUEST_ERROR,
  payload,
});

export const getPeriodDataRequestSuccess = (payload) => ({
  type: types.GET_PERIOD_DATA_REQUEST_SUCCESS,
  payload,
});

export const getPeriodItemsRequest = (payload) => ({
  type: types.GET_PERIOD_ITEMS_REQUEST,
  payload,
});

export const getPeriodItemsRequestError = (payload) => ({
  type: types.GET_PERIOD_ITEMS_REQUEST_ERROR,
  payload,
});

export const getPeriodItemsRequestSuccess = (payload) => ({
  type: types.GET_PERIOD_ITEMS_REQUEST_SUCCESS,
  payload,
});

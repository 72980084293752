import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { loadableReady } from "@loadable/component";
import { Router } from "react-router-dom";
import { SWRConfig } from "swr";

import "./assets/styles/index.scss";
import App from "./react/App";
import ScrollToTop from "./react/router/components/ScrollToTop";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";
import history from "./setupHistory";
import { swrFetcher } from "./api/customFetch";
import * as Sentry from "@sentry/react";
import GoogleCaptchaProvider from "./react/provider/GoogleCaptchaProvider";

// Prepare Redux store
const preloadedState = window.__PRELOADED_STATE__;
const store = configureStore(preloadedState);
const hasSSR = !!preloadedState;
delete window.__PRELOADED_STATE__;

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

// Render App
const renderFunc = hasSSR ? ReactDOM.hydrate : ReactDOM.render;
loadableReady(() => {
  renderFunc(
    <Provider store={store}>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          shouldRetryOnError: false,
          fetcher: swrFetcher,
        }}
      >
        <Router history={history}>
          <GoogleCaptchaProvider>
            <ScrollToTop />
            <Sentry.ErrorBoundary>
              <App />
            </Sentry.ErrorBoundary>
          </GoogleCaptchaProvider>
        </Router>
      </SWRConfig>
    </Provider>,
    document.getElementById("root")
  );
});

serviceWorker.unregister();

import React from "react";

const CheckMarkCircle = (props) => {
  return (
    <span role="img" aria-label="check-mark-circle" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5741 10.4091H20.9253C20.5667 10.4091 20.2257 10.5813 20.0147 10.8766L14.4882 18.5407L11.9851 15.0673C11.7741 14.7755 11.4366 14.5997 11.0745 14.5997H9.42568C9.19716 14.5997 9.06357 14.8598 9.19716 15.0462L13.5776 21.1212C13.6811 21.2656 13.8175 21.3833 13.9756 21.4645C14.1336 21.5457 14.3087 21.588 14.4864 21.588C14.6641 21.588 14.8392 21.5457 14.9973 21.4645C15.1553 21.3833 15.2917 21.2656 15.3952 21.1212L22.7991 10.8555C22.9362 10.6692 22.8026 10.4091 22.5741 10.4091Z"
          fill="#004F57"
        />
        <path
          d="M16 0.25C7.30234 0.25 0.25 7.30234 0.25 16C0.25 24.6977 7.30234 31.75 16 31.75C24.6977 31.75 31.75 24.6977 31.75 16C31.75 7.30234 24.6977 0.25 16 0.25ZM16 29.0781C8.77891 29.0781 2.92188 23.2211 2.92188 16C2.92188 8.77891 8.77891 2.92188 16 2.92188C23.2211 2.92188 29.0781 8.77891 29.0781 16C29.0781 23.2211 23.2211 29.0781 16 29.0781Z"
          fill="#004F57"
        />
      </svg>
    </span>
  );
}

export default CheckMarkCircle;
import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";


function RouteWithStatus({ component: Component, status, ...rest }) {
  return (
    <Route
      {...rest}
      status={status}
      render={props => {
        if (props.staticContext) {
          props.staticContext.status = status;
        }
  
        return <Component {...props} />
      }}
    />
  );
}

RouteWithStatus.propTypes = {
  component: PropTypes.any.isRequired,
  status: PropTypes.number,
};


export default RouteWithStatus;

import { message } from "antd";
import { isEmpty } from "lodash";

export const formErrorsHandler = (err) => {
  // show validation errors in console
  console.info("Form validation error: ", err);

  // get the first error field and scroll to it
  const node = document.querySelector(".ant-form-item-has-error") ||
    document.getElementById(String(err?.errorFields?.[0]?.name?.[0]));

  if (node) {
    node.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

export const setServerErrorsToFormAndScroll = (form, err) => {
  if (typeof err === "string" && err?.length) {
    message.error(err);
  } else if (err?.detail || err?.details || err?.message) {
    message.error(err?.detail || err?.details || err?.message || "Something went wrong");
  } else if (!isEmpty(err)) {
    const fields = Object.keys(err)
      .reduce((acc, cur) => {
        return document.getElementById(cur) ? acc.concat({ name: cur, errors: err[cur] }) : acc
      }, []);

    if (fields.length) {
      const node = document.getElementById(fields[0]?.name);
      node?.scrollIntoView({ behavior: "smooth", block: "start" });

      form.setFields(fields);
    }
  } else {
    console.error(err);
  }
};

export const transformServerErrorsToFormAndScroll = (err) => {
  if (typeof err === "string" && err?.length) {
    message.error(err);
  } else if (err?.detail || err?.details || err?.message) {
    message.error(err?.detail || err?.details || err?.message || "Something went wrong");
  } else if (!isEmpty(err)) {
    const fields = Object.keys(err)
      .reduce((acc, cur) => {
        return document.getElementById(cur) ? acc.concat({ name: cur, errors: err[cur] }) : acc
      }, []);

    if (fields.length) {
      const node = document.getElementById(fields[0]?.name);
      node?.scrollIntoView({ behavior: "smooth", block: "start" });

      return fields;
    }
  } else {
    console.error(err);
  }
};

export const formCorrectMeasurements = (metricSystem, measurementSystem) => {
  let defaultMeasurement = "";

  if (
    (metricSystem === "METRIC" || metricSystem === "METRIC_AND_IMPERIAL") && measurementSystem === "length"
  ) {
    defaultMeasurement = "mm";
  }
  if (
    (metricSystem === "METRIC" || metricSystem === "METRIC_AND_IMPERIAL") && measurementSystem === "mass"
  ) {
    defaultMeasurement = "mg";
  }

  if (metricSystem === "IMPERIAL" && measurementSystem === "length") {
    defaultMeasurement = "in";
  }
  if (metricSystem === "IMPERIAL" && measurementSystem === "mass") {
    defaultMeasurement = "oz";
  }

  return defaultMeasurement;
};

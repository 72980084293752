export const DEALERS_CATEGORY = {
  directoryCount: null,
  id: "dealers",
  title: "Dealers",
  url: "dealers",
  titleTag: "Antique & Vintage Dealers",
};

export const ANTIQUE_FAIRS_CATEGORY = {
  directoryCount: null,
  isActive: true,
  id: "antique-fairs",
  title: "Antique Fairs",
  url: "antique-fairs",
  titleTag: "Antique & Vintage Fairs",
};

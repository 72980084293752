import fetch from "./customFetch";

// helpers
import { getAlgoliaUserUUID, fullyDecodeURL } from "../helpers";

const storefront = {
  async getDealers({ page = 1, pageSize = 16, speciality, businessName, ordering, location, abroadLocation }) {
    const query = new URLSearchParams();

    if (page) {
      query.set("page", page);
    }
    if (pageSize) {
      query.set("page_size", pageSize);
    }
    if (speciality) {
      query.set("speciality", speciality);
    }
    if (businessName) {
      query.set("business_name", businessName);
    }
    if (ordering) {
      query.set("ordering", ordering);
    }
    if (location && !abroadLocation) {
      query.set("location", location);
    } else if (location && abroadLocation) {
      query.set("abroad_location", location);
    }

    try {
      return await fetch({
        url: `/storefront/dealers/?${query.toString()}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async globalSearch(query) {
    try {
      return await fetch({
        url: `/storefront/search/?query=${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async algoliaSearch(data, params = {}) {
    try {
      const searchQuery = new URLSearchParams();

      if (params.sort) {
        searchQuery.set("sort_by", params.sort);
      }

      return await fetch({
        url: `/search/algolia/?${searchQuery.toString()}`,
        method: "POST",
        headers: {
          "X-Algolia-UserToken": getAlgoliaUserUUID()
        },
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async emailPreferencesSearch({ page, pageSize, query }) {
    const searchQuery = new URLSearchParams();

    if (page) {
      searchQuery.set("page", page);
    }
    if (pageSize) {
      searchQuery.set("pageSize", pageSize);
    }
    if (query) {
      searchQuery.set("query", query);
    }

    try {
      return await fetch({
        url: `/profiles/email-preferences/search/?${searchQuery.toString()}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async checkSeoRedirect(fromURL) {
    return fetch({
      url: `/seo/redirects/?from_url=${fromURL}`,
      method: "GET"
    });
  },

  async getAllSearchResults({ page, pageSize, query, forImpressions, order }) {
    const searchQuery = new URLSearchParams();

    if (page) {
      searchQuery.set("page", page);
    }
    if (pageSize) {
      searchQuery.set("page_size", pageSize);
    }
    if (query) {
      searchQuery.set("query", fullyDecodeURL(query));
    }
    if (forImpressions) {
      searchQuery.set("for_impressions", forImpressions);
    }
    if (order) {
      searchQuery.set("order", order);
    }

    try {
      return await fetch({
        url: `/search/items/?${searchQuery.toString()}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createSearchQuery(data) {
    try {
      return await fetch({
        url: "/search/search-query/create-search-query/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  }
};

export default storefront;

import { all, put, call, takeLatest } from "redux-saga/effects";

import serverSagaWrapper from "../../helpers/serverSagaWrapper";

import * as types from "./artisansActionsTypes";
import artisans from "../../api/artisans";
import items from "../../api/items";

import {
  getArtisanSuccess,
  getArtisansError,
  getArtisansTagsSuccess,
  getArtisansTagsError,
  getArtisansItemsSuccess,
  getArtisansItemsError,
  getArtisanDataSuccess,
  getArtisanDataError,
} from "./artisansActions";


function *getArtisansSaga({ payload, callback }) {
  try {
    const response = yield call(artisans.getArtisansByName, payload);
    yield put(getArtisanSuccess(response));
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put(getArtisansError(error));
  }
}

function *getArtisansTagsSaga({ payload }) {
  try {
    const response = yield call(artisans.getArtisansTagsByName, payload);
    yield put(getArtisansTagsSuccess(response));
  } catch (error) {
    yield put(getArtisansTagsError(error.message));
  }
}

function *getArtisansItemsSaga({ payload }) {
  yield put({ type: types.SHOW_ARTISANS_ITEMS_LOADING });
  try {
    const response = yield call(items.get, payload);
    yield put(getArtisansItemsSuccess(response));
  } catch (error) {
    yield put(getArtisansItemsError(error.message));
  } finally {
    yield put({ type: types.HIDE_ARTISANS_ITEMS_LOADING });
  }
}

function *getArtisanDataSaga({ payload }) {
  yield put({ type: types.SHOW_ARTISAN_DATA_LOADING });
  try {
    const response = yield call(artisans.getArtisanData, payload);
    yield put(getArtisanDataSuccess(response));
  } catch (error) {
    yield put(getArtisanDataError(error.detail));
  } finally {
    yield put({ type: types.HIDE_ARTISAN_DATA_LOADING });
  }
}

function *fetchActualArtisans() {
  try {
    yield put({ type: types.GET_ARTISANS_REQUEST });
  } catch (err) {
    yield put(getArtisansError(err.message));
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_ARTISANS_REQUEST, serverSagaWrapper(getArtisansSaga)),
    yield takeLatest(types.GET_ARTISANS_TAGS_REQUEST, serverSagaWrapper(getArtisansTagsSaga)),
    yield takeLatest(types.GET_ARTISANS_ITEMS_REQUEST, serverSagaWrapper(getArtisansItemsSaga)),
    yield takeLatest(types.GET_ARTISAN_DATA_REQUEST, serverSagaWrapper(getArtisanDataSaga)),
    yield takeLatest(types.CLEAR_ARTISANS_ACTION, fetchActualArtisans)
  ])
}

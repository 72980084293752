import { all, put, call, takeLatest } from "redux-saga/effects";

import notifications from "../../api/notifications";
import * as types from "./notificationsActionsTypes";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import profiles from "../../api/profiles";

function* getAllNotifications() {
  try {
    const result = yield call(notifications.getAll);
    yield put({ type: types.NOTIFICATIONS_GET_ALL_REQUEST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: types.NOTIFICATIONS_GET_ALL_REQUEST_ERROR, payload: error });
  }
}

function* markNotificationAsRead(action) {
  try {
    yield call(notifications.markAsRead, action.payload);

    // Refresh notifications and user profile
    const notificationsAll = yield call(notifications.getAll);
    const userProfile = yield call(profiles.getAuthorizedUserProfile);

    const payload = {
      notifications: notificationsAll,
      unreadNotifications: userProfile.unreadNotifications,
    };

    yield put({
      type: types.NOTIFICATION_MARK_AS_READ_REQUEST_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({ type: types.NOTIFICATION_MARK_AS_READ_REQUEST_ERROR, payload: error });
  }
}

function* markNotificationAsUnread(action) {
  try {
    yield call(notifications.markAsUnread, action.payload);

    // Refresh notifications and user profile
    const notificationsAll = yield call(notifications.getAll);
    const userProfile = yield call(profiles.getAuthorizedUserProfile);

    const payload = {
      notifications: notificationsAll,
      unreadNotifications: userProfile.unreadNotifications,
    };

    yield put({
      type: types.NOTIFICATION_MARK_AS_UNREAD_REQUEST_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({ type: types.NOTIFICATION_MARK_AS_UNREAD_REQUEST_ERROR, payload: error });
  }
}

function* deleteNotification(action) {
  try {
    yield call(notifications.delete, action.payload);

    // Refresh notifications and user profile
    const notificationsAll = yield call(notifications.getAll);
    const userProfile = yield call(profiles.getAuthorizedUserProfile);

    const payload = {
      notifications: notificationsAll,
      unreadNotifications: userProfile.unreadNotifications,
    };

    yield put({
      type: types.NOTIFICATION_DELETE_REQUEST_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({ type: types.NOTIFICATION_DELETE_REQUEST_ERROR, payload: error });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.NOTIFICATIONS_GET_ALL_REQUEST, serverSagaWrapper(getAllNotifications)),
    yield takeLatest(types.NOTIFICATION_MARK_AS_READ_REQUEST, serverSagaWrapper(markNotificationAsRead)),
    yield takeLatest(types.NOTIFICATION_MARK_AS_UNREAD_REQUEST, serverSagaWrapper(markNotificationAsUnread)),
    yield takeLatest(types.NOTIFICATION_DELETE_REQUEST, serverSagaWrapper(deleteNotification)),
  ]);
}

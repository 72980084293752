import fetch from "./customFetch";


const artisans = {
  async getArtisansByName({ page, pageSize, name, startsWith, ordering, status, tag }) {
    const query = new URLSearchParams();

    if (page) {
      query.set("page", page);
    }
    if (pageSize) {
      query.set("page_size", pageSize);
    }
    if (name) {
      query.set("name", name);
    }
    if (startsWith) {
      query.set("starts_with", startsWith);
    }
    if (ordering) {
      query.set("ordering", ordering);
    }
    if (status) {
      query.set("status", status);
    }
    if (tag) {
      query.set("tag", tag);
    }

    try {
      return await fetch({
        url: `/storefront/artisans/?${query}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getArtisansTagsByName({ page, pageSize, name, startsWith }) {
    const query = new URLSearchParams();

    if (page) {
      query.set("page", page);
    }
    if (pageSize) {
      query.set("page_size", pageSize);
    }
    if (name) {
      query.set("name", name);
    }
    if (startsWith) {
      query.set("start_with", startsWith);
    }

    try {
      return await fetch({
        url: `/artisans/tags/?${query}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getArtisanData(url) {
    try {
      return await fetch({
        url: `/storefront/artisans/${url}/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getArtisanTags() {
    try {
      return await fetch({
        url: "/artisans/tags/?page=1&page_size=200",
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async postSuggestArtisan(data) {
    try {
      return await fetch({
        url: "storefront/artisans/suggest-artisan/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default artisans;

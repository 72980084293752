import * as types from "./generalActionsTypes";


export const initialState = {
  requestToken: "",
  allowed: true,
  messageSent: false,
  error: null,
  isMobile: false,
  isMobileDropdownOpened: false,
  mobileDropdownChildren: [],
  dropdownQueryToSet: "",
  dropdownDefaultSelected: "0",
  searchPreference: {},
  notificationDrawerOpened: false,
};


const general = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REQUEST_TOKEN:
      return {
        ...state,
        requestToken: action.payload,
      };

    case types.ALLOWED_SUCCESS:
      return {
        ...state,
        allowed: action.payload
      };

    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSent: true,
        error: null
      };

    case types.SEND_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.RESET_MESSAGE_STATUS:
      return {
        ...state,
        messageSent: false
      };

    case "SET_IS_MOBILE_BROWSER":
      return {
        ...state,
        isMobile: action.payload
      }

    case types.OPEN_MOBILE_DROPDOWN:
      return {
        ...state,
        isMobileDropdownOpened: true,
        mobileDropdownChildren: action.payload.children,
        dropdownQueryToSet: action.payload.query,
      }

    case types.CLOSE_MOBILE_DROPDOWN: {
      return {
        ...state,
        isMobileDropdownOpened: false
      }
    }

    case types.SET_MOBILE_DROPDOWN_SELECTED_KEY:
      return {
        ...state,
        dropdownDefaultSelected: action.payload
      }

    case types.SET_SEARCH_PREFERENCES_VALUE:
      return {
        ...state,
        searchPreference: action.payload
      }

    case types.CLEAR_SEARCH_PREFERENCES_VALUE:
      return {
        ...state,
        searchPreference: {}
      }

    case types.NOTIFICATION_DRAWER_OPEN:
      return {
        ...state,
        notificationDrawerOpened: true,
      }

    case types.NOTIFICATION_DRAWER_CLOSE:
      return {
        ...state,
        notificationDrawerOpened: false,
      }

    default:
      return state;
  }
};

export default general;

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./storefrontActionsTypes";
import storefront from "../../api/storefront";
import dealers from "../../api/dealers";

// helpers
import { ENGLAND_OPTIONS, NORTHERN_IRELAND_OPTIONS, SCOTLAND_OPTIONS, WALES_OPTIONS } from "../../constants";

import {
  getDealersSuccess,
  getDealersError,
  globalSearchSuccess,
  globalSearchError,
  searchEmailPreferencesSuccess,
  searchEmailPreferencesError,
  checkSeoRedirectSuccess,
  checkSeoRedirectError,
  getAllSearchResultsRequestSuccess,
  getAllSearchResultsError,
  getDealersLocationsSuccess,
  getDealersLocationsError
} from "./storefrontActions";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";


function* getDealers(action) {
  try {
    const response = yield call(storefront.getDealers, action.payload);
    yield put(getDealersSuccess(response));
  }
  catch (error) {
    yield put(getDealersError(error));
  }
}

function* getDealersLocations(action) {
  try {
    const locations = yield call(dealers.getDealersLocations, action.payload);
    const abroadLocations = yield call(dealers.getAbroadDealersLocations, action.payload);


    const locationsGrouped = {
      isEngland: [],
      isNorthernIreland: [],
      isScotlandOptions: [],
      isWalesOptions: [],
      abroadOptions: abroadLocations.filter(item => !!item).map(item => ({ value: item, label: item, isAbroad: true })),
    };

    // moved formatting of list to display correct list without JS

    locations.forEach(item => {
      const isEngland = ENGLAND_OPTIONS.find(option => option.county === item);
      const isNorthernIreland = NORTHERN_IRELAND_OPTIONS.find(option => option.county === item);
      const isScotlandOptions = SCOTLAND_OPTIONS.find(option => option.county === item);
      const isWalesOptions = WALES_OPTIONS.find(option => option.county === item);

      if (isEngland) {
        locationsGrouped.isEngland.push({ value: item, label: item });
      } else if (isNorthernIreland) {
        locationsGrouped.isNorthernIreland.push({ value: item, label: item });
      } else if (isScotlandOptions) {
        locationsGrouped.isScotlandOptions.push({ value: item, label: item });
      } else if (isWalesOptions) {
        locationsGrouped.isWalesOptions.push({ value: item, label: item });
      }
    });

    yield put(getDealersLocationsSuccess(locationsGrouped));
  }
  catch (error) {
    yield put(getDealersLocationsError(error));
  }
}

function* globalSearch(action) {
  try {
    const response = yield call(storefront.globalSearch, action.payload);
    yield put(globalSearchSuccess(response));
  }
  catch (error) {
    yield put(globalSearchError(error.message));
  }
}

function* searchEmailPreferences(action) {
  try {
    const response = yield call(storefront.emailPreferencesSearch, action.payload);
    yield put(searchEmailPreferencesSuccess(response));
  }
  catch (error) {
    yield put(searchEmailPreferencesError(error.message));
  }
}

export function* checkSeoRedirect(action) {
  try {
    const response = yield call(storefront.checkSeoRedirect, action.payload);
    yield put(checkSeoRedirectSuccess(response));
  }
  catch (error) {
    if (error.status === 404) {
      yield put(checkSeoRedirectSuccess({ toUrl: "not-found" }));
    }
    yield put(checkSeoRedirectError(error.data));
  }
}

function* getAllSearchResults(action) {
  try {
    const response = yield call(storefront.getAllSearchResults, action.payload);
    yield put(getAllSearchResultsRequestSuccess(response));
  }
  catch (error) {
    yield put(getAllSearchResultsError(error.message));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_DEALERS_REQUEST, serverSagaWrapper(getDealers)),
    yield takeLatest(types.GET_DEALERS_LOCATIONS_REQUEST, serverSagaWrapper(getDealersLocations)),
    yield takeLatest(types.GLOBAL_SEARCH_REQUEST, serverSagaWrapper(globalSearch)),
    yield takeLatest(types.SEARCH_EMAIL_PREFERENCES_REQUEST, serverSagaWrapper(searchEmailPreferences)),
    yield takeLatest(types.CHECK_SEO_REDIRECT_REQUEST, checkSeoRedirect),
    yield takeLatest(types.GET_ALL_SEARCH_RESULTS_REQUEST, serverSagaWrapper(getAllSearchResults)),
  ]);
}

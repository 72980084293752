import * as types from "./categoryPageActionsTypes";


export const requestItems = data => {
  return { type: types.REQUEST_CATEGORY_ITEMS, payload: data };
};

export const requestBreadcrumb = urls => {
  return { type: types.REQUEST_BREADCRUMB, payload: urls }
};

export const requestLastItems = pageSize => {
  return { type: types.REQUEST_CATEGORY_LAST_ITEMS, payload: pageSize };
};

export const searchCategoriesRequestError = (payload) => ({
  type: types.SEARCH_CATEGORIES_REQUEST_ERROR,
  payload,
});

export const searchCategoriesRequestSuccess = (payload) => ({
  type: types.SEARCH_CATEGORIES_REQUEST_SUCCESS,
  payload,
});

export const getCategoryChildrenRequestError = (payload) => ({
  type: types.GET_CATEGORIES_CHILDREN_REQUEST_ERROR,
  payload,
});

export const getCategoryChildrenRequestSuccess = (payload) => ({
  type: types.GET_CATEGORIES_CHILDREN_REQUEST_SUCCESS,
  payload,
});

export const clearCategoryData = () => {
  return { type: types.CLEAR_CATEGORY_DATA }
};

import * as types from "./artisansActionsTypes";

export const getArtisansRequest = (payload, callback) => ({
  type: types.GET_ARTISANS_REQUEST,
  payload,
  callback
});

export const getArtisanSuccess = payload => ({
  type: types.GET_ARTISANS_SUCCESS,
  payload
});

export const getArtisansError = payload => ({
  type: types.GET_ARTISANS_ERROR,
  payload
});

export const getArtisansTagsRequest = payload => ({
  type: types.GET_ARTISANS_TAGS_REQUEST,
  payload
});

export const getArtisansTagsSuccess = payload => ({
  type: types.GET_ARTISANS_TAGS_SUCCESS,
  payload
});

export const getArtisansTagsError = error => ({
  type: types.GET_ARTISANS_TAGS_ERROR,
  error
});

export const getArtisansItemsRequest = payload => ({
  type: types.GET_ARTISANS_ITEMS_REQUEST,
  payload
});

export const getArtisansItemsSuccess = payload => ({
  type: types.GET_ARTISANS_ITEMS_SUCCESS,
  payload
});

export const getArtisansItemsError = error => ({
  type: types.GET_ARTISANS_ITEMS_ERROR,
  error
});

export const getArtisanDataRequest = payload => ({
  type: types.GET_ARTISAN_DATA_REQUEST,
  payload
});

export const getArtisanDataSuccess = payload => ({
  type: types.GET_ARTISAN_DATA_REQUEST_SUCCESS,
  payload
});

export const getArtisanDataError = payload => ({
  type: types.GET_ARTISAN_DATA_REQUEST_ERROR,
  payload
});

export const clearArtisans = () => ({
  type: types.CLEAR_ARTISANS_ACTION
});

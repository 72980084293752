export * from "./country-list";
export * from "./country-reach-data";
export * from "./england";
export * from "./northern-ireland";
export * from "./scotland";
export * from "./wales";

import { ENGLAND_OPTIONS, ENGLAND_PROPERTIES } from "./england";
import { NORTHERN_IRELAND_OPTIONS, NORTHERN_IRELAND_PROPERTIES } from "./northern-ireland";
import { SCOTLAND_OPTIONS, SCOTLAND_PROPERTIES } from "./scotland";
import { WALES_OPTIONS, WALES_PROPERTIES } from "./wales";

export const UK_OPTION = {
  label: "United Kingdom",
  type: "country",
  address: "United%20Kingdom",
};

export const UK_STATE_OPTIONS = [
  ENGLAND_PROPERTIES,
  NORTHERN_IRELAND_PROPERTIES,
  SCOTLAND_PROPERTIES,
  WALES_PROPERTIES,
];

export const UK_COUNTY_OPTIONS = [
  ...UK_STATE_OPTIONS,
  ...ENGLAND_OPTIONS,
  ...NORTHERN_IRELAND_OPTIONS,
  ...SCOTLAND_OPTIONS,
  ...WALES_OPTIONS,
];

const mapCountyOptions = (options) =>
  options.map(({ county }) => ({
    value: county,
    label: county,
  }));

export const UK_COUNTY_SELECT_OPTIONS = [
  {
    label: "England",
    options: mapCountyOptions(ENGLAND_OPTIONS),
  },
  {
    label: "Northern Ireland",
    options: mapCountyOptions(NORTHERN_IRELAND_OPTIONS),
  },
  {
    label: "Scotland",
    options: mapCountyOptions(SCOTLAND_OPTIONS),
  },
  {
    label: "Wales",
    options: mapCountyOptions(WALES_OPTIONS),
  },
];

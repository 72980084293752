import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";

import auth from "../../../api/auth";
import "../../../assets/styles/auth/social-auth-buttons.scss";

export const SocialAuthButtons = ({ label = "Log In" }) => {
  const [socialAuthKeys, setSocialAuthKeys] = useState({});

  useEffect(() => {
    auth.getSocialAuthKeys()
      .then((resp) => {
        setSocialAuthKeys(resp);
      });
  }, []);

  if (!socialAuthKeys?.google) {
    return null;
  }

  return (
    <div className="social-auth">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <a className="social-auth--button" href={socialAuthKeys?.google}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_4592_1080)">
                <path
                  d="M20.3052 10.2303C20.3052 9.55056 20.2501 8.86711 20.1325 8.19836H10.7002V12.0492H16.1016C15.8775 13.2911 15.1573 14.3898 14.1027 15.0879V17.5866H17.3252C19.2176 15.8449 20.3052 13.2728 20.3052 10.2303Z"
                  fill="#4285F4" />
                <path
                  d="M10.6999 20.0007C13.397 20.0007 15.6714 19.1152 17.3286 17.5866L14.1061 15.088C13.2096 15.6979 12.0521 16.0433 10.7036 16.0433C8.09474 16.0433 5.88272 14.2833 5.08904 11.9169H1.76367V14.4927C3.46127 17.8695 6.91892 20.0007 10.6999 20.0007Z"
                  fill="#34A853" />
                <path
                  d="M5.08564 11.9169C4.66676 10.6749 4.66676 9.33008 5.08564 8.08811V5.51233H1.76395C0.345611 8.33798 0.345611 11.667 1.76395 14.4927L5.08564 11.9169Z"
                  fill="#FBBC04" />
                <path
                  d="M10.6999 3.95805C12.1256 3.936 13.5035 4.47247 14.536 5.45722L17.3911 2.60218C15.5833 0.904587 13.1838 -0.0287217 10.6999 0.000673888C6.91892 0.000673888 3.46126 2.13185 1.76367 5.51234L5.08537 8.08813C5.87537 5.71811 8.09106 3.95805 10.6999 3.95805Z"
                  fill="#EA4335" />
              </g>
              <defs>
                <clipPath id="clip0_4592_1080">
                  <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
              </defs>
            </svg>

            <span className="social-auth--button__text">{`${label} with Google`}</span>
          </a>
        </Col>
        {/*<Col xs={isVertical ? 24 : 12}>*/}
        {/*  <a*/}
        {/*    className="social-auth--button"*/}
        {/*    href={`https://www.facebook.com/v16.0/dialog/oauth?client_id=9133718340033751&redirect_uri=${process.env.REACT_APP_URL}/social-facebook`}*/}
        {/*  >*/}
        {/*    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*      <g clipPath="url(#clip0_4592_1012)">*/}
        {/*        <path*/}
        {/*          d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z"*/}
        {/*          fill="#1877F2" />*/}
        {/*        <path*/}
        {/*          d="M13.8926 12.8906L14.3359 10H11.5625V8.125C11.5625 7.33418 11.95 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93047 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C9.47287 20.0405 10.5271 20.0405 11.5625 19.8785V12.8906H13.8926Z"*/}
        {/*          fill="white" />*/}
        {/*      </g>*/}
        {/*      <defs>*/}
        {/*        <clipPath id="clip0_4592_1012">*/}
        {/*          <rect width="20" height="20" fill="white" />*/}
        {/*        </clipPath>*/}
        {/*      </defs>*/}
        {/*    </svg>*/}

        {/*    <span className="social-auth--button__text">*/}
        {/*      {`${label} with Facebook`}*/}
        {/*    </span>*/}
        {/*  </a>*/}
        {/*</Col>*/}
      </Row>
    </div>
  )
};

SocialAuthButtons.propTypes = {
  label: PropTypes.oneOf(["Log In", "Sign Up"]),
  isVertical: PropTypes.bool,
}

export default SocialAuthButtons;

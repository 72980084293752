import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import loadable from "@loadable/component";
import classNames from "classnames";

// components
import MainSearch from "../MainSearch/MainSearch";
import LoveAntiquesLogo from "../LoveAntiquesLogo";
import SocialIconsGroup from "../SocialIconsGroup";
import FavoritesIcon from "../FavoritesIcon";
import HeaderAuthContent from "../HeaderAuthContent";
import NotificationButton from "../NotificationButton";
import BurgerButton from "../BurgerButton";
import TopBarLink from "../TopBarLink";
import MenuDrawer from "../MenuDrawer";
const HeaderDropdown = loadable(() => import("../HeaderDropdown"));

// redux
import {
  logoutRequest,
  openLoginModalRequest,
  closeNotificationModalRequest,
} from "../../../redux/auth/authActions";
import { getAuthorizedUserProfileRequest } from "../../../redux/profiles/profilesActions";
import { getCategoriesWithoutPaginationRequest } from "../../../redux/home/homeActions";
import { getListOfAllPeriodsRequest } from "../../../redux/periods/periodsActions";
import { getAllNotificationsRequest } from "../../../redux/notifications/notificationsActions";
import {
  createNewSearchEmailPreferenceRequest,
  clearSearchPreferencesValue,
  openNotificationDrawer as openNotificationDrawerAction,
  closeNotificationDrawer as closeNotificationDrawerAction,
} from "../../../redux/general/generalActions";

// hooks
import useServerSafeEffect from "../../../hooks/useServerSafeEffect";
import useDimensions from "../../../hooks/useDimensions";

// helpers
import { TOP_BAR_CATEGORIES } from "../../../constants";

// styles
import "../../../assets/styles/shared/header.scss";

const SEARCH_RESULTS_PATH = "/search-results";

const Header = ({
  isAuthenticated,
  getAuthorizedUserProfile,
  authorizedUserProfile,
  getCategoriesWithoutPagination,
  allCategories,
  getAllPeriods,
  allPeriods,
  closeNotificationModal,
  searchPreference,
  createNewSearchEmailPreference,
  clearStoredSearchPreferencesValue,
  notificationDrawerOpened,
  closeNotificationDrawer,
  getAllNotifications,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedTopBarCategory, setSelectedTopBarCategory] = useState({});

  const headerDropdownRef = useRef(null);

  const [headerRef, { width }] = useDimensions({ liveMeasure: true });
  const history = useHistory();

  const showNotifications =
    isAuthenticated && authorizedUserProfile.role === "DEALER";

  const isSmsModalVisible =
    !isEmpty(authorizedUserProfile) &&
    !authorizedUserProfile.smsNotification &&
    authorizedUserProfile.role === "DEALER";

  // Effects

  // Listen to history pathname changes
  useEffect(() => {
    return history.listen((currentLocation, action) => {
      // Clear "search" query param on POP action
      const query = new URLSearchParams(currentLocation.search);
      if (
        action === "POP" &&
        query.has("search") &&
        currentLocation.pathname !== SEARCH_RESULTS_PATH
      ) {
        query.delete("search");
        history.replace({
          pathname: currentLocation.pathname,
          search: `?${query.toString()}`,
        });
      }
    });
  }, [mobileMenuOpen]);

  useEffect(() => {
    function clickOutsideOfHeaderDropdown(event) {
      if (
        headerDropdownRef.current &&
        headerDropdownRef.current.contains(event.target) &&
        event.target.innerHTML !== "All Antiques"
      ) {
        return;
      }

      setIsDropdownVisible(false);
    }

    document.addEventListener("click", clickOutsideOfHeaderDropdown);

    return () => {
      document.removeEventListener("click", clickOutsideOfHeaderDropdown);
    };
  }, [headerDropdownRef]);

  useEffect(() => {
    isAuthenticated && getAuthorizedUserProfile();
  }, [isAuthenticated]);

  useEffect(() => {
    if (showNotifications) {
      getAllNotifications();
    }
  }, [showNotifications]);

  useServerSafeEffect(() => {
    if (!allCategories || !allCategories.length) {
      getCategoriesWithoutPagination();
    }
    if (!allPeriods || !allPeriods.length) {
      getAllPeriods({ pathname: history.location.pathname });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isEmpty(searchPreference)) {
      createNewSearchEmailPreference(searchPreference);
      clearStoredSearchPreferencesValue();
    }
  }, [searchPreference, isAuthenticated]);

  // Load notifications
  useEffect(() => {
    if (showNotifications) {
      getAllNotifications();
    }
  }, [showNotifications]);

  const getCurrentDateAndTime = () => {
    const date = new Date();

    const currentDate = date.toLocaleString().slice(0, 10);
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    return `${currentDate} ${currentTime}`;
  };

  // Methods

  const handleTriggerNotificationModal = (shouldRedirect = false) => {
    const dataToSend = {
      smsNotification: true,
    };

    closeNotificationModal(dataToSend);

    if (shouldRedirect) {
      setTimeout(
        () => history.push({ pathname: "/profile/paypal/settings" }),
        1000
      );
    }
  };

  const subcategories = [];

  for (const category of TOP_BAR_CATEGORIES) {
    if (category.children) {
      subcategories.push(...category.children);
    }
  }

  return (
    <header
      className="header"
      ref={headerRef}
      data-html2canvas-ignore="true"
      onClick={() => {
        if (notificationDrawerOpened) closeNotificationDrawer();
      }}
    >
      <div className="header--timestamp">{getCurrentDateAndTime()}</div>

      <div className="page-container">
        {width > 1200 ? (
          <>
            <div
              className="header--row header--logo-row"
              style={{ height: "40px" }}
            >
              <LoveAntiquesLogo width={width} />

              <MainSearch />

              <div className="header--right-block xl-hidden">
                <SocialIconsGroup />

                <div className="header--favorites-and-notifications">
                  <FavoritesIcon
                    closeMobileDrawer={() => setMobileMenuOpen(false)}
                  />

                  <NotificationButton
                    closeMobileDrawer={() => setMobileMenuOpen(false)}
                    mobileMenuOpen={mobileMenuOpen}
                  />
                </div>

                <HeaderAuthContent
                  closeMobileDrawer={() => setMobileMenuOpen(false)}
                />
              </div>
            </div>

            <div className="header--row xl-hidden">
              <div className="header--hidden-categories-links">
                <Link to="/knowledge">Dealers and more</Link>
                {[...TOP_BAR_CATEGORIES, ...subcategories].map((category) => {
                  return (
                    <Link to={category.url} key={category.id}>
                      {category.title}
                    </Link>
                  );
                })}
              </div>
              <div
                ref={headerDropdownRef}
                className="megamenu"
                onClick={() => setIsDropdownVisible(true)}
              >
                {TOP_BAR_CATEGORIES.map((category) => {
                  return (
                    <TopBarLink
                      key={category.id}
                      category={category}
                      setSelectedTopBarCategory={setSelectedTopBarCategory}
                    />
                  );
                })}

                {selectedTopBarCategory.title &&
                  !["All Antiques", "Directory"].includes(
                    selectedTopBarCategory.title
                  ) && (
                  <div
                    className={classNames(
                      "megamenu-dropdown-wrapper",
                      isDropdownVisible ? "shown" : "hidden",

                      /*TODO(LANT-5450): Change top navigation*/
                      // selectedTopBarCategory.title === "Directory and more" && "directory"
                      selectedTopBarCategory.title === "Dealers and more" &&
                          "directory"
                    )}
                  >
                    <HeaderDropdown
                      category={selectedTopBarCategory}
                      setDropdownVisible={setIsDropdownVisible}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="header--mobile-search">
              <div className="header--mobile-search-logo-container">
                <LoveAntiquesLogo width={width} />
              </div>
              <div className="header--mobile-search-container">
                <MainSearch />

                <BurgerButton
                  openMenuDrawer={() => setMobileMenuOpen(true)}
                  mobileMenuOpen={mobileMenuOpen}
                />
              </div>
            </div>

            <MenuDrawer
              closeMobileDrawer={() => setMobileMenuOpen(false)}
              mobileMenuOpen={mobileMenuOpen}
            />
          </>
        )}

        <Modal
          centered
          onOk={() => handleTriggerNotificationModal(true)}
          onCancel={() => handleTriggerNotificationModal()}
          className="sms-notification-modal"
          closable={false}
          open={isSmsModalVisible}
        >
          <p>Please enable PayPal to be able to receive online payments.</p>
        </Modal>
      </div>
    </header>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  getAuthorizedUserProfile: PropTypes.func.isRequired,
  authorizedUserProfile: PropTypes.object.isRequired,
  allPeriods: PropTypes.array.isRequired,
  getCategoriesWithoutPagination: PropTypes.func.isRequired,
  allCategories: PropTypes.array.isRequired,
  getAllPeriods: PropTypes.func.isRequired,
  closeNotificationModal: PropTypes.func.isRequired,
  searchPreference: PropTypes.object.isRequired,
  createNewSearchEmailPreference: PropTypes.func.isRequired,
  clearStoredSearchPreferencesValue: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    authorizedUserProfile: state.auth.authorizedUserProfile,
    allCategories: state.home.allCategories,
    allPeriods: state.periods.allPeriods,
    searchPreference: state.general.searchPreference,
    notificationDrawerOpened: state.general.notificationDrawerOpened,
  }),
  (dispatch) => ({
    logout: () => dispatch(logoutRequest()),
    openLoginModal: () => dispatch(openLoginModalRequest()),
    getAuthorizedUserProfile: (data) => dispatch(getAuthorizedUserProfileRequest(data)),
    getCategoriesWithoutPagination: () => dispatch(getCategoriesWithoutPaginationRequest()),
    getAllPeriods: (location) => dispatch(getListOfAllPeriodsRequest(location)),
    closeNotificationModal: (data) => dispatch(closeNotificationModalRequest(data)),
    createNewSearchEmailPreference: (data) => dispatch(createNewSearchEmailPreferenceRequest(data)),
    clearStoredSearchPreferencesValue: () => dispatch(clearSearchPreferencesValue()),
    openNotificationDrawer: () => dispatch(openNotificationDrawerAction()),
    closeNotificationDrawer: () => dispatch(closeNotificationDrawerAction()),
    getAllNotifications: () => dispatch(getAllNotificationsRequest()),
  })
)(Header);

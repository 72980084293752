export const WALES_PROPERTIES = {
  country: "United Kingdom",
  country_code: "gb",
  county: "Wales",
  state: "Wales",
  url: "wales",
  latitude: 52.1307,
  longitude: -3.7837,
  zoom: 5,
  radius: 150,
};

export const WALES_OPTIONS = [
  {
    county: "Anglesey",
    state: "Wales",
    country_code: "gb",
    url: "anglesey",
    latitude: 53.20274,
    longitude: -4.20158,
    zoom: 9,
    radius: 34,
  },
  {
    county: "Blaenau Gwent",
    state: "Wales",
    country_code: "gb",
    url: "blaenau-gwent",
    latitude: 51.7706,
    longitude: -3.1719,
    zoom: 8,
    radius: 9,
  },
  {
    county: "Brecknockshire",
    state: "Wales",
    country_code: "gb",
    url: "brecknockshire",
    latitude: 51.83917,
    longitude: -3.14072,
    zoom: 9,
    radius: 30,
  },
  {
    county: "Bridgend",
    state: "Wales",
    country_code: "gb",
    url: "bridgend",
    latitude: 51.5064,
    longitude: -3.5786,
    zoom: 11,
    radius: 13,
  },
  {
    county: "Caerphilly",
    state: "Wales",
    country_code: "gb",
    url: "caerphilly",
    latitude: 51.5768,
    longitude: -3.2183,
    zoom: 10,
    radius: 15,
  },
  {
    county: "Caernarfonshire",
    state: "Wales",
    country_code: "gb",
    url: "caernarfonshire",
    latitude: 53.1399,
    longitude: -4.2739,
    zoom: 8,
    radius: 37,
  },
  {
    county: "Cardiganshire",
    state: "Wales",
    country_code: "gb",
    url: "cardiganshire",
    latitude: 52.12843,
    longitude: -3.74496,
    zoom: 8,
    radius: 60,
  },
  {
    county: "Cardiff",
    state: "Wales",
    country_code: "gb",
    url: "cardiff",
    latitude: 51.4816,
    longitude: -3.1791,
    zoom: 8,
    radius: 11,
  },
  {
    county: "Carmarthenshire",
    state: "Wales",
    country_code: "gb",
    url: "carmarthenshire",
    latitude: 51.72022,
    longitude: -3.87955,
    zoom: 8,
    radius: 60,
  },
  {
    county: "Ceredigion",
    state: "Wales",
    country_code: "gb",
    url: "ceredigion",
    latitude: 52.2429,
    longitude: -3.9685,
    zoom: 8,
    radius: 41,
  },
  {
    county: "Clwyd",
    state: "Wales",
    country_code: "gb",
    url: "clwyd",
    latitude: 53.10577,
    longitude: -3.23874,
    zoom: 10,
    radius: 16,
  },
  {
    county: "Conwy",
    state: "Wales",
    country_code: "gb",
    url: "conwy",
    latitude: 53.2804,
    longitude: -3.828,
    zoom: 8,
    radius: 23,
  },
  {
    county: "Denbighshire",
    state: "Wales",
    country_code: "gb",
    url: "denbighshire",
    latitude: 52.95195,
    longitude: -3.11874,
    zoom: 10,
    radius: 28,
  },
  {
    county: "Dyfed",
    state: "Wales",
    country_code: "gb",
    url: "dyfed",
    latitude: 51.9971,
    longitude: -4.4714,
    zoom: 8,
    radius: 70,
  },
  {
    county: "Flintshire",
    state: "Wales",
    country_code: "gb",
    url: "flintshire",
    latitude: 53.13936,
    longitude: -2.99721,
    zoom: 9,
    radius: 32,
  },
  {
    county: "Glamorgan",
    state: "Wales",
    country_code: "gb",
    url: "glamorgan",
    latitude: 51.42,
    longitude: -3.28,
    zoom: 8,
    radius: 43,
  },
  {
    county: "Gwent",
    state: "Wales",
    country_code: "gb",
    url: "gwent",
    latitude: 51.71001,
    longitude: -3.09986,
    zoom: 9,
    radius: 33,
  },
  {
    county: "Gwynedd",
    state: "Wales",
    country_code: "gb",
    url: "gwynedd",
    latitude: 52.9574,
    longitude: -4.1373,
    zoom: 8,
    radius: 60,
  },
  {
    county: "Isle of Anglesey",
    state: "Wales",
    country_code: "gb",
    url: "isle-of-anglesey",
    latitude: 53.2917,
    longitude: -4.3747,
    zoom: 8,
    radius: 28,
  },
  {
    county: "Merionethshire",
    state: "Wales",
    country_code: "gb",
    url: "merionethshire",
    latitude: 52.64306,
    longitude: -3.55545,
    zoom: 9,
    radius: 24,
  },
  {
    county: "Merthyr Tydfil",
    state: "Wales",
    country_code: "gb",
    url: "merthyr-tydfil",
    latitude: 51.7487,
    longitude: -3.3772,
    zoom: 8,
    radius: 10,
  },
  {
    county: "Mid Glamorgan",
    state: "Wales",
    country_code: "gb",
    url: "mid-glamorgan",
    latitude: 51.44202,
    longitude: -3.15994,
    zoom: 9,
    radius: 21,
  },
  {
    county: "Monmouthshire",
    state: "Wales",
    country_code: "gb",
    url: "monmouthshire",
    latitude: 51.55829,
    longitude: -2.61063,
    zoom: 9,
    radius: 13,
  },
  {
    county: "Montgomeryshire",
    state: "Wales",
    country_code: "gb",
    url: "montgomeryshire",
    latitude: 52.45601,
    longitude: -3.16544,
    zoom: 9,
    radius: 14,
  },
  {
    county: "Neath Port Talbot",
    state: "Wales",
    country_code: "gb",
    url: "neath-port-talbot",
    latitude: 51.6612,
    longitude: -3.8116,
    zoom: 8,
    radius: 35,
  },
  {
    county: "Newport",
    state: "Wales",
    country_code: "gb",
    url: "newport",
    latitude: 51.5842,
    longitude: -2.9977,
    zoom: 8,
    radius: 8,
  },
  {
    county: "Pembrokeshire",
    state: "Wales",
    country_code: "gb",
    url: "pembrokeshire",
    latitude: 51.68788,
    longitude: -4.61014,
    zoom: 9,
    radius: 71,
  },
  {
    county: "Powys",
    state: "Wales",
    country_code: "gb",
    url: "powys",
    latitude: 51.99841,
    longitude: -2.84821,
    zoom: 8,
    radius: 26,
  },
  {
    county: "Radnorshire",
    state: "Wales",
    country_code: "gb",
    url: "radnorshire",
    latitude: 52.08457,
    longitude: -3.00888,
    zoom: 9,
    radius: 20,
  },
  {
    county: "Rhondda Cynon Taf",
    state: "Wales",
    country_code: "gb",
    url: "rhondda-cynon-taf",
    latitude: 51.6105,
    longitude: -3.4183,
    zoom: 8,
    radius: 19,
  },
  {
    county: "South Glamorgan",
    state: "Wales",
    country_code: "gb",
    url: "south-glamorgan",
    latitude: 51.41291,
    longitude: -3.23341,
    zoom: 10,
    radius: 36,
  },
  {
    county: "Swansea",
    state: "Wales",
    country_code: "gb",
    url: "swansea",
    latitude: 51.6214,
    longitude: -3.9436,
    zoom: 8,
    radius: 11,
  },
  {
    county: "Torfaen",
    state: "Wales",
    country_code: "gb",
    url: "torfaen",
    latitude: 51.6888,
    longitude: -3.0107,
    zoom: 8,
    radius: 9,
  },
  {
    county: "Vale of Glamorgan",
    state: "Wales",
    country_code: "gb",
    url: "vale-of-glamorgan",
    latitude: 51.3982,
    longitude: -3.3901,
    zoom: 8,
    radius: 13,
  },
  {
    county: "West Glamorgan",
    state: "Wales",
    country_code: "gb",
    url: "west-glamorgan",
    latitude: 51.5839,
    longitude: -3.77449,
    zoom: 9,
    radius: 40,
  },
  {
    county: "Wrexham",
    state: "Wales",
    country_code: "gb",
    url: "wrexham",
    latitude: 52.91967,
    longitude: -2.85988,
    zoom: 10,
    radius: 19,
  },
];

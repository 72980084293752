/* eslint-disable global-require */
import axios from "axios";
import Compressor from "compressorjs";

import fetch from "./customFetch";


export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      minWidth: 100,
      minHeight: 100,
      resize: "contain",

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        resolve(result);
      },
      error(err) {
        console.error(err);

        reject(new Error("An error occurred while compressing this file"));
      }
    });
  });
};

export const convertHeicToJpeg = (file) => {
  // console.info("convertHeicToJpeg", file);

  return new Promise((resolve, reject) => {
    try {
      if (file.type?.endsWith("heic") || file.type?.endsWith("heif")) {
        const heic2any = require("heic2any");

        heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 1
        })
          .then((conversionResult) => {
            const convertedFile = new File(
              [conversionResult],
              file?.name?.replace("heic", "jpeg")?.replace("heif", "jpeg") || "name.jpeg",
              { type: "image/jpeg" }
            );

            compressImage(convertedFile)
              .then((compressedFile) => {
                resolve(compressedFile);
              })
              .catch((err) => {
                resolve(convertedFile);
                console.error(err);
              });
          })
          .catch(console.error);
      } else {
        resolve(file);
      }
    } catch (err) {
      console.error(err);
      reject(new Error("Error raised when converting heic/heif image"));
    }
  });
};

const general = {
  async uploadImage(data, type = "") {
    try {
      const file = await convertHeicToJpeg(data);
      // console.info("uploadImage", "type", type, "initial file", data, "converted file", file);

      return fetch({
        url: `/static/image/${type ? `?type=${type}` : ""}`,
        headers: { "Content-Type": data.type },
        method: "POST",
        data: file
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async uploadVideo(data) {

    try {
      return await fetch({
        url: `/static/video/${data.name}/`,
        headers: { "Content-Type": data.type },
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCurrencies({ page = 1, pageSize = 20 }) {
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("page_size", pageSize);

    try {
      return await fetch({
        url: `/settings/currency/autocomplete/?${params.toString()}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async rotateImage(data) {
    try {
      return await fetch({
        url: "/static/image/rotate/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async contactUs(data) {
    try {
      return await fetch({
        url: "/index/contact-us/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getUserLocation() {
    try {
      //third party endpoint

      return await axios.get("https://geolocation-db.com/json/");
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  }
};

export default general;

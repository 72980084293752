import moment from "moment";

const MONTH_ARRAY = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

export const convertDatesForFairs = ({ year, month, day }) => {
  const monthNumber = MONTH_ARRAY.findIndex((el) => el === month) + 1;

  const current = year && monthNumber
    ? moment(`${year}-${monthNumber}${day ? `-${day}` : ""}`)
    : moment();

  return {
    fromDate: current.clone().startOf("month"),
    current: current,
    toDate: current.clone().endOf("month"),
  };
};

export const generateDaysOfMonthArray = (startDate, endDate, format) => {
  let count = 0;
  const dates = [];
  const date = startDate.clone();

  if (startDate.isAfter(endDate)) {
    return [];
  }

  while (date.isSameOrBefore(endDate, "days")) {
    dates.push(format ? date.format(format) : date.clone());
    date.add(1, "days");
    count += 1;

    if (count > 31) {
      break;
    }
  }

  return dates;
};

export const generateFairEventDates = (fromDate, toDate) => {
  const startDate = moment(fromDate).format("D MMM YYYY");
  const endDate = toDate && fromDate !== toDate ? moment(toDate).format("D MMM YYYY") : "";

  return `${startDate}${endDate ? ` ... ${endDate}` : ""}`;
};

// helpers
import { objectToQueryString } from "../helpers";
import fetch from "./customFetch";

const homeAndLiving = {
  async getHomeAndLivingItems({
    page = 1,
    pageSize = 10,
    ordering,
    priceRange,
    forImpressions = false
  }) {
    const query = objectToQueryString({
      page,
      page_size: pageSize,
      ordering,
      price_min: priceRange && priceRange.length === 2 ? priceRange[0] : null,
      price_max: priceRange && priceRange.length === 2 ? priceRange[1] : null,
      for_impressions: forImpressions,
    });

    try {
      return await fetch({
        url: `/storefront/home-and-living/items/?${query}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getHomeAndLivingCategoryData(url) {
    try {
      return await fetch({
        url: `/storefront/home-and-living/${url}/`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getHomeAndLivingItemsByCategory({
    page = 1,
    pageSize = 10,
    category,
    ordering,
    priceRange,
    forImpressions = false,
  }) {
    const query = objectToQueryString({
      page,
      page_size: pageSize,
      ordering: ordering !== "sold" ? ordering : null,
      home_and_living: category,
      is_sold: `${ordering === "sold"}`,
      price_min: priceRange && priceRange.length === 2 ? priceRange[0] : null,
      price_max: priceRange && priceRange.length === 2 ? priceRange[1] : null,
      for_impressions: forImpressions,
    });

    try {
      return await fetch({
        url: `/storefront/home-and-living/items/?${query}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getHomeAndLivingTopItems() {
    const query = new URLSearchParams();
    query.set("page", "1");
    query.set("page_size", "12");

    try {
      return await fetch({
        url: `/storefront/home-and-living/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  }
};

export default homeAndLiving;

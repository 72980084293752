export const GET_ARTISANS_REQUEST = "GET_ARTISANS_REQUEST";
export const GET_ARTISANS_ERROR = "GET_ARTISANS_ERROR";
export const GET_ARTISANS_SUCCESS = "GET_ARTISANS_SUCCESS";

export const GET_ARTISANS_TAGS_REQUEST = "GET_ARTISANS_TAGS_REQUEST";
export const GET_ARTISANS_TAGS_ERROR = "GET_ARTISANS_TAGS_ERROR";
export const GET_ARTISANS_TAGS_SUCCESS = "GET_ARTISANS_TAGS_SUCCESS";

export const GET_ARTISANS_ITEMS_REQUEST = "GET_ARTISANS_ITEMS_REQUEST";
export const GET_ARTISANS_ITEMS_ERROR = "GET_ARTISANS_ITEMS_ERROR";
export const GET_ARTISANS_ITEMS_SUCCESS = "GET_ARTISANS_ITEMS_SUCCESS";

export const SHOW_ARTISANS_ITEMS_LOADING = "SHOW_ARTISANS_ITEMS_LOADING";
export const HIDE_ARTISANS_ITEMS_LOADING = "HIDE_ARTISANS_ITEMS_LOADING";

export const GET_ARTISAN_DATA_REQUEST = "GET_ARTISAN_DATA_REQUEST";
export const GET_ARTISAN_DATA_REQUEST_ERROR = "GET_ARTISAN_DATA_REQUEST_ERROR";
export const GET_ARTISAN_DATA_REQUEST_SUCCESS = "GET_ARTISAN_DATA_REQUEST_SUCCESS";

export const SHOW_ARTISAN_DATA_LOADING = "SHOW_ARTISAN_DATA_LOADING";
export const HIDE_ARTISAN_DATA_LOADING = "HIDE_ARTISAN_DATA_LOADING";

export const CLEAR_ARTISANS_ACTION = "CLEAR_ARTISANS_ACTION";

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

// components
import UserAvatar from "./UserAvatar";

// redux
import {
  logoutRequest,
  openLoginModalRequest,
} from "../../redux/auth/authActions";

const HeaderAuthContent = ({
  isAuthenticated,
  logout,
  openLoginModal,
  authorizedUserProfile,
  closeMobileDrawer,
}) => {
  const history = useHistory();

  const onHandleProfile = () => {
    history.push({ pathname: "/profile/home" });
    closeMobileDrawer();
  };

  const onHandleLogOut = () => {
    logout();
    closeMobileDrawer();
  };

  const onHandleLogin = (event) => {
    event.preventDefault();
    history.push({ pathname: "/registration" });
    closeMobileDrawer();
  };

  const onHandleLogIn = () => {
    openLoginModal();
    closeMobileDrawer();
  };

  return (
    <div className="header--auth-control">
      {isAuthenticated ? (
        <>
          <UserAvatar />

          <p className="header--auth-name" onClick={onHandleProfile}>
            {authorizedUserProfile?.firstName || ""}
          </p>

          <Button
            size="large"
            className="header--auth-button--logout"
            onClick={onHandleLogOut}
          >
            Log out
          </Button>
        </>
      ) : (
        <>
          <Button
            href="/registration"
            className="header--auth-link"
            onClick={onHandleLogin}
          >
            Register
          </Button>
          <Button
            className="header--auth-button"
            size="large"
            onClick={onHandleLogIn}
          >
            Log in
          </Button>
        </>
      )}
    </div>
  );
};

HeaderAuthContent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  authorizedUserProfile: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    authorizedUserProfile: state.auth.authorizedUserProfile,
  }),
  (dispatch) => ({
    logout: () => dispatch(logoutRequest()),
    openLoginModal: () => dispatch(openLoginModalRequest()),
  })
)(HeaderAuthContent);

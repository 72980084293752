import * as types from "./catalogsActionsTypes";

const initialState = {
  materials: {},
  topMaterials: [],
  origins: {},
  topOrigins: [],
  error: null,
  isTopLoading: false,
  isLoading: false,
  materialsSearchResult: [],
  materialsSearchLoading: false,
  originsSearchResult: [],
  originsSearchLoading: false,
  originItems: [],
  originItemsCount: 0,
  originItemsLoading: false,
  originData: [],
  originDataLoading: false,
  materialItems: [],
  materialItemsCount: 0,
  materialItemsLoading: false,
  materialData: {},
  materialDataLoading: false,
  originError: null,
  materialError: null,
};

const catalogs = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MOST_POPULAR_MATERIALS_REQUEST:
    case types.GET_MOST_POPULAR_ORIGINS_REQUEST:
      return {
        ...state,
        isTopLoading: true,
        error: null,
      };

    case types.GET_LIST_OF_MATERIALS_REQUEST:
    case types.GET_LIST_OF_ORIGINS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.GET_LIST_OF_MATERIALS_REQUEST_SUCCESS:
      return {
        ...state,
        materials: action.payload,
        isLoading: false,
        error: null,
      };

    case types.GET_MOST_POPULAR_MATERIALS_REQUEST_SUCCESS:
      return {
        ...state,
        topMaterials: action.payload,
        isTopLoading: false,
        error: null,
      };

    case types.GET_LIST_OF_ORIGINS_REQUEST_SUCCESS:
      return {
        ...state,
        origins: action.payload,
        isLoading: false,
        error: null,
      };

    case types.GET_MOST_POPULAR_ORIGINS_REQUEST_SUCCESS:
      return {
        ...state,
        topOrigins: action.payload,
        isTopLoading: false,
        error: null,
      };

    case types.GET_MOST_POPULAR_MATERIALS_REQUEST_ERROR:
    case types.GET_LIST_OF_MATERIALS_REQUEST_ERROR:
    case types.GET_MOST_POPULAR_ORIGINS_REQUEST_ERROR:
    case types.GET_LIST_OF_ORIGINS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isTopLoading: false,
      };

    case types.SHOW_MATERIALS_SEARCH_LOADING:
      return {
        ...state,
        materialsSearchLoading: true,
      };

    case types.HIDE_MATERIALS_SEARCH_LOADING:
      return {
        ...state,
        materialsSearchLoading: false,
      };

    case types.SHOW_ORIGINS_SEARCH_LOADING:
      return {
        ...state,
        originsSearchLoading: true,
      };

    case types.HIDE_ORIGINS_SEARCH_LOADING:
      return {
        ...state,
        originsSearchLoading: false,
      };

    case types.SEARCH_MATERIALS_REQUEST_SUCCESS:
      return {
        ...state,
        materialsSearchResult: action.payload.results,
        error: null,
      };

    case types.SEARCH_ORIGINS_REQUEST_SUCCESS:
      return {
        ...state,
        originsSearchResult: action.payload.results,
        error: null,
      };

    case types.SEARCH_MATERIALS_REQUEST_ERROR:
    case types.SEARCH_ORIGINS_REQUEST_ERROR:
    case types.GET_ORIGIN_ITEMS_REQUEST_ERROR:
    case types.GET_MATERIAL_ITEMS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_ORIGIN_DATA_REQUEST_ERROR:
      return {
        ...state,
        originError: action.payload,
      }

    case types.GET_MATERIAL_DATA_REQUEST_ERROR:
      return {
        ...state,
        materialError: action.payload
      }

    case types.GET_ORIGIN_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        originItems: action.payload.results,
        originItemsCount: action.payload.count,
      };

    case types.SHOW_ORIGIN_ITEMS_LOADING:
      return {
        ...state,
        originItemsLoading: true,
      };

    case types.HIDE_ORIGIN_ITEMS_LOADING:
      return {
        ...state,
        originItemsLoading: false,
      };

    case types.GET_MATERIAL_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        materialItems: action.payload.results,
        materialItemsCount: action.payload.count,
      };

    case types.SHOW_MATERIAL_ITEMS_LOADING:
      return {
        ...state,
        materialItemsLoading: true,
      };

    case types.HIDE_MATERIAL_ITEMS_LOADING:
      return {
        ...state,
        materialItemsLoading: false,
      };

    case types.GET_MATERIAL_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        materialData: action.payload,
        materialError: null,
      };

    case types.SHOW_MATERIAL_DATA_LOADING:
      return {
        ...state,
        materialDataLoading: true,
      };

    case types.HIDE_MATERIAL_DATA_LOADING:
      return {
        ...state,
        materialDataLoading: false,
      };

    case types.GET_ORIGIN_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        originData: action.payload,
        originError: null,
      };

    case types.SHOW_ORIGIN_DATA_LOADING:
      return {
        ...state,
        originDataLoading: true,
      };

    case types.HIDE_ORIGIN_DATA_LOADING:
      return {
        ...state,
        originDataLoading: false,
      };

    default:
      return state;
  }
};

export default catalogs;

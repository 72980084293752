import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const DirectionIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6667 0L11.56 0.02L8 1.4L4 0L0.24 1.26667C0.1 1.31333 0 1.43333 0 1.58667V11.6667C0 11.8533 0.146667 12 0.333333 12L0.44 11.98L4 10.6L8 12L11.76 10.7333C11.9 10.6867 12 10.5667 12 10.4133V0.333333C12 0.146667 11.8533 0 11.6667 0ZM8 10.6667L4 9.26V1.33333L8 2.74V10.6667Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FF0000"
        />
      </svg>
    </span>
  );
};

DirectionIcon.propTypes = {
  className: PropTypes.string,
};

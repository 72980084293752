import { takeLatest, call, put, all } from "redux-saga/effects";
import * as types from "./hnlActionsTypes";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import hnlApi from "../../api/homeAndLiving";
import {
  getTopItemsSuccess,
  getTopItemsError,
  getHomeAndLivingItemsByCategorySuccess,
  getHomeAndLivingItemsByCategoryError,
  getHomeAndLivingCategoryDataSuccess,
  getHomeAndLivingCategoryDataError,
} from "./hnlActions";

function* getTopItemsRequest() {
  try {
    const response = yield call(hnlApi.getHomeAndLivingTopItems);
    yield put(getTopItemsSuccess(response));
  } catch (error) {
    yield put(getTopItemsError(error));
  }
}

function* getHomeAndLivingItemsByCategory(action) {
  yield put({ type: types.SET_HNL_ITEMS_LOADING });

  try {
    const response = yield call(hnlApi.getHomeAndLivingItemsByCategory, action.payload);
    yield put(getHomeAndLivingItemsByCategorySuccess(response));
  } catch (error) {
    yield put(getHomeAndLivingItemsByCategoryError(error));
  } finally {
    yield put({ type: types.RESET_HNL_ITEMS_LOADING });
  }
}

function* getHomeAndLivingCategoryData(action) {
  try {
    const response = yield call(hnlApi.getHomeAndLivingCategoryData, action.payload);
    yield put(getHomeAndLivingCategoryDataSuccess(response));
  } catch (error) {
    yield put(getHomeAndLivingCategoryDataError(error));
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_TOP_ITEMS_REQUEST, serverSagaWrapper(getTopItemsRequest)),
    yield takeLatest(types.GET_ITEMS_BY_CATEGORY_REQUEST, serverSagaWrapper(getHomeAndLivingItemsByCategory)),
    yield takeLatest(types.GET_HNL_CATEGORY_DATA_REQUEST, serverSagaWrapper(getHomeAndLivingCategoryData)),
  ])
}

import { createBrowserHistory } from "history";

let history = null;

// SSR part
if (typeof window === "undefined") {
  history = {
    location: {
      pathname: ""
    }
  }
}
// Client side part
else {
  history = createBrowserHistory();
}


export default history;

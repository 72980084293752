import * as types from "./homeActionsTypes";


export const initialState = {
  loading: false,
  mostPopular: [],
  error: "",
  items: [],
  itemsCount: 0,
  itemsLoading: false,
  allCategories: [],
  allCategoriesLoading: false,
  homeBlogs: [],
};


const home = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case types.TOGGLE_ALL_CATEGORIES_LOADING:
      return {
        ...state,
        allCategoriesLoading: action.payload,
      };

    case types.HOME_REQUEST_ERROR:
    case types.GET_ALL_HOME_CATEGORIES_ERROR:
    case types.GET_ALL_HOME_BLOGS_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.HOME_RESET_ERROR:
      return {
        ...state,
        error: ""
      };

    case types.REQUEST_MOST_POPULAR_SUCCESS:
      return {
        ...state,
        mostPopular: action.payload,
      };

    case types.TOGGLE_HOME_ITEMS_LOADING:
      return {
        ...state,
        itemsLoading: action.payload,
      };

    case types.REQUEST_HOME_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        itemsCount: action.payload.count,
      };

    case types.GET_ALL_HOME_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: action.payload,
      };

    case types.GET_ALL_HOME_BLOGS_SUCCESS:
      return {
        ...state,
        homeBlogs: action.payload.results
      };

    default:
      return state;
  }
};

export default home;

export const DEFAULT_LOGO_SETTINGS = {
  text: "Logo",
  secondLineText: "",
  style: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Dancing Script"
  }
};

export const WEBSITE_PALETTE_COLORS = {
  "PALETTE1": {
    text: "#3F4C5B",
    background: "#FEC393",
    primary: "#E93C40"
  },
  "PALETTE2": {
    text: "#4C5E69",
    background: "#F3E9E4",
    primary: "#1E3558"
  },
  "PALETTE3": {
    text: "#000000",
    background: "#FBFBFB",
    primary: "#3F4C5B"
  },
  "PALETTE4": {
    text: "#FFFFFF",
    background: "#2B2C2C",
    primary: "#D7612F"
  },
  "PALETTE5": {
    text: "#FFF",
    background: "#22343C",
    primary: "#FFC53D"
  },
  "PALETTE6": {
    text: "#FFD476",
    background: "#461412",
    primary: "#D67739"
  },
  "PALETTE7": {
    text: "#FFF",
    background: "#1A1D21",
    primary: "#0576B9"
  },
  "PALETTE8": {
    text: "#DD4147",
    background: "#FFEB84",
    primary: "#591035"
  },
  "PALETTE9": {
    text: "#2153FF",
    background: "#FFF",
    primary: "#001A5E"
  },
  "PALETTE10": {
    text: "#FFF",
    background: "#1A1E20",
    primary: "#3FDB9C"
  },
  "PALETTE11": {
    text: "#D67739",
    background: "#F8F8FA",
    primary: "#591035"
  },
};

export const WEBSITE_DEFAULT_VALUES = {
  websiteType: "SALES",
  isLatestItems: "yes",
  stockPageLayout: "OPTION2",
  isBlogPage: "no",
  isGallery: "no",
  colorPalette: "PALETTE3",
  siteImages: [{
    name: "photo-1589674536249-62d7ea50d6e1.jpeg",
    url: `${process.env.REACT_APP_IMAGES_URL}webbuilder/photo-1589674536249-62d7ea50d6e1.jpeg`,
    originalUrl: `${process.env.REACT_APP_IMAGES_URL}webbuilder/photo-1589674536249-62d7ea50d6e1.jpeg`,
    priority: 1,
  }],
  coverImage1: "photo-1589674536249-62d7ea50d6e1.jpeg",
  logoType: "TEXT",
  useCustomFavicon: "generatedFavicon",
  customFavicon: {
    backgroundColor: "#fa0707",
    borderRadius: 24,
    color: "#ffffff",
    fontFamily: "Kaushan Script",
    fontSize: "20px",
    fontWeight: 700,
    text: null
  }
};

import * as types from "./ordersActionsTypes";

export const getDealerOrdersRequest = (payload) => ({
  type: types.GET_DEALER_ORDERS_REQUEST,
  payload
});

export const getDealerOrdersRequestError = (payload) => ({
  type: types.GET_DEALER_ORDERS_REQUEST_ERROR,
  payload
});

export const getDealerOrdersRequestSuccess = (payload) => ({
  type: types.GET_DEALER_ORDERS_REQUEST_SUCCESS,
  payload
});

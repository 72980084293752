import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const EmptyIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g />
      </svg>
    </span>
  );
};

EmptyIcon.propTypes = {
  className: PropTypes.string,
};

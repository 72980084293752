import { all, put, call, takeLatest } from "redux-saga/effects";

import orders from "../../api/orders";
import * as types from "./ordersActionsTypes";
import {
  getDealerOrdersRequestError,
  getDealerOrdersRequestSuccess,
} from "./ordersActions";

import serverSagaWrapper from "../../helpers/serverSagaWrapper";


function *getPeriodItems({ payload }) {
  yield put({ type: types.DEALER_ORDERS_LOADING, payload: true });
  try {
    const response = yield call(orders.getDealerOrders, payload);
    yield put(getDealerOrdersRequestSuccess(response));
  } catch (error) {
    yield put(getDealerOrdersRequestError(error.message));
  } finally {
    yield put({ type: types.DEALER_ORDERS_LOADING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_DEALER_ORDERS_REQUEST, serverSagaWrapper(getPeriodItems)),
  ])
}

import { useState, useCallback, useLayoutEffect } from "react";
import { useSelector } from "react-redux";


function getDimensionObject(node) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    innerWidth: window.innerWidth,
  };
}

function useDimensions(args = {}) {
  const liveMeasure = typeof args.liveMeasure === "undefined" ? true : args.liveMeasure;
  const isMobile = useSelector(state => state.general.isMobile);

  const [dimensions, setDimensions] = useState({ width: isMobile ? 300 : 1000 });
  const [node, setNode] = useState(null);

  const ref = useCallback(function (nodeEl) {
    setNode(nodeEl);
  }, []);

  useLayoutEffect(function () {
    function measure() {
      return window.requestAnimationFrame(function () {
        return setDimensions(getDimensionObject(node));
      });
    }

    if (node) {
      measure();

      if (liveMeasure) {
        window.addEventListener("resize", measure);

        return function () {
          window.removeEventListener("resize", measure);
        };
      }
    }
  }, [node]);

  return [ref, dimensions, node];
}

export default useDimensions;

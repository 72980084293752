export const FAV_ITEMS_BASE_GROUP_NAME = "All Favourites";

export const DEFAULT_PAGE_TITLE = "Antiques For Sale UK - Buy & Sell Antiques Online";

export const DEFAULT_PAGE_SIZE_OPTIONS = [
  { value: 40, label: "40" },
  { value: 96, label: "96" }
];

export const SMALL_PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 45, label: "45" },
];

export const DEFAULT_SORTING_OPTIONS = [
  { value: "-date", label: "Newest" },
  { value: "date", label: "Oldest" },
  { value: "-price", label: "Price High/Low" },
  { value: "price", label: "Price Low/High" }
];

export const GET_LIST_OF_DEALERS_REQUEST = "GET_LIST_OF_DEALERS_REQUEST";
export const GET_LIST_OF_DEALERS_REQUEST_ERROR = "GET_LIST_OF_DEALERS_REQUEST_ERROR";
export const GET_LIST_OF_DEALERS_REQUEST_SUCCESS = "GET_LIST_OF_DEALERS_REQUEST_SUCCESS";

export const GET_DEALER_NEWS_REQUEST = "GET_DEALER_NEWS_REQUEST";
export const GET_DEALER_NEWS_REQUEST_ERROR = "GET_DEALER_NEWS_REQUEST_ERROR";
export const GET_DEALER_NEWS_REQUEST_SUCCESS = "GET_DEALER_NEWS_REQUEST_SUCCESS";

export const SHOW_LOADING = "SHOW_DEALERS_LOADING";
export const HIDE_LOADING = "HIDE_DEALERS_LOADING";

export const SHOW_DEALERS_NEWS_LOADING = "SHOW_DEALERS_NEWS_LOADING";
export const HIDE_DEALERS_NEWS_LOADING = "HIDE_DEALERS_NEWS_LOADING";

export const GET_SINGLE_DEALER_REQUEST = "GET_SINGLE_DEALER_REQUEST";
export const GET_SINGLE_DEALER_SUCCESS = "GET_SINGLE_DEALER_SUCCESS";
export const GET_SINGLE_DEALER_ERROR = "GET_SINGLE_DEALER_ERROR";

export const GET_SINGLE_DEALERS_ITEMS_REQUEST = "GET_SINGLE_DEALERS_ITEMS_REQUEST";
export const GET_SINGLE_DEALERS_ITEMS_SUCCESS = "GET_SINGLE_DEALERS_ITEMS_SUCCESS";
export const GET_SINGLE_DEALERS_ITEMS_ERROR = "GET_SINGLE_DEALERS_ITEMS_ERROR";

export const GET_SINGLE_DEALER_TESTIMONIALS_REQUEST = "GET_SINGLE_DEALER_TESTIMONIALS_REQUEST";
export const GET_SINGLE_DEALER_TESTIMONIALS_SUCCESS = "GET_SINGLE_DEALER_TESTIMONIALS_SUCCESS";
export const GET_SINGLE_DEALER_TESTIMONIALS_ERROR = "GET_SINGLE_DEALERS_ITEMS_ERROR";

export const GET_MOST_POPULAR_DEALERS_REQUEST = "GET_MOST_POPULAR_DEALERS_REQUEST";
export const GET_MOST_POPULAR_DEALERS_REQUEST_SUCCESS = "GET_MOST_POPULAR_DEALERS_REQUEST_SUCCESS";
export const GET_MOST_POPULAR_DEALERS_REQUEST_ERROR = "GET_MOST_POPULAR_DEALERS_REQUEST_ERROR";

export const SHOW_MOST_POPULAR_DEALERS_LOADING = "SHOW_MOST_POPULAR_DEALERS_LOADING";
export const HIDE_MOST_POPULAR_DEALERS_LOADING = "HIDE_MOST_POPULAR_DEALERS_LOADING";

export const CLEAR_DEALER_DATA = "CLEAR_DEALER_DATA";

export const SET_DEALER_NOT_FOUND = "SET_DEALER_NOT_FOUND";

export const HOME_REQUEST_ERROR = "HOME_REQUEST_ERROR";
export const HOME_RESET_ERROR = "HOME_RESET_ERROR";

export const TOGGLE_LOADING = "TOGGLE_HOME_LOADING";
export const TOGGLE_ALL_CATEGORIES_LOADING = "TOGGLE_ALL_CATEGORIES_LOADING";

export const REQUEST_MOST_POPULAR = "REQUEST_MOST_POPULAR";
export const REQUEST_MOST_POPULAR_SUCCESS = "REQUEST_MOST_POPULAR_SUCCESS";

export const TOGGLE_HOME_ITEMS_LOADING = "TOGGLE_HOME_ITEMS_LOADING";
export const REQUEST_HOME_ITEMS = "REQUEST_HOME_ITEMS";
export const REQUEST_HOME_ITEMS_SUCCESS = "REQUEST_HOME_ITEMS_SUCCESS";

export const GET_ALL_HOME_CATEGORIES_REQUEST = "GET_ALL_HOME_CATEGORIES_REQUEST";
export const GET_ALL_HOME_CATEGORIES_ERROR = "GET_ALL_HOME_CATEGORIES_ERROR";
export const GET_ALL_HOME_CATEGORIES_SUCCESS = "GET_ALL_HOME_CATEGORIES_SUCCESS";

export const GET_ALL_HOME_BLOGS_REQUEST = "GET_ALL_HOME_BLOGS_REQUEST";
export const GET_ALL_HOME_BLOGS_ERROR = "GET_ALL_HOME_BLOGS_ERROR";
export const GET_ALL_HOME_BLOGS_SUCCESS = "GET_ALL_HOME_BLOGS_SUCCESS";

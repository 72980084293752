import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const CalendarIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M13.3846 3H2.61538C1.72323 3 1 3.67157 1 4.5V13.5C1 14.3284 1.72323 15 2.61538 15H13.3846C14.2768 15 15 14.3284 15 13.5V4.5C15 3.67157 14.2768 3 13.3846 3Z"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M4.88462 9H4.11538C4.05166 9 4 9.05596 4 9.125V9.875C4 9.94404 4.05166 10 4.11538 10H4.88462C4.94834 10 5 9.94404 5 9.875V9.125C5 9.05596 4.94834 9 4.88462 9Z"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path d="M4 1V3M12 1V3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15 6H1" strokeWidth="1.5" strokeLinejoin="round" />
        </g>
      </svg>
    </span>
  );
};

CalendarIcon.propTypes = {
  className: PropTypes.string,
};

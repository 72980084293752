import * as types from "./hnlActionsTypes";

export const getTopItemsRequest = (payload) => ({
  type: types.GET_TOP_ITEMS_REQUEST,
  payload,
});

export const getTopItemsSuccess = (payload) => ({
  type: types.GET_TOP_ITEMS_SUCCESS,
  payload,
});

export const getTopItemsError = (payload) => ({
  type: types.GET_TOP_ITEMS_ERROR,
  payload,
});

export const getHomeAndLivingItemsByCategoryRequest = (payload) => ({
  type: types.GET_ITEMS_BY_CATEGORY_REQUEST,
  payload,
});

export const getHomeAndLivingItemsByCategorySuccess = (payload) => ({
  type: types.GET_ITEMS_BY_CATEGORY_SUCCESS,
  payload,
});

export const getHomeAndLivingItemsByCategoryError = (payload) => ({
  type: types.GET_ITEMS_BY_CATEGORY_ERROR,
  payload,
});

export const getHomeAndLivingCategoryDataRequest = (payload) => ({
  type: types.GET_HNL_CATEGORY_DATA_REQUEST,
  payload,
});

export const getHomeAndLivingCategoryDataSuccess = (payload) => ({
  type: types.GET_HNL_CATEGORY_DATA_REQUEST_SUCCESS,
  payload,
});

export const getHomeAndLivingCategoryDataError = (payload) => ({
  type: types.GET_HNL_CATEGORY_DATA_REQUEST_ERROR,
  payload,
});

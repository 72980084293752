const IS_PRODUCTION = process.env.REACT_APP_URL === "https://www.loveantiques.com";

export const HARDCODED_TOP_MATERIALS = [
  {
    title: "Mahogany",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_mahogany.jpg",
    url: "mahogany",
    id: 457,
    alt: "Search antiques made from Mahogany"
  },
  {
    title: "Oak",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_oak.jpg",
    url: "oak",
    id: 464,
    alt: "Search antiques made from Oak"
  },
  {
    title: "Walnut",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_walnut.jpg",
    url: "walnut",
    id: 486,
    alt: "Search antiques made from Walnut",
  },
  {
    title: "Rosewood",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_rosewood.jpg",
    url: "rosewood",
    id: 4464,
    alt: "Search antiques made from Rosewood",
  },
  {
    title: "Bronze",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_bronze.jpg",
    url: "bronze",
    id: 419,
    alt: "Search antiques made from Bronze",
  },
  {
    title: "Brass",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_brass.jpg",
    url: "brass",
    id: 418,
    alt: "Search antiques made from Brass",
  },
  {
    title: "Silver",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_silver.jpg",
    url: "silver",
    id: 476,
    alt: "Search Silver antiques",
  },
  {
    title: "Gold",
    image: "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/materials_gold.jpg",
    url: "gold",
    id: 446,
    alt: "Search antiques made from Gold",
  },
]

export const HARDCODED_TOP_ORIGINS = [
  {
    title: "English",
    image: `${process.env.REACT_APP_IMAGES_URL}englishbanner.png`,
    url: "english",
    alt: "Search English Antiques"
  }, {
    title: "French Antiques",
    image: `${process.env.REACT_APP_IMAGES_URL}frenchbanner.png`,
    url: "french",
    alt: "Search French antiques"
  }, {
    title: "African Antiques",
    image: `${process.env.REACT_APP_IMAGES_URL}africanantiques.png`,
    url: "african",
    alt: "Search African antiques"
  }, {
    title: "Oriental Antiques",
    image: `${process.env.REACT_APP_IMAGES_URL}orientalantiques.png`,
    url: "oriental",
    alt: "Search Oriental Antiques"
  }, {
    title: "Birmingham Assay Office",
    image: `${process.env.REACT_APP_IMAGES_URL}BirminghamAssayOffice.png`,
    url: "birmingham-assay-office",
    alt: "Search Birmingham Assay Office collectables"
  }, {
    title: "Sheffield Assay Office",
    image: `${process.env.REACT_APP_IMAGES_URL}SheffieldAssayOffice.png`,
    url: "sheffield-assay-office",
    alt: "Search Sheffield Assay Office collectables"
  }, {
    title: "Edinburgh Assay Office",
    image: `${process.env.REACT_APP_IMAGES_URL}EdinburghAssayOffice.png`,
    url: "edinburgh-assay-office",
    alt: "Search antiques from Edinburgh Assay Office"
  }, {
    title: "London Assay Office",
    image: `${process.env.REACT_APP_IMAGES_URL}LondonAssayOffice.png`,
    url: "london-assay-office",
    alt: "Search antiques made by London Assay Office"
  }
];

export const HARDCODED_TOP_HOME_AND_LIVING = [
  {
    title: "Dining Room",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/dining-room.jpg`,
    url: "dining-room",
    alt: "Search Items for your Dining Room"
  }, {
    title: "Hallway",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/hallway.jpg`,
    url: "hallway",
    alt: "Search for Hallway Items"
  }, {
    title: "For Him",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/for-him.jpg`,
    url: "for-him",
    alt: "Search Items For Man"
  }, {
    title: "For Her",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/for-her.jpg`,
    url: "for-her",
    alt: "Search Items For Woman"
  }, {
    title: "Office",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/office.jpg`,
    url: "office",
    alt: "Search Items For Office"
  }, {
    title: "Kitchen",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/kitchen.jpg`,
    url: "kitchen",
    alt: "Search Items For Kitchen"
  }, {
    title: "Interior Inspiration",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/interior-inspiration.jpg`,
    url: "interior-inspiration",
    alt: "Search Interior Items"
  }, {
    title: "Bedroom",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/bedroom.jpg`,
    url: "bedroom",
    alt: "Search Items For Bedroom"
  }, {
    title: "Storage & Organisation",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/storage-and-organisation.jpg`,
    url: "storage-and-organisation",
    alt: "Search Storage And Organisation Items"
  }, {
    title: "Lounge & Living",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/lounge-and-living.jpg`,
    url: "lounge-and-living",
    alt: "Search Items For Lounge"
  }, {
    title: "Bathroom",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/bath.jpg`,
    url: "bathroom",
    alt: "Search Bathroom"
  }, {
    title: "Garden & Architectural",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/garden-and-architectural.jpg`,
    url: "garden-and-architectural",
    alt: "Search Items For Garden. Architectural Items"
  }
];

export const HARDCODED_TOP_PERIODS = [
  {
    title: "Georgian (1714-1837)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/Georgian.jpg`,
    url: "georgian-antiques-(1714-to-1837)",
  },
  {
    title: "Regency (1811-1820)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/Regency.jpg`,
    url: "regency-(1811-1820)",
  },
  {
    title: "Victorian (1837-1901)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/Victorian.jpg`,
    url: "victorian-(1837-1901)",
  },
  {
    title: "Arts & Crafts (1880-1920)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/ArtsAndCrafts.jpg`,
    url: "arts-and-crafts-(1880-1920)",
  },
  {
    title: "Art Nouveau (1890-1910)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/ArtNouveau.jpg`,
    url: "art-nouveau-(1890-1910)",
  },
  {
    title: "Edwardian (1901-1910)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/Edwardian.jpg`,
    url: "edwardian-(1901-1910)",
  },
  {
    title: "Art Deco (1925-1940)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/ArtDeco.jpg`,
    url: "art-deco",
  },
  {
    title: "Retro & Vintage (1950s-1970s)",
    image: `${process.env.REACT_APP_IMAGES_URL}media/RetroAndVintage.jpg`,
    url: "retro-and-vintage-(1950s-70s)",
  },
];

export const HARDCODED_ARTISANS_SLIDER_DATA = [
  {
    id: 1,
    url: `${process.env.REACT_APP_URL}/images/royal-doulton.png`,
    name: "Royal Doulton - Maker (RD)",
    path: `/knowledge/artisan/royal-doulton${IS_PRODUCTION ? "-maker" : ""}`,
  },
  {
    id: 2,
    url: `${process.env.REACT_APP_URL}/images/royal-crown-derby.png`,
    name: "Royal Crown Derby - Ceramic Maker / Designer",
    path: "/knowledge/artisan/royal-crown-derby-ceramic-maker-designer",
  },
  {
    id: 3,
    url: `${process.env.REACT_APP_URL}/images/royal-worcester.png`,
    name: "Royal Worcester - Maker",
    path: `/knowledge/artisan/royal-worcester${IS_PRODUCTION ? "-maker" : ""}`,
  },
  {
    id: 4,
    url: `${process.env.REACT_APP_URL}/images/wedgwood.png`,
    name: "Wedgwood - Designer",
    path: `/knowledge/artisan/wedgwood${IS_PRODUCTION ? "-designer" : ""}`,
  },
  {
    id: 5,
    url: `${process.env.REACT_APP_IMAGES_URL}storefront_images/artisans/william_comyns.png`,
    name: "William Comyns - Silversmith",
    path: `/knowledge/artisan/william-comyns${IS_PRODUCTION ? "-silversmith" : ""}`,
  },
  {
    id: 6,
    url: `${process.env.REACT_APP_IMAGES_URL}storefront_images/artisans/thomas_chippendale.png`,
    name: "Thomas Chippendale - Cabinet-maker (Chippendale)",
    path: `/knowledge/artisan/thomas-chippendale${IS_PRODUCTION ? "-chippendale-cabinet-maker" : ""}`,
  },
  {
    id: 7,
    url: `${process.env.REACT_APP_IMAGES_URL}storefront_images/artisans/rolex.png`,
    name: "Rolex - Designer",
    path: `/knowledge/artisan/rolex${IS_PRODUCTION ? "-designer" : ""}`,
  },
  {
    id: 8,
    url: `${process.env.REACT_APP_IMAGES_URL}storefront_images/artisans/nathaniel_mills.png`,
    name: "Nathaniel Mills - Maker",
    path: `/knowledge/artisan/nathaniel-mills${IS_PRODUCTION ? "-maker" : ""}`,
  },
  {
    id: 9,
    url: `${process.env.REACT_APP_IMAGES_URL}storefront_images/artisans/omega.png`,
    name: "Omega - Designer (Omega-Pseudo)",
    path: `/knowledge/artisan/omega${IS_PRODUCTION ? "-designer" : ""}`,
  },
];

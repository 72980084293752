import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./dealersActionsTypes";
import dealers from "../../api/dealers";
import items from "../../api/items";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import {
  getMostPopularDealersRequestSuccess,
  getMostPopularDealersRequestError
} from "./dealersActions";


function* getListOfAllDealers(action) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dealers.getListOfAllDealers, action.payload);
    yield put({ type: types.GET_LIST_OF_DEALERS_REQUEST_SUCCESS, payload: response });
  }
  catch (error) {
    yield put({ type: types.GET_LIST_OF_DEALERS_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* getDealerNews(action) {
  yield put({ type: types.SHOW_DEALERS_NEWS_LOADING });

  try {
    const response = yield call(dealers.getDealerNews, action.payload);
    yield put({ type: types.GET_DEALER_NEWS_REQUEST_SUCCESS, payload: response });
  }
  catch (error) {
    yield put({ type: types.GET_DEALER_NEWS_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_DEALERS_NEWS_LOADING });
  }
}

function* getSingleDealer(action) {
  try {
    const dealerResponse = yield call(dealers.getSingleDealer, action.payload.dealer);

    // handling 204 response status code
    if (!dealerResponse) {
      yield put({ type: types.SET_DEALER_NOT_FOUND, payload: true });
    }

    yield put({ type: types.GET_SINGLE_DEALER_SUCCESS, payload: dealerResponse });

    const itemsResponse = yield call(items.get, { ...action.payload.params, dealer: dealerResponse.id });
    yield put({ type: types.GET_SINGLE_DEALERS_ITEMS_SUCCESS, payload: itemsResponse });
  }
  catch (error) {
    if (error.detail && (error.detail === "Not found." || error.detail === "Invalid page.")) {
      yield put({ type: types.SET_DEALER_NOT_FOUND, payload: true });
    }
    else {
      yield put({ type: types.GET_SINGLE_DEALER_ERROR, payload: error.message });
    }
  }
}

function* getSingleDealerItems(action) {
  try {
    const response = yield call(items.get, action.payload);

    yield put({ type: types.GET_SINGLE_DEALERS_ITEMS_SUCCESS, payload: response });

    if (typeof action.callback === "function") {
      yield call(action.callback);
    }
  } catch (error) {
    yield put(getMostPopularDealersRequestError(error));
  }
}

function* getSingleDealerTestimonials(action) {
  try {
    const response = yield call(dealers.getDealerTestimonials, action.payload);

    yield put({ type: types.GET_SINGLE_DEALER_TESTIMONIALS_SUCCESS, payload: response });

    if (typeof action.callback === "function") {
      yield call(action.callback);
    }
  } catch (error) {
    yield put({ type: types.GET_SINGLE_DEALER_TESTIMONIALS_ERROR, payload: error });
  }
}

function* getMostPopularDealers(action) {
  yield put({ type: types.SHOW_MOST_POPULAR_DEALERS_LOADING });
  try {
    const response = yield call(dealers.getMostPopularDealers, action.payload);
    yield put(getMostPopularDealersRequestSuccess(response));
  } catch (error) {
    yield put(getMostPopularDealersRequestError(error));
  } finally {
    yield put({ type: types.HIDE_MOST_POPULAR_DEALERS_LOADING });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_LIST_OF_DEALERS_REQUEST, serverSagaWrapper(getListOfAllDealers)),
    yield takeLatest(types.GET_DEALER_NEWS_REQUEST, serverSagaWrapper(getDealerNews)),
    yield takeLatest(types.GET_SINGLE_DEALER_REQUEST, serverSagaWrapper(getSingleDealer)),
    yield takeLatest(types.GET_SINGLE_DEALERS_ITEMS_REQUEST, serverSagaWrapper(getSingleDealerItems)),
    yield takeLatest(types.GET_SINGLE_DEALER_TESTIMONIALS_REQUEST, serverSagaWrapper(getSingleDealerTestimonials)),
    yield takeLatest(types.GET_MOST_POPULAR_DEALERS_REQUEST, serverSagaWrapper(getMostPopularDealers)),
  ]);
}

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const UserAvatar = ({ authorizedUserProfile }) => {
  const { role, textLogoUrl, shouldUseTextLogo, logo, image } =
    authorizedUserProfile || {};

  const userRole = role || "WEBSITE_VISITOR";
  const isDealer = userRole === "DEALER";

  const avatarUrl = isDealer
    ? shouldUseTextLogo
      ? textLogoUrl
      : logo
    : image?.url;

  return (
    <Link to="/profile/home">
      <Avatar
        className="header--avatar"
        shape="circle"
        size={40}
        icon={
          avatarUrl ? (
            <img src={avatarUrl} alt="Profile photo" />
          ) : (
            <UserOutlined />
          )
        }
      />
    </Link>
  );
};

UserAvatar.propTypes = {
  authorizedUserProfile: PropTypes.object.isRequired,
};

export default connect((state) => ({
  authorizedUserProfile: state.auth.authorizedUserProfile,
}))(UserAvatar);

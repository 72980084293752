export const GET_LIST_OF_PERIODS_REQUEST = "GET_LIST_OF_PERIODS_REQUEST";
export const GET_LIST_OF_PERIODS_REQUEST_ERROR = "GET_LIST_OF_PERIODS_REQUEST_ERROR";
export const GET_LIST_OF_PERIODS_REQUEST_SUCCESS = "GET_LIST_OF_PERIODS_REQUEST_SUCCESS";

export const SEARCH_PERIODS_REQUEST = "SEARCH_PERIODS_REQUEST";
export const SEARCH_PERIODS_REQUEST_ERROR = "SEARCH_PERIODS_REQUEST_ERROR";
export const SEARCH_PERIODS_REQUEST_SUCCESS = "SEARCH_PERIODS_REQUEST_SUCCESS";

export const SHOW_SEARCH_LOADING = "SHOW_PERIODS_SEARCH_LOADING";
export const HIDE_SEARCH_LOADING = "HIDE_PERIODS_SEARCH_LOADING";

export const GET_PERIOD_DATA_REQUEST = "GET_PERIOD_DATA_REQUEST";
export const GET_PERIOD_DATA_REQUEST_ERROR = "GET_PERIOD_DATA_REQUEST_ERROR";
export const GET_PERIOD_DATA_REQUEST_SUCCESS = "GET_PERIOD_DATA_REQUEST_SUCCESS";

export const SHOW_PERIOD_DATA_LOADING = "SHOW_PERIOD_DATA_LOADING";
export const HIDE_PERIOD_DATA_LOADING = "HIDE_PERIOD_DATA_LOADING";

export const GET_PERIOD_ITEMS_REQUEST = "GET_PERIOD_ITEMS_REQUEST";
export const GET_PERIOD_ITEMS_REQUEST_ERROR = "GET_PERIOD_ITEMS_REQUEST_ERROR";
export const GET_PERIOD_ITEMS_REQUEST_SUCCESS = "GET_PERIOD_ITEMS_REQUEST_SUCCESS";

export const SHOW_PERIOD_ITEMS_LOADING = "SHOW_PERIOD_ITEMS_LOADING";
export const HIDE_PERIOD_ITEMS_LOADING = "HIDE_PERIOD_ITEMS_LOADING";

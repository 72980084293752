import React from "react";
import { Redirect, useParams } from "react-router-dom";


/*TODO(LANT-5450): Add redirect to dealers list and single dealer pages*/
export const RedirectToDealerSinglePage = () => {
  const { url } = useParams();

  return <Redirect to={`/directory/dealers/${url}`} />;
};

import * as React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const TikTokIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M3.8 0H34.2C36.2988 0 38 1.70118 38 3.8V34.2C38 36.2988 36.2988 38 34.2 38H3.8C1.70118 38 0 36.2988 0 34.2V3.8C0 1.70118 1.70118 0 3.8 0Z"
            fill="#010101"
          />
          <path
            d="M23.8603 34.96C25.0166 34.96 25.954 34.0302 25.954 32.8834C25.954 31.7365 25.0166 30.8066 23.8603 30.8066H23.5449C24.7012 30.8066 25.6386 31.7365 25.6386 32.8834C25.6386 34.0302 24.7012 34.96 23.5449 34.96H23.8603Z"
            fill="#EE1D51"
          />
          <path
            d="M23.5166 30.8066H23.2012C22.045 30.8066 21.1074 31.7365 21.1074 32.8834C21.1074 34.0302 22.045 34.96 23.2012 34.96H23.5166C22.3602 34.96 21.4228 34.0302 21.4228 32.8834C21.4228 31.7365 22.3602 30.8066 23.5166 30.8066V30.8066Z"
            fill="#66C8CF"
          />
          <ellipse cx="23.5319" cy="32.8834" rx="1.01825" ry="1.00974" fill="#010101" />
          <path
            d="M7.59961 30.9206V29.868H11.4139L11.0698 30.949H10.0661V34.9316H8.83263V30.9206H7.59961ZM17.6953 30.9206V29.868H21.5096L21.1655 30.949H20.1618V34.9316H18.9283V30.9206H17.6953ZM11.6445 30.4653C11.6445 30.1355 11.9137 29.868 12.2463 29.868C12.5792 29.868 12.8487 30.1355 12.8487 30.4653C12.8487 30.7952 12.5793 31.0627 12.2463 31.0627C11.9137 31.0625 11.6445 30.7952 11.6445 30.4653ZM12.8487 31.4895H11.6445V34.9315H12.8487V31.4895ZM13.3633 29.868V34.9316H14.5679V33.6231L14.9407 33.2814L16.1165 34.96H17.4069L15.7147 32.5135L17.2347 31.0341H15.7722L14.5679 32.2288V29.868H13.3633ZM26.3555 34.9316V29.868H27.5602V32.2288L28.7648 31.0341H30.2274L28.7074 32.5135L30.3996 34.96H29.109L27.933 33.2814L27.5602 33.6231V34.9316H26.3555Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
          />
          <path
            d="M26.026 8.06937C25.1759 7.14111 24.6178 5.94137 24.4969 4.61502V4.07056H23.3223C23.6179 5.75624 24.6264 7.19644 26.026 8.06937ZM30.3994 14.2281C28.1985 14.2281 26.1605 13.5288 24.4971 12.3404V20.979C24.4971 25.3009 20.9918 28.804 16.6679 28.804C14.4745 28.804 12.492 27.9026 11.0703 26.4498C12.316 27.2858 13.8154 27.7733 15.4286 27.7733C19.7522 27.7733 23.2574 24.2701 23.2574 19.9485V11.3096C24.9213 12.498 26.959 13.1974 29.1598 13.1974V9.8651C29.5674 9.95147 29.9828 9.99492 30.3994 9.99476V14.2281ZM13.0753 20.9667C13.0742 21.7497 13.3309 22.5112 13.8059 23.1337C12.6373 22.5442 11.8359 21.334 11.8359 19.9363C11.8359 17.9596 13.4391 16.357 15.4171 16.357C15.7858 16.3568 16.1524 16.4133 16.5039 16.5247V13.1561C16.9181 13.1473 17.3324 13.1711 17.743 13.2274V17.5553C17.3916 17.4439 17.0252 17.3873 16.6566 17.3876C14.6786 17.3876 13.0753 18.9901 13.0753 20.9667Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#EE1D52"
          />
          <path
            d="M23.2583 11.3096C24.9222 12.498 26.9599 13.1974 29.1607 13.1974V9.8651C27.9323 9.60366 26.8447 8.96192 26.0269 8.06937C24.6273 7.19644 23.6188 5.75624 23.3232 4.07056H20.2377V20.9789C20.2307 22.95 18.6302 24.546 16.6564 24.546C15.4933 24.546 14.46 23.992 13.8057 23.1336C12.6371 22.5442 11.8358 21.334 11.8358 19.9363C11.8358 17.9596 13.4389 16.357 15.4169 16.357C15.7958 16.357 16.1611 16.416 16.5037 16.5247V13.1561C12.256 13.2438 8.83984 16.7127 8.83984 20.979C8.83984 23.1087 9.69059 25.0394 11.0712 26.45C12.3168 27.286 13.8163 27.7734 15.4295 27.7734C19.7531 27.7734 23.2583 24.2701 23.2583 19.9486V11.3096Z"
            fill="white"
          />
          <path
            d="M23.3223 4.07054C23.2941 3.90946 23.2724 3.74729 23.2574 3.58444V3.03998H18.9972V19.9485C18.9903 21.9194 17.3899 23.5154 15.4161 23.5154C14.8365 23.5154 14.2894 23.378 13.8049 23.1336C14.4592 23.992 15.4925 24.546 16.6556 24.546C18.6292 24.546 20.2299 22.95 20.2369 20.9789V4.07054H23.3223ZM29.1592 9.86509V8.96404C28.0513 8.96571 26.9653 8.65562 26.0254 8.06921C26.8574 8.97966 27.953 9.60752 29.1592 9.86509ZM16.503 12.1968V13.156C12.2552 13.2437 8.83917 16.7127 8.83917 20.9789C8.83917 23.1085 9.68991 25.0392 11.0705 26.4498C8.97719 25.0453 7.59961 22.6577 7.59961 19.9485C7.59961 15.6272 11.1047 12.124 15.4288 12.124C15.7881 12.1238 16.147 12.1481 16.503 12.1968Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#69C9D0"
          />
          <path
            d="M25.6399 32.8834C25.6399 34.0302 24.7023 34.96 23.5461 34.96H23.5175C22.3612 34.96 21.4238 34.0302 21.4238 32.8834C21.4238 31.7365 22.3612 30.8066 23.5175 30.8066H23.5461C24.7023 30.8066 25.6399 31.7365 25.6399 32.8834ZM23.5321 31.8736C22.9697 31.8736 22.5137 32.3255 22.5137 32.8834C22.5137 33.4412 22.9697 33.8932 23.5321 33.8934C24.094 33.8934 24.55 33.4412 24.55 32.8834C24.55 32.3255 24.094 31.8736 23.5321 31.8736Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
          />
        </g>
      </svg>
    </span>
  );
}

TikTokIcon.propTypes = {
  className: PropTypes.string,
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BellOutlined } from "@ant-design/icons";

// redux
import { getAllNotificationsRequest } from "../../redux/notifications/notificationsActions";
import { openNotificationDrawer } from "../../redux/general/generalActions";

const NotificationButton = ({
  authorizedUserProfile,
  openNotifications,
  getAllNotifications,
  closeMobileDrawer,
  mobileMenuOpen,
}) => {
  const showNotifications = authorizedUserProfile?.role === "DEALER";

  useEffect(() => {
    if (showNotifications) {
      getAllNotifications();
    }
  }, [showNotifications]);

  if (!showNotifications) {
    return null;
  }

  const handleClick = () => {
    if (mobileMenuOpen) {
      closeMobileDrawer();
    }

    openNotifications();
  };

  return (
    <div
      className={classNames("header--bell-icon", {
        "header--bell-icon__badge": authorizedUserProfile?.unreadNotifications,
      })}
      onClick={handleClick}
    >
      <BellOutlined />
    </div>
  );
};

NotificationButton.propTypes = {
  authorizedUserProfile: PropTypes.object.isRequired,
  closeMobileDrawer: PropTypes.func.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    authorizedUserProfile: state.auth.authorizedUserProfile,
  }),
  (dispatch) => ({
    openNotifications: () => dispatch(openNotificationDrawer()),
    getAllNotifications: () => dispatch(getAllNotificationsRequest()),
  })
)(NotificationButton);

export const NOTIFICATIONS_GET_ALL_REQUEST = "NOTIFICATIONS_GET_ALL_REQUEST";
export const NOTIFICATIONS_GET_ALL_REQUEST_ERROR = "NOTIFICATIONS_GET_ALL_REQUEST_ERROR";
export const NOTIFICATIONS_GET_ALL_REQUEST_SUCCESS = "NOTIFICATIONS_GET_ALL_REQUEST_SUCCESS";

export const NOTIFICATION_MARK_AS_READ_REQUEST = "NOTIFICATION_MARK_AS_READ_REQUEST";
export const NOTIFICATION_MARK_AS_READ_REQUEST_ERROR = "NOTIFICATION_MARK_AS_READ_REQUEST_ERROR";
export const NOTIFICATION_MARK_AS_READ_REQUEST_SUCCESS = "NOTIFICATION_MARK_AS_READ_REQUEST_SUCCESS";

export const NOTIFICATION_MARK_AS_UNREAD_REQUEST = "NOTIFICATION_MARK_AS_UNREAD_REQUEST";
export const NOTIFICATION_MARK_AS_UNREAD_REQUEST_ERROR = "NOTIFICATION_MARK_AS_UNREAD_REQUEST_ERROR";
export const NOTIFICATION_MARK_AS_UNREAD_REQUEST_SUCCESS = "NOTIFICATION_MARK_AS_UNREAD_REQUEST_SUCCESS";

export const NOTIFICATION_DELETE_REQUEST = "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_REQUEST_ERROR = "NOTIFICATION_DELETE_REQUEST_ERROR";
export const NOTIFICATION_DELETE_REQUEST_SUCCESS = "NOTIFICATION_DELETE_REQUEST_SUCCESS";

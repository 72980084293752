import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const ScotlandFlagIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g>
          <g>
            <path d="M31 28C31 29.1046 30.1046 30 29 30H3C1.89543 30 1 29.1046 1 28V4C1 2.89543 1.89543 2 3 2H29C30.1046 2 31 2.89543 31 4V28Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.9375 8.5L11.5 16L0.9375 23.5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <path d="M28.9997 29.2344L15.9997 20.0002L3 29.2344" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M28.9997 2.76565L15.9999 12L3 2.76565" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M31.0625 8.5L20.5 16L31.0625 23.5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    </span>
  );
};

ScotlandFlagIcon.propTypes = {
  className: PropTypes.string,
};

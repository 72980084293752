// helpers
import { objectToQueryString } from "../helpers";
import fetch from "./customFetch";


const blog = {
  async getBlogArticles({ page = 1, pageSize = 10, ...rest } = {}) {
    const query = objectToQueryString({ page, page_size: pageSize, ...rest });

    try {
      return await fetch({
        url: `/storefront/blog/articles/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getBlogArticleByURL({ slicedUrl, pathname }) {
    const query = objectToQueryString({ slicedUrl });

    try {
      return await fetch({
        url: `/storefront/blog/articles/${pathname}/?${query}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getBlogAuthors({ page = 1, pageSize = 10 }) {
    const query = objectToQueryString({ page, page_size: pageSize })

    try {
      return await fetch({
        url: `/storefront/blog/authors/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getBlogCategories({ page = 1, pageSize = 10, name }) {
    const query = objectToQueryString({ name, page, page_size: pageSize });

    try {
      return await fetch({
        url: `/storefront/blog/categories/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getArchivedBlogCategories({ page = 1, pageSize = 10, name }) {
    const query = objectToQueryString({ name, page, page_size: pageSize });

    try {
      return await fetch({
        url: `/storefront/blog/categories/archive/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getHomeBlogArticles() {
    try {
      return await fetch({
        url: "/blog/articles/?show_on_homepage=true",
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default blog;

import * as React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const LinkedinIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="14.6446136%" y1="14.644643%" x2="85.3551856%" y2="85.355357%" id="linearGradient-1">
            <stop stopColor="#2489BE" offset="0%" />
            <stop stopColor="#0575B3" offset="100%" />
          </linearGradient>
        </defs>
        <g id="Dealer-Profile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="07_DealerProfile_Website_Info_Social" transform="translate(-466.000000, -593.000000)" fill="url(#linearGradient-1)" fillRule="nonzero">
            <g id="Content" transform="translate(442.000000, 285.000000)">
              <g id="linkedin-icon" transform="translate(24.000000, 308.000000)">
                <path
                  d="M18.9999618,0 C8.50650365,0 0,8.50652074 0,19 C0,29.4934793 8.50650365,38 18.9999618,38 C29.49342,38 38,29.4934793 38,19 C38,8.50652074 29.4932674,0 18.9999618,0 Z M13.9307362,28.1856291 L9.7591008,28.1856291 L9.7591008,14.7082398 L13.9307362,14.7082398 L13.9307362,28.1856291 Z M11.825039,12.9432754 C10.4626278,12.9432754 9.35815371,11.8297942 9.35815371,10.4564675 C9.35815371,9.08298825 10.4627805,7.96958329 11.825039,7.96958329 C13.1872975,7.96958329 14.2917717,9.08298825 14.2917717,10.4564675 C14.291848,11.8298705 13.1873739,12.9432754 11.825039,12.9432754 L11.825039,12.9432754 Z M29.5640093,28.1856291 L25.4125205,28.1856291 L25.4125205,21.1111196 C25.4125205,19.1707882 24.6754922,18.0876795 23.1412247,18.0876795 C21.4714259,18.0876795 20.5990951,19.2157365 20.5990951,21.1111196 L20.5990951,28.1856291 L16.5980187,28.1856291 L16.5980187,14.7082398 L20.5990951,14.7082398 L20.5990951,16.523418 C20.5990951,16.523418 21.8026231,14.2971423 24.6603822,14.2971423 C27.5182177,14.2971423 29.5640093,16.0421127 29.5640093,19.651941 L29.5640093,28.1856291 L29.5640093,28.1856291 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
}

LinkedinIcon.propTypes = {
  className: PropTypes.string,
};

import { DEFAULT_LOGO_SETTINGS, WEBSITE_DEFAULT_VALUES } from "../constants";

export const generateDefaultTemplate = ({ template, businessName = "" }) => {
  const [firstName, lastName] = businessName.split(" ");

  return {
    ...WEBSITE_DEFAULT_VALUES,
    template,
    customFavicon: {
      ...WEBSITE_DEFAULT_VALUES.customFavicon,
      text: `${firstName?.length ? firstName[0] : ""}${lastName?.length ? lastName[0] : ""}`
    },
    isActionButton: "no",
    banner: template !== 2 ? `<p class="ql-align-center"><span style="color: rgb(250, 204, 204);" class="ql-size-large ql-font-Dancing-Script">Welcome to ${businessName}</span></p>` : "",
    homeTitle: `Home | ${businessName}`,
    textLogo: JSON.stringify({
      ...DEFAULT_LOGO_SETTINGS,
      text: businessName
    })
  };
}

export const websitePagesDefaultValues = (dealer) => {
  const businessName = dealer?.businessName || "";
  const websiteName = businessName ? ` (${businessName}) ` : "";

  return {
    defaultCookiesPolicy: `<h1>${businessName} Cookie</h1><p><br></p><p>This website${websiteName}uses cookie and tracking technology depending on the features offered. Cookies are useful for gathering data such as what browser a website visitor is using, what device they are using and what operating system for example IOS or android they are using, this means we can provide a better user experience for you the website visitor.</p><p><br></p><p><span>Rest assured, your personal information cannot be collected via cookies. But if you have used a login or newsletter signup previously, cookies will relate to such information. Aggregate cookie and tracking information may be shared with third parties.</span></p><p><br></p><p><span style="background-color: transparent;">Read more about cookies here: </span><a href="https://www.allaboutcookies.org/" rel="noopener noreferrer" target="_blank" style="color: rgb(17, 85, 204);">https://www.allaboutcookies.org/</a></p>`,
    defaultTermsAndConditions: `<h1>Terms &amp; Conditions of ${businessName}</h1><p><br></p><p>Welcome to the website of ${businessName}. By browsing the website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern the relationship with you in relation to this website and business conducted on and offline with ${businessName}.</p><p><br></p><p>"us" or "we" refers to the owner of the website ${businessName}.</p><p><br></p><p>By using this website you are subject to the following terms of use:</p><p>&nbsp;</p><ul><li>Neither we ${businessName} nor any third parties provide any warranty or guarantee as to the accuracy of any of our items for sale or our content. timeliness, performance, materials used, completeness or suitability of the information found or offered on this website for any particular purpose. By using this website or purchasing any item from ${businessName} you acknowledge that such information and materials may contain inaccuracies or errors and ${businessName} expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li><li>The content of the website pages is for your general information and use only. It is subject to change without and due notice.</li><li>Your use of any information also known as content and materials on this website is entirely at your own risk, for which we shall not be liable.</li><li>Unauthorised use of this website may give to a claim for damages and/or be a criminal offence.</li><li>From time to time this website may also include links to other websites. These links are provided to provide further information. The links do not signify the ${businessName}&nbsp;endorse the website(s).</li></ul>`,
    defaultPrivacyPolicy: `<h1>Privacy Policy of ${businessName}</h1><p><br/></p><p>We are committed to protecting your privacy on our website. Our staff within the company on a need to know basis only use any information collected from individual customers and website visitors. We constantly review our systems to ensure the safest data experience for our customers. Parliament has created specific offences for unauthorised actions against computer systems and data. We will investigate any such actions with a view to prosecuting and/or taking civil proceedings to recover damages against those responsible.</p><p><br/></p><h3><strong>Confidentiality when using our website</strong></h3><p><br/></p><p>We ${businessName} are registered under the Data Protection Act 1998 and as such, any information concerning website visitors, our Clients and their respective records may be passed to third parties. However, Client and website visitors records are regarded as confidential by ${businessName} and therefore will not be divulged to any third party, other than if legally required to do so to the appropriate authorities. Website visitors and clients have the right to request sight of, and copies of any and all client and website visitors records we keep, on the proviso that we are given reasonable notice of such a request. Clients are requested to retain copies of any literature issued in relation to the provision of our services. Where appropriate, we shall issue Client’s and website visitors with appropriate written information, handouts or copies of records as part of an agreed contract, for the benefit of both parties.</p><p><br/></p><p>We ${businessName} will not sell, share, or rent your personal information to any third party or use your email address for unsolicited mail. Any emails sent by this Company will only be in connection with the provision of agreed services and products.</p>`
  }
};

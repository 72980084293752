import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./catalogsActionsTypes";
import catalogs from "../../api/catalogs";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import {
  getListOfMaterialsSuccess,
  getListOfMaterialsError,
  getMostPopularMaterialsSuccess,
  getMostPopularMaterialsError,
  getMostPopularOriginsSuccess,
  getMostPopularOriginsError,
  getListOfOriginsSuccess,
  getListOfOriginsError,
  searchMaterialsRequestError,
  searchMaterialsRequestSuccess,
  searchOriginsRequestError,
  searchOriginsRequestSuccess,
  getOriginItemsRequestError,
  getOriginItemsRequestSuccess,
  getMaterialItemsRequestError,
  getMaterialItemsRequestSuccess,
  getMaterialDataRequestSuccess,
  getMaterialDataRequestError,
  getOriginDataRequestSuccess,
  getOriginDataRequestError,
} from "./catalogsActions";
import items from "../../api/items";


function* getMostPopularMaterials(action) {
  try {
    const response = yield call(catalogs.getMostPopularMaterials, action.payload);
    yield put(getMostPopularMaterialsSuccess(response));
  }
  catch (error) {
    yield put(getMostPopularMaterialsError(error.message));
  }
}

function* getListOfAllMaterials(action) {
  yield put({ type: types.SHOW_LIST_OF_MATERIALS_REQUEST_LOADING });
  try {
    const response = yield call(catalogs.getAllMaterialsList, action.payload);
    yield put(getListOfMaterialsSuccess(response));
  } catch (error) {
    yield put(getListOfMaterialsError(error));
  } finally {
    yield put({ type: types.HIDE_LIST_OF_MATERIALS_REQUEST_LOADING });
  }
}

function* getMostPopularOrigins(action) {
  try {
    const response = yield call(catalogs.getMostPopularOrigin, action.payload);
    yield put(getMostPopularOriginsSuccess(response));
  }
  catch (error) {
    yield put(getMostPopularOriginsError(error.message));
  }
}

function* getListOfAllOrigins(action) {
  try {
    const response = yield call(catalogs.getAllOriginList, action.payload);
    yield put(getListOfOriginsSuccess(response));
  }
  catch (error) {
    yield put(getListOfOriginsError(error));
  }
}

function *searchMaterials({ payload }) {
  yield put({ type: types.SHOW_MATERIALS_SEARCH_LOADING });
  try {
    const response = yield call(catalogs.searchMaterials, payload);
    yield put(searchMaterialsRequestSuccess(response));
  } catch (error) {
    yield put(searchMaterialsRequestError(error.message));
  } finally {
    yield put({ type: types.HIDE_MATERIALS_SEARCH_LOADING });
  }
}

function *searchOrigins({ payload }) {
  yield put({ type: types.SHOW_ORIGINS_SEARCH_LOADING });
  try {
    const response = yield call(catalogs.searchOrigins, payload);
    yield put(searchOriginsRequestSuccess(response));
  } catch (error) {
    yield put(searchOriginsRequestError(error.message));
  } finally {
    yield put({ type: types.HIDE_ORIGINS_SEARCH_LOADING });
  }
}

function *getOriginItemsRequest({ payload }) {
  yield put({ type: types.SHOW_ORIGIN_ITEMS_LOADING });
  try {
    const response = yield call(items.get, payload);
    yield put(getOriginItemsRequestSuccess(response));
  } catch (error) {
    yield put(getOriginItemsRequestError(error.message));
  } finally {
    yield put({ type: types.HIDE_ORIGIN_ITEMS_LOADING });
  }
}

function *getMaterialItemsRequest({ payload }) {
  yield put({ type: types.SHOW_MATERIAL_ITEMS_LOADING });
  try {
    const response = yield call(items.get, payload);
    yield put(getMaterialItemsRequestSuccess(response));
  } catch (error) {
    yield put(getMaterialItemsRequestError(error.message));
  } finally {
    yield put({ type: types.HIDE_MATERIAL_ITEMS_LOADING });
  }
}

function *getMaterialDataRequest({ payload }) {
  yield put({ type: types.SHOW_MATERIAL_DATA_LOADING });
  try {
    const response = yield call(catalogs.getMaterialData, payload);
    yield put(getMaterialDataRequestSuccess(response));
  } catch (error) {
    yield put(getMaterialDataRequestError(error.data.detail));
  } finally {
    yield put({ type: types.HIDE_MATERIAL_DATA_LOADING });
  }
}

function *getOriginDataRequest({ payload }) {
  yield put({ type: types.SHOW_ORIGIN_DATA_LOADING });
  try {
    const response = yield call(catalogs.getOriginData, payload);
    yield put(getOriginDataRequestSuccess(response));
  } catch (error) {
    yield put(getOriginDataRequestError(error.data.detail));
  } finally {
    yield put({ type: types.HIDE_ORIGIN_DATA_LOADING });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_MOST_POPULAR_MATERIALS_REQUEST, serverSagaWrapper(getMostPopularMaterials)),
    yield takeLatest(types.GET_LIST_OF_MATERIALS_REQUEST, serverSagaWrapper(getListOfAllMaterials)),
    yield takeLatest(types.GET_MOST_POPULAR_ORIGINS_REQUEST, serverSagaWrapper(getMostPopularOrigins)),
    yield takeLatest(types.GET_LIST_OF_ORIGINS_REQUEST, serverSagaWrapper(getListOfAllOrigins)),
    yield takeLatest(types.SEARCH_MATERIALS_REQUEST, serverSagaWrapper(searchMaterials)),
    yield takeLatest(types.SEARCH_ORIGINS_REQUEST, serverSagaWrapper(searchOrigins)),
    yield takeLatest(types.GET_ORIGIN_ITEMS_REQUEST, serverSagaWrapper(getOriginItemsRequest)),
    yield takeLatest(types.GET_MATERIAL_ITEMS_REQUEST, serverSagaWrapper(getMaterialItemsRequest)),
    yield takeLatest(types.GET_MATERIAL_DATA_REQUEST, serverSagaWrapper(getMaterialDataRequest)),
    yield takeLatest(types.GET_ORIGIN_DATA_REQUEST, serverSagaWrapper(getOriginDataRequest)),
  ]);
}

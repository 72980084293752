import fetch from "./customFetch";


const enquiries = {
  async sendEmailToDealerAboutItem(data) {
    try {
      return await fetch({
        url: "/enquiries/email-item-dealer/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async sendEmailToDealerAboutDealer(data) {
    try {
      return await fetch({
        url: "/enquiries/email-dealer/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchEmailEnquiries({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("query", title);
    }

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/email/search/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchPhoneEnquiries({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("query", title);
    }

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/phone/search/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchCallbackEnquiries({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("query", title);
    }

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/callback/search/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchDeliveryEnquiries({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("query", title);
    }

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/delivery-quote/search/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default enquiries;

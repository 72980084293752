import omit from "lodash/omit";

export const transformComponentForReactHelmetMarkupSchema = (component, id) => {
  return {
    ...component,
    props: {
      ...omit(component.props, ["dangerouslySetInnerHTML"]),
      children: component.props.dangerouslySetInnerHTML.__html,
      id
    }
  };
}

export const transformDealerPhoneNumberToRender = (dealer) => {
  const { visiblePhone, callButton, phone = "", mobile = "" } = dealer?.address || {};

  switch (visiblePhone) {
    case "PHONE":
      return phone;
    case "MOBILE":
      return mobile;
    case "BOTH":
      return callButton === "MOBILE" ? mobile : phone;
    default:
      return "";
  }
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import loadable from "@loadable/component";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { UserbackProvider } from "@userback/react";

// components
import Header from "./shared/Header/Header";
import Login from "./Auth/Login/Login";
import Footer from "./shared/Footer";
import AppRoutes from "./router/AppRoutes";
import TagManager from "./shared/TagManager";
import AdvertiseWBModal from "./AdvertiseWBModal/AdvertiseWBModal";
import AdvertisePublicAddressModal from "./AdvertisePublicAddressModal/AdvertisePublicAddressModal";

// hooks
import { useCreateQueryClient } from "./provider/useCreateQueryClient";

const NotificationDrawer = loadable(() =>
  import("./Notifications/NotificationDrawer")
);

// redux
import { resetSeoRedirect } from "../redux/storefront/storefrontActions";

// helpers
import { ORGANISATION_SCHEMA_SCRIPT } from "../helpers";

function App({ redirectDataUrl, resetSeoRedirectAction, isAuthenticated }) {
  const location = useLocation();

  const queryClient = useCreateQueryClient();

  const userData = useSelector((state) => {
    const {
      id = "unknown",
      firstName,
      lastName,
      email = "unknown",
      role = "GUEST",
    } = state.auth.authorizedUserProfile || {};

    const name = [firstName, lastName].join(" ").trim() || "unknown";

    return {
      id,
      info: { name, email, role },
    };
  });

  useEffect(() => {
    const homeSchemaScript = document.getElementById("home-schema-script");
    const jewelleryScript = document.getElementById("jewellery-script");
    const itemScript = document.getElementById("item-script");
    const serverOrganisationScript = document.getElementById(
      "server-organisation-schema-script"
    );
    const clientOrganisationScript = document.getElementById(
      "client-organisation-schema-script"
    );
    const faqSchemaScript = document.getElementById("faqSchema");
    const markupSchema = document.getElementById("markupSchema");

    const organisationScriptToAppend = document.createElement("script");
    organisationScriptToAppend.id = "client-organisation-schema-script";
    organisationScriptToAppend.type = "application/ld+json";
    organisationScriptToAppend.text = ORGANISATION_SCHEMA_SCRIPT;

    if (location.pathname === "/") {
      if (serverOrganisationScript) {
        document.body.removeChild(serverOrganisationScript);
      }
      if (clientOrganisationScript) {
        document.body.removeChild(clientOrganisationScript);
      }
    }

    if (location.pathname !== "/") {
      if (homeSchemaScript) {
        document.body.removeChild(homeSchemaScript);
      }
      if (!serverOrganisationScript && !clientOrganisationScript) {
        document.body.appendChild(organisationScriptToAppend);
      }
    }

    if (location.pathname !== "/vintage-jewellery" && jewelleryScript) {
      document.body.removeChild(jewelleryScript);
    }
    if (!location.pathname.includes("/items/listings") && itemScript) {
      document.body.removeChild(itemScript);
    }
    if (faqSchemaScript && document.body.contains(faqSchemaScript)) {
      document.body.removeChild(faqSchemaScript);
    }
    if (markupSchema && document.body.contains(markupSchema)) {
      document.body.removeChild(markupSchema);
    }
  });

  const lastSymbolOfCurrentLocation = location.pathname.slice(-1);
  const currentLocationWithoutLastSlash = location.pathname.substring(
    0,
    location.pathname.length - 1
  );

  // Trigger redirect and reset it
  if (redirectDataUrl && location.pathname !== `/${redirectDataUrl}`) {
    resetSeoRedirectAction();

    return <Redirect to={`/${redirectDataUrl}`} />;
  }

  // add 301 redirect if location.pathname has trailing slash
  if (
    lastSymbolOfCurrentLocation === "/" &&
    currentLocationWithoutLastSlash.length
  ) {
    return <Redirect to={currentLocationWithoutLastSlash} />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserbackProvider
        token={process.env.REACT_APP_USERBACK_TOKEN}
        options={{ user_data: userData, autohide: false }}
      >
        <Header />
        <Login />
        <TagManager />

        <div className="wrapper">
          <div className="wrapper--content">
            <AppRoutes />
          </div>

          <Footer />

          {isAuthenticated && <NotificationDrawer />}
        </div>

        <ReactQueryDevtools />

        <AdvertiseWBModal />
        <AdvertisePublicAddressModal />
      </UserbackProvider>
    </QueryClientProvider>
  );
}

App.propTypes = {
  redirectDataUrl: PropTypes.string.isRequired,
  resetSeoRedirectAction: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    redirectDataUrl: state.storefront.redirectDataUrl,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = {
  resetSeoRedirectAction: resetSeoRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

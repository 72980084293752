import { all, put, call, takeLatest } from "redux-saga/effects";
import categoriesApi from "../../api/categories";
import itemsApi from "../../api/items";
import catalogsApi from "../../api/catalogs";
import * as types from "./categoryPageActionsTypes";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";

import {
  searchCategoriesRequestError,
  searchCategoriesRequestSuccess,
  getCategoryChildrenRequestError,
  getCategoryChildrenRequestSuccess
} from "./categoryPageActions";


function *requestItems ({ payload }) {
  yield put({ type: types.TOGGLE_CATEGORY_ITEMS_LOADING, payload: true });
  try {
    const response = yield call(itemsApi.get, payload);
    yield put({ type: types.REQUEST_CATEGORY_ITEMS_SUCCESS, payload: { ...response, page: payload.page } });
  } catch (error) {
    yield put({ type: types.CATEGORY_REQUEST_ERROR, error: error });
  } finally {
    yield put({ type: types.TOGGLE_CATEGORY_ITEMS_LOADING, payload: false });
  }
}

function* requestMaterials({ payload }) {
  yield put({ type: types.TOGGLE_LOADING, payload: true });
  try {
    const response = yield call(catalogsApi.getMaterials, payload);
    yield put({ type: types.REQUEST_MATERIALS_SUCCESS, payload: response.results });
  } catch (error) {
    yield put({ type: types.REQUEST_MATERIALS_ERROR, error: error });
  } finally {
    yield put({ type: types.TOGGLE_LOADING, payload: false });
  }
}

function* requestMostPopularMaterials({ payload }) {
  yield put({ type: types.TOGGLE_LOADING, payload: true });

  try {
    const response = yield call(catalogsApi.getMostPopularMaterials, payload);
    yield put({
      type: types.REQUEST_MOST_POPULAR_MATERIALS_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({ type: types.REQUEST_MOST_POPULAR_MATERIALS_ERROR, error: error });
  } finally {
    yield put({ type: types.TOGGLE_LOADING, payload: false });
  }
}

function* requestBreadcrumb({ payload }) {
  yield put({ type: types.TOGGLE_BREADCRUMB_LOADING, payload: true });

  try {
    const response = yield call(categoriesApi.getMultipleByUrls, payload);
    yield put({ type: types.REQUEST_BREADCRUMB_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CATEGORY_REQUEST_ERROR, error: error });
  } finally {
    yield put({ type: types.TOGGLE_BREADCRUMB_LOADING, payload: false });
  }
}

function* requestLastItems({ payload }) {
  yield put({ type: types.TOGGLE_LOADING, payload: true });

  try {
    const response = yield call(itemsApi.get, { pageSize: payload, ordering: "-date" });
    yield put({ type: types.REQUEST_CATEGORY_LAST_ITEMS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CATEGORY_REQUEST_ERROR, error: error });
  } finally {
    yield put({ type: types.TOGGLE_LOADING, payload: false });
  }
}

function *searchCategories({ payload }) {
  yield put({ type: types.SHOW_SEARCH_LOADING });
  try {
    const response = yield call(categoriesApi.searchCategories, payload);
    yield put(searchCategoriesRequestSuccess(response));
  } catch (error) {
    yield put(searchCategoriesRequestError(error));
  } finally {
    yield put({ type: types.HIDE_SEARCH_LOADING });
  }
}

function *getCategoryChildren({ payload }) {
  yield put({ type: types.TOGGLE_GET_CATEGORY_CHILDREN_LOADING, payload: true });
  try {
    const response = yield call(categoriesApi.getCategoryChildren, payload);
    yield put(getCategoryChildrenRequestSuccess(response));
  } catch (error) {
    yield put(getCategoryChildrenRequestError(error));
  } finally {
    yield put({ type: types.TOGGLE_GET_CATEGORY_CHILDREN_LOADING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.REQUEST_CATEGORY_ITEMS, serverSagaWrapper(requestItems)),
    yield takeLatest(types.REQUEST_BREADCRUMB, serverSagaWrapper(requestBreadcrumb)),
    yield takeLatest(types.REQUEST_CATEGORY_LAST_ITEMS, serverSagaWrapper(requestLastItems)),
    yield takeLatest(types.REQUEST_MATERIALS, serverSagaWrapper(requestMaterials)),
    yield takeLatest(types.REQUEST_MOST_POPULAR_MATERIALS, serverSagaWrapper(requestMostPopularMaterials)),
    yield takeLatest(types.SEARCH_CATEGORIES_REQUEST, serverSagaWrapper(searchCategories)),
    yield takeLatest(types.GET_CATEGORIES_CHILDREN_REQUEST, serverSagaWrapper(getCategoryChildren)),
  ])
}

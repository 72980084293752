import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./directoryActionsTypes";
import business from "../../api/business";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";


function* getDirectoryListSaga(action) {
  try {
    const response = yield call(business.getDirectoryList, action.payload);
    yield put({ type: types.GET_DIRECTORY_LIST_SUCCESS, payload: response });
  }
  catch (error) {
    yield put({ type: types.GET_DIRECTORY_LIST_FAILED, payload: error.message });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_DIRECTORY_LIST_REQUEST, serverSagaWrapper(getDirectoryListSaga)),
  ]);
}

export const GET_BLOG_ARTICLE_BY_URL_REQUEST = "GET_BLOG_ARTICLE_BY_URL_REQUEST";
export const GET_BLOG_ARTICLE_BY_URL_REQUEST_ERROR = "GET_BLOG_ARTICLE_BY_URL_REQUEST_ERROR";
export const GET_BLOG_ARTICLE_BY_URL_REQUEST_SUCCESS = "GET_BLOG_ARTICLE_BY_URL_REQUEST_SUCCESS";

export const GET_ARCHIVED_BLOG_ARTICLES_REQUEST = "GET_ARCHIVED_BLOG_ARTICLES_REQUEST";
export const GET_ARCHIVED_BLOG_ARTICLES_REQUEST_ERROR = "GET_ARCHIVED_BLOG_ARTICLES_REQUEST_ERROR";
export const GET_ARCHIVED_BLOG_ARTICLES_REQUEST_SUCCESS = "GET_ARCHIVED_BLOG_ARTICLES_REQUEST_SUCCESS";

export const GET_BLOG_ARTICLES_REQUEST = "GET_BLOG_ARTICLES_REQUEST";
export const GET_BLOG_ARTICLES_REQUEST_ERROR = "GET_BLOG_ARTICLES_REQUEST_ERROR";
export const GET_BLOG_ARTICLES_REQUEST_SUCCESS = "GET_BLOG_ARTICLES_REQUEST_SUCCESS";

export const GET_BLOG_AUTHORS_REQUEST = "GET_BLOG_AUTHORS_REQUEST";
export const GET_BLOG_AUTHORS_REQUEST_ERROR = "GET_BLOG_AUTHORS_REQUEST_ERROR";
export const GET_BLOG_AUTHORS_REQUEST_SUCCESS = "GET_BLOG_AUTHORS_REQUEST_SUCCESS";

export const GET_BLOG_CATEGORIES_REQUEST = "GET_BLOG_CATEGORIES_REQUEST";
export const GET_BLOG_CATEGORIES_REQUEST_ERROR = "GET_BLOG_CATEGORIES_REQUEST_ERROR";
export const GET_BLOG_CATEGORIES_REQUEST_SUCCESS = "GET_BLOG_CATEGORIES_REQUEST_SUCCESS";

export const TOGGLE_BLOG_PROCESSING = "TOGGLE_BLOG_PROCESSING";
export const SHOW_BLOG_LOADING = "SHOW_BLOG_LOADING";
export const HIDE_BLOG_LOADING = "HIDE_BLOG_LOADING";

export const SHOW_BLOG_ARCHIVES_LOADING = "SHOW_BLOG_ARCHIVES_LOADING";
export const HIDE_BLOG_ARCHIVES_LOADING = "HIDE_BLOG_ARCHIVES_LOADING";

export const SHOW_BLOG_ARTICLES_LOADING = "SHOW_BLOG_ARTICLES_LOADING";
export const HIDE_BLOG_ARTICLES_LOADING = "HIDE_BLOG_ARTICLES_LOADING";

export const SHOW_BLOG_AUTHORS_LOADING = "SHOW_BLOG_AUTHORS_LOADING";
export const HIDE_BLOG_AUTHORS_LOADING = "HIDE_BLOG_AUTHORS_LOADING";

export const SHOW_BLOG_CATEGORIES_LOADING = "SHOW_BLOG_CATEGORIES_LOADING";
export const HIDE_BLOG_CATEGORIES_LOADING = "HIDE_BLOG_CATEGORIES_LOADING";

import { all, put, call, takeLatest } from "redux-saga/effects";

import categoriesApi from "../../api/categories";
import itemsApi from "../../api/items";
import blogApi from "../../api/blog";
import * as types from "./homeActionsTypes";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import {
  getCategoriesWithoutPaginationError,
  getCategoriesWithoutPaginationSuccess,
  getAllHomeBlogsSuccess,
  getAllHomeBlogsError,
} from "./homeActions";


function *requestMostPopular () {
  yield put({ type: types.TOGGLE_LOADING, payload: true });

  try {
    const response = yield call(categoriesApi.getMostPopular, 12);
    yield put({ type: types.REQUEST_MOST_POPULAR_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.HOME_REQUEST_ERROR, error: error });
  } finally {
    yield put({ type: types.TOGGLE_LOADING, payload: false });
  }
}

function *requestItems ({ payload }) {
  yield put({ type: types.TOGGLE_HOME_ITEMS_LOADING, payload: true });

  try {
    const response = yield call(itemsApi.get, payload);
    yield put({ type: types.REQUEST_HOME_ITEMS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.HOME_REQUEST_ERROR, error: error });
  } finally {
    yield put({ type: types.TOGGLE_HOME_ITEMS_LOADING, payload: false });
  }
}

function *getCategoriesWithoutPagination () {
  yield put({ type: types.TOGGLE_ALL_CATEGORIES_LOADING, payload: true });

  try {
    const response = yield call(categoriesApi.getAllWithoutPagination);
    yield put(getCategoriesWithoutPaginationSuccess(response));
  } catch (error) {
    yield put(getCategoriesWithoutPaginationError(error));
  } finally {
    yield put({ type: types.TOGGLE_ALL_CATEGORIES_LOADING, payload: false });
  }
}

function *getHomeBlogs () {
  try {
    const response = yield call(blogApi.getHomeBlogArticles);
    yield put(getAllHomeBlogsSuccess(response));
  } catch (error) {
    yield put(getAllHomeBlogsError(error));
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.REQUEST_MOST_POPULAR, serverSagaWrapper(requestMostPopular)),
    yield takeLatest(types.REQUEST_HOME_ITEMS, serverSagaWrapper(requestItems)),
    yield takeLatest(types.GET_ALL_HOME_CATEGORIES_REQUEST, serverSagaWrapper(getCategoriesWithoutPagination)),
    yield takeLatest(types.GET_ALL_HOME_BLOGS_REQUEST, serverSagaWrapper(getHomeBlogs)),
  ])
}

import * as types from "./hnlActionsTypes";

export const initialState = {
  error: null,
  itemsLoading: false,
  topItems: [],
  topItemsLoading: false,
  itemsOfSpecifiedCategory: [],
  itemsOfSpecifiedCategoryCount: 0,
  categoryData: {},
};

const hnl = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOP_ITEMS_SUCCESS:
      return {
        ...state,
        error: null,
        topItems: action.payload.results,
      };

    case types.GET_HNL_CATEGORY_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        categoryData: action.payload,
      };

    case types.GET_TOP_ITEMS_ERROR:
    case types.GET_ITEMS_BY_CATEGORY_ERROR:
    case types.GET_HNL_CATEGORY_DATA_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_ITEMS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        error: null,
        itemsOfSpecifiedCategory: action.payload.results,
        itemsOfSpecifiedCategoryCount: action.payload.count,
      };

    case types.SET_HNL_ITEMS_LOADING:
      return {
        ...state,
        itemsLoading: true
      }

    case types.RESET_HNL_ITEMS_LOADING:
      return {
        ...state,
        itemsLoading: false
      }

    default:
      return state;
  }
};

export default hnl;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Button, Input, Modal } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import SocialAuthButtons from "../SocialAuth/SocialAuthButtons";

// redux
import {
  loginRequest,
  closeLoginModalRequest,
  openLoginModalRequest,
} from "../../../redux/auth/authActions";

// constants
import {
  MESSAGE_EMPTY_FIELD,
  MESSAGE_VALID_EMAIL,
  REGEXP_EMAIL,
} from "../../../constants";

const validation = yup.object().shape({
  email: yup
    .string()
    .required(MESSAGE_EMPTY_FIELD)
    .matches(REGEXP_EMAIL, MESSAGE_VALID_EMAIL),
  password: yup.string().required(MESSAGE_EMPTY_FIELD),
});

export const Login = ({
  processing,
  error,
  login,
  showLoginModal = false,
  closeLoginModal,
  openLoginModal,
  isAuthenticated,
}) => {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(validation),
  });

  const { email, password } = watch();

  const [showModal, setShowModal] = useState(showLoginModal);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const redirect = searchParams.get("redirect");

  const handleRedirectToResetPassword = () => {
    history.push({ pathname: "/auth/forget-password" });
    closeLoginModal();
  };

  const onHandleRedirect = (path) => {
    switch (path) {
      case "subscription":
        history.push("/profile/subscriptions");
        break;
      default:
        history.push("/profile/home");
    }
  };

  const onSubmit = (data) => {
    login(data, () => {
      closeLoginModal();

      if (redirect) {
        onHandleRedirect(redirect);
      } else if (history.location.pathname === "/") {
        history.push("/profile/home");
      } else {
        history.go(0);
      }
    });
  };

  useEffect(() => {
    if (redirect) {
      isAuthenticated ? onHandleRedirect(redirect) : openLoginModal();
    }
  }, []);

  useEffect(() => {
    if (showLoginModal) {
      //lazy loading fir styles (LCP improvement)
      import("../../../assets/styles/auth.scss").then(() => setShowModal(showLoginModal)
      );
    } else {
      setShowModal(showLoginModal);
    }
  }, [showLoginModal]);

  useEffect(() => {
    if (!processing) {
      return;
    }
    setFormSubmitted(true);
  }, [processing]);

  useEffect(() => {
    if (!formSubmitted || processing || error) {
      return;
    }

    closeLoginModal && closeLoginModal();
  }, [formSubmitted, processing, error]);

  return (
    <Modal
      className="modal-block"
      centered
      open={showModal}
      onCancel={closeLoginModal}
      title={
        <div className="login-dialog">
          <div className="login-dialog--logo">
            <img
              src={`${process.env.REACT_APP_IMAGES_URL}loginLALogo.svg`}
              title="LoveAntiques"
              alt="LoveAntiques"
            />
          </div>
          <div className="login-dialog--title">Login</div>
          <form onSubmit={handleSubmit(onSubmit)} className="auth-form">
            <div className="login-dialog--email-input-wrapper">
              <Input
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setValue("email", e.target.value)}
                className="ant-input--white-bg login-dialog--email-input"
              />
            </div>
            {errors.email && (
              <div className="login-dialog--error">
                {errors.email.message || MESSAGE_VALID_EMAIL}
              </div>
            )}

            <div className="login-dialog--password-input-wrapper">
              <Input.Password
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setValue("password", e.target.value)}
                className="ant-input--white-bg login-dialog--password-input"
              />
            </div>
            {errors.password && (
              <div className="login-dialog--error">
                {errors.password.message}
              </div>
            )}

            <div
              onClick={handleRedirectToResetPassword}
              className="login-dialog--forgotten-pass-wrapper"
            >
              Forgotten password?
            </div>
            <p className="login-dialog--error">
              {!!error &&
                Object.values(error).filter((item) => typeof item !== "object" ? item : null
                )}
            </p>
            <div className="login-dialog--submit-button-wrapper">
              <Button
                size="large"
                htmlType="submit"
                disabled={processing}
                className="login-dialog--submit-button"
                data-testid="button"
              >
                Login
              </Button>
            </div>

            <SocialAuthButtons />

            <div className="login-dialog--register">
              Don’t have an account?{" "}
              <Link to="/registration" onClick={closeLoginModal}>
                Register
              </Link>
            </div>
          </form>
        </div>
      }
      footer={null}
    ></Modal>
  );
};

Login.propTypes = {
  processing: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  error: PropTypes.object,
  showLoginModal: PropTypes.bool.isRequired,
  closeLoginModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    processing: state.auth.processing,
    error: state.auth.loginError,
    showLoginModal: state.auth.showLoginModal,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    login: loginRequest,
    openLoginModal: openLoginModalRequest,
    closeLoginModal: closeLoginModalRequest,
  }
)(Login);

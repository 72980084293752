export const CATEGORY_REQUEST_ERROR = "CATEGORY_REQUEST_ERROR";
export const CATEGORY_RESET_ERROR = "CATEGORY_RESET_ERROR";

export const TOGGLE_LOADING = "TOGGLE_CATEGORY_LOADING";

export const TOGGLE_BREADCRUMB_LOADING = "TOGGLE_BREADCRUMB_LOADING";

export const REQUEST_CATEGORY_SUCCESS = "REQUEST_CATEGORY_SUCCESS";
export const REQUEST_TOP_CATEGORY_SUCCESS = "REQUEST_TOP_CATEGORY_SUCCESS";

export const TOGGLE_CATEGORY_ITEMS_LOADING = "TOGGLE_CATEGORY_ITEMS_LOADING";
export const REQUEST_CATEGORY_ITEMS = "REQUEST_CATEGORY_ITEMS";
export const REQUEST_CATEGORY_ITEMS_SUCCESS = "REQUEST_CATEGORY_ITEMS_SUCCESS";

export const REQUEST_MATERIALS = "REQUEST_MATERIALS";
export const REQUEST_MATERIALS_ERROR = "REQUEST_MATERIALS_ERROR";
export const REQUEST_MATERIALS_SUCCESS = "REQUEST_MATERIALS_SUCCESS";

export const REQUEST_BREADCRUMB = "REQUEST_BREADCRUMB";
export const REQUEST_BREADCRUMB_SUCCESS = "REQUEST_BREADCRUMB_SUCCESS";

export const REQUEST_CATEGORY_LAST_ITEMS = "REQUEST_CATEGORY_LAST_ITEMS";
export const REQUEST_CATEGORY_LAST_ITEMS_SUCCESS = "REQUEST_CATEGORY_LAST_ITEMS_SUCCESS";

export const REQUEST_MOST_POPULAR_MATERIALS = "REQUEST_MOST_POPULAR_MATERIALS";
export const REQUEST_MOST_POPULAR_MATERIALS_SUCCESS = "REQUEST_MOST_POPULAR_MATERIALS_SUCCESS";
export const REQUEST_MOST_POPULAR_MATERIALS_ERROR = "REQUEST_MOST_POPULAR_MATERIALS_ERROR";

export const SEARCH_CATEGORIES_REQUEST = "SEARCH_CATEGORIES_REQUEST";
export const SEARCH_CATEGORIES_REQUEST_ERROR = "SEARCH_CATEGORIES_REQUEST_ERROR";
export const SEARCH_CATEGORIES_REQUEST_SUCCESS = "SEARCH_CATEGORIES_REQUEST_SUCCESS";

export const SHOW_SEARCH_LOADING = "SHOW_CATEGORY_SEARCH_LOADING";
export const HIDE_SEARCH_LOADING = "HIDE_CATEGORY_SEARCH_LOADING";

export const GET_CATEGORIES_CHILDREN_REQUEST = "GET_CATEGORIES_CHILDREN_REQUEST";
export const GET_CATEGORIES_CHILDREN_REQUEST_ERROR = "GET_CATEGORIES_CHILDREN_REQUEST_ERROR";
export const GET_CATEGORIES_CHILDREN_REQUEST_SUCCESS = "GET_CATEGORIES_CHILDREN_REQUEST_SUCCESS";
export const TOGGLE_GET_CATEGORY_CHILDREN_LOADING = "TOGGLE_GET_CATEGORY_CHILDREN_LOADING";

export const REQUEST_TOP_CATEGORY_PERIODS_SUCCESS = "REQUEST_TOP_CATEGORY_PERIODS_SUCCESS";

export const REQUEST_TOP_CATEGORY_ORIGINS_SUCCESS = "REQUEST_TOP_CATEGORY_ORIGINS_SUCCESS";

export const REQUEST_TOP_CATEGORY_MATERIALS_SUCCESS = "REQUEST_TOP_CATEGORY_MATERIALS_SUCCESS";

export const CLEAR_CATEGORY_DATA = "CLEAR_CATEGORY_DATA";

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const MapMarkerIcon = ({ className, filled, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg
        width="1em"
        height="1em"
        viewBox={filled ? "0 0 384 512" : "0 0 16 16"}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        {filled ? (
          <path
            d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
          />
        ) : (
          <path
            d="M8 14.3648C9.77917 12.8193 11.099 11.4151 11.9594 10.1522C12.8198 8.88935 13.25 7.76831 13.25 6.78913C13.25 5.28504 12.7431 4.05361 11.7292 3.09485C10.7154 2.13609 9.47233 1.65644 8 1.65589C6.52708 1.65589 5.28371 2.13554 4.26988 3.09485C3.25604 4.05416 2.74942 5.28559 2.75 6.78913C2.75 7.76887 3.18021 8.89018 4.04062 10.1531C4.90104 11.416 6.22083 12.8199 8 14.3648ZM8 16C7.79583 16 7.59167 15.9655 7.3875 15.8965C7.18333 15.8275 7.00104 15.724 6.84063 15.586C5.89271 14.7581 5.05417 13.9508 4.325 13.1643C3.59583 12.3777 2.98712 11.6155 2.49887 10.8775C2.01004 10.139 1.63817 9.42834 1.38325 8.74556C1.12833 8.06279 1.00058 7.41064 1 6.78913C1 4.71927 1.70379 3.07029 3.11138 1.84217C4.51896 0.614058 6.1485 0 8 0C9.85208 0 11.4819 0.614058 12.8895 1.84217C14.2971 3.07029 15.0006 4.71927 15 6.78913C15 7.41009 14.8723 8.06223 14.6168 8.74556C14.3612 9.42889 13.9894 10.1395 13.5011 10.8775C13.0129 11.6155 12.4042 12.3777 11.675 13.1643C10.9458 13.9508 10.1073 14.7581 9.15938 15.586C8.99896 15.724 8.81667 15.8275 8.6125 15.8965C8.40833 15.9655 8.20417 16 8 16ZM8 8.27943C8.48125 8.27943 8.89337 8.11743 9.23637 7.79343C9.57938 7.46943 9.75058 7.07946 9.75 6.62354C9.75 6.16818 9.5785 5.77821 9.2355 5.45366C8.8925 5.12911 8.48067 4.96711 8 4.96766C7.51875 4.96766 7.10663 5.12994 6.76363 5.45449C6.42062 5.77904 6.24942 6.16873 6.25 6.62354C6.25 7.07891 6.4215 7.46887 6.7645 7.79343C7.1075 8.11798 7.51933 8.27998 8 8.27943Z"
          />
        )}
      </svg>
    </span>
  );
};

MapMarkerIcon.propTypes = {
  className: PropTypes.string,
  filled: PropTypes.bool,
};

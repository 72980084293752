import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const EbayIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="2.5em" height="1em" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 5.55385C0 2.88462 1.87087 2 4.11599 2C7.16017 2 8 3.89428 8 5.12308V5.90256H1.6444C1.6444 7.14458 2.66015 8.03077 4.07246 8.03077C5.80647 8.03077 6.163 6.9 6.163 6.9H7.82598C7.82598 6.9 7.60599 9 4.17386 9C1.25804 9 0 7.66844 0 5.55385ZM6.31926 4.90763C6.31926 3.76108 5.40278 2.99609 4.05842 2.99609C2.87799 2.99609 1.68164 3.69037 1.68164 4.90763H6.31926Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E53238"
        />
        <path
          d="M8.03458 8.58338V0H9.59425V3.86821C9.59425 3.86821 10.2553 2.71197 12.3828 2.71197C14.6548 2.71197 16 4.32696 16 6.33499C16 8.49334 14.5354 10 12.3794 10C10.3318 10 9.57884 8.8143 9.57884 8.8143C9.57884 9.2634 9.52406 9.75471 9.52406 9.75471H8C8 9.75471 8.03458 9.07058 8.03458 8.58338ZM14.3842 6.33503C14.3842 4.86738 13.4531 3.77368 11.9889 3.77368C10.3501 3.77368 9.59375 5.08054 9.59375 6.34899C9.59375 7.71027 10.4367 8.93837 11.9995 8.93837C13.3089 8.93837 14.3842 7.90884 14.3842 6.33503Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#0064D2"
        />
        <path
          d="M15.4773 3.98134C15.4773 3.70829 15.6754 2 18.7652 2C20.2563 2 21.9471 2.44536 21.9471 4.56205V7.79013C21.9471 8.16361 22 8.74736 22 8.74736H20.6329C20.6329 8.74736 20.5964 8.27639 20.5964 7.79847C20.5964 7.79847 19.9028 9 17.7493 9C16.1692 9 15 8.23167 15 6.81957C15 5.38461 16.2654 4.67748 18.6591 4.67748H20.4621V4.35619C20.4621 3.45359 19.6749 2.984 18.6591 2.984C17.0958 2.984 17.0152 3.98134 17.0152 3.98134H15.4773ZM20.4614 6.06975V5.64136C18.1264 5.64136 16.5371 5.64891 16.5371 6.81945C16.5371 7.46293 17.009 8.01765 18.1098 8.01765C20.1199 8.01765 20.4614 6.6285 20.4614 6.06975Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5AF02"
        />
        <path
          d="M22 3H23.6761L26.0816 8.05529L28.4816 3H30L25.6285 12H24.0357L25.2972 9.49111L22 3Z"
          fill="#86B817"
        />
      </svg>
    </span>
  );
}

EbayIcon.propTypes = {
  className: PropTypes.string,
};

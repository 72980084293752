import * as types from "./storefrontActionsTypes";

export const getDealersRequest = (payload) => ({
  type: types.GET_DEALERS_REQUEST,
  payload,
});

export const getDealersSuccess = (payload) => ({
  type: types.GET_DEALERS_REQUEST_SUCCESS,
  payload,
});

export const getDealersError = (payload) => ({
  type: types.GET_DEALERS_REQUEST_ERROR,
  payload,
});

export const getDealersLocationsRequest = (payload) => ({
  type: types.GET_DEALERS_LOCATIONS_REQUEST,
  payload,
});

export const getDealersLocationsSuccess = (payload) => ({
  type: types.GET_DEALERS_LOCATIONS_SUCCESS,
  payload,
});

export const getDealersLocationsError = (payload) => ({
  type: types.GET_DEALERS_LOCATIONS_ERROR,
  payload,
});

export const globalSearchRequest = (payload) => ({
  type: types.GLOBAL_SEARCH_REQUEST,
  payload,
});

export const globalSearchSuccess = (payload) => ({
  type: types.GLOBAL_SEARCH_REQUEST_SUCCESS,
  payload,
});

export const globalSearchError = (payload) => ({
  type: types.GLOBAL_SEARCH_REQUEST_ERROR,
  payload,
});

export const searchEmailPreferencesRequest = (payload) => ({
  type: types.SEARCH_EMAIL_PREFERENCES_REQUEST,
  payload,
});

export const searchEmailPreferencesSuccess = (payload) => ({
  type: types.SEARCH_EMAIL_PREFERENCES_REQUEST_SUCCESS,
  payload,
});

export const searchEmailPreferencesError = (payload) => ({
  type: types.SEARCH_EMAIL_PREFERENCES_REQUEST_ERROR,
  payload,
});

export const checkSeoRedirectSuccess = (payload) => ({
  type: types.CHECK_SEO_REDIRECT_REQUEST_SUCCESS,
  payload,
});

export const checkSeoRedirectError = (payload) => ({
  type: types.CHECK_SEO_REDIRECT_REQUEST_ERROR,
  payload,
});

export const resetSeoRedirect = () => ({
  type: types.RESET_SEO_REDIRECT,
});

export const selectSpecialityRequest = (payload) => ({
  type: types.SET_SELECTED_SPECIALITY,
  payload,
});

export const getAllSearchResultsRequest = (payload) => ({
  type: types.GET_ALL_SEARCH_RESULTS_REQUEST,
  payload,
});

export const getAllSearchResultsRequestSuccess = (payload) => ({
  type: types.GET_ALL_SEARCH_RESULTS_REQUEST_SUCCESS,
  payload,
});

export const getAllSearchResultsError = (payload) => ({
  type: types.GET_ALL_SEARCH_RESULTS_REQUEST_ERROR,
  payload,
});

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const ClockIcon = ({ className, filled, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      {filled ? (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99331 0.333496C3.31331 0.333496 0.333313 3.32016 0.333313 7.00016C0.333313 10.6802 3.31331 13.6668 6.99331 13.6668C10.68 13.6668 13.6666 10.6802 13.6666 7.00016C13.6666 3.32016 10.68 0.333496 6.99331 0.333496Z"
            fill="currentColor"
          />
          <path
            d="M7.33331 3.66699H6.33331V7.66699L9.83331 9.76699L10.3333 8.94699L7.33331 7.16699V3.66699Z"
            fill="white"
          />
        </svg>

      ) : (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 8C1 8.91925 1.18106 9.82951 1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15C8.91925 15 9.82951 14.8189 10.6788 14.4672C11.5281 14.1154 12.2997 13.5998 12.9497 12.9497C13.5998 12.2997 14.1154 11.5281 14.4672 10.6788C14.8189 9.82951 15 8.91925 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 4.66699V8.00033L10 10.0003"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </span>
  );
};

ClockIcon.propTypes = {
  className: PropTypes.string,
  filled: PropTypes.bool,
};

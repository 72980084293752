import * as types from "./catalogsActionsTypes";

export const getMostPopularMaterialsSuccess = (payload) => ({
  type: types.GET_MOST_POPULAR_MATERIALS_REQUEST_SUCCESS,
  payload,
});

export const getMostPopularMaterialsError = (payload) => ({
  type: types.GET_MOST_POPULAR_MATERIALS_REQUEST_ERROR,
  payload,
});

export const getListOfMaterialsRequest = (payload) => ({
  type: types.GET_LIST_OF_MATERIALS_REQUEST,
  payload,
});

export const getListOfMaterialsSuccess = (payload) => ({
  type: types.GET_LIST_OF_MATERIALS_REQUEST_SUCCESS,
  payload,
});

export const getListOfMaterialsError = (payload) => ({
  type: types.GET_LIST_OF_MATERIALS_REQUEST_ERROR,
  payload,
});

export const getMostPopularOriginsRequest = (payload) => ({
  type: types.GET_MOST_POPULAR_ORIGINS_REQUEST,
  payload,
});

export const getMostPopularOriginsSuccess = (payload) => ({
  type: types.GET_MOST_POPULAR_ORIGINS_REQUEST_SUCCESS,
  payload,
});

export const getMostPopularOriginsError = (payload) => ({
  type: types.GET_MOST_POPULAR_ORIGINS_REQUEST_ERROR,
  payload,
});

export const getListOfOriginsRequest = (payload) => ({
  type: types.GET_LIST_OF_ORIGINS_REQUEST,
  payload,
});

export const getListOfOriginsSuccess = (payload) => ({
  type: types.GET_LIST_OF_ORIGINS_REQUEST_SUCCESS,
  payload,
});

export const getListOfOriginsError = (payload) => ({
  type: types.GET_LIST_OF_ORIGINS_REQUEST_ERROR,
  payload,
});

export const searchMaterialsRequest = (payload) => ({
  type: types.SEARCH_MATERIALS_REQUEST,
  payload,
});

export const searchMaterialsRequestError = (payload) => ({
  type: types.SEARCH_MATERIALS_REQUEST_ERROR,
  payload,
});

export const searchMaterialsRequestSuccess = (payload) => ({
  type: types.SEARCH_MATERIALS_REQUEST_SUCCESS,
  payload,
});

export const searchOriginsRequest = (payload) => ({
  type: types.SEARCH_ORIGINS_REQUEST,
  payload,
});

export const searchOriginsRequestError = (payload) => ({
  type: types.SEARCH_ORIGINS_REQUEST_ERROR,
  payload,
});

export const searchOriginsRequestSuccess = (payload) => ({
  type: types.SEARCH_ORIGINS_REQUEST_SUCCESS,
  payload,
});

export const getOriginItemsRequest = (payload) => ({
  type: types.GET_ORIGIN_ITEMS_REQUEST,
  payload,
});

export const getOriginItemsRequestError = (payload) => ({
  type: types.GET_ORIGIN_ITEMS_REQUEST_ERROR,
  payload,
});

export const getOriginItemsRequestSuccess = (payload) => ({
  type: types.GET_ORIGIN_ITEMS_REQUEST_SUCCESS,
  payload,
});

export const getMaterialItemsRequest = (payload) => ({
  type: types.GET_MATERIAL_ITEMS_REQUEST,
  payload,
});

export const getMaterialItemsRequestError = (payload) => ({
  type: types.GET_MATERIAL_ITEMS_REQUEST_ERROR,
  payload,
});

export const getMaterialItemsRequestSuccess = (payload) => ({
  type: types.GET_MATERIAL_ITEMS_REQUEST_SUCCESS,
  payload,
});

export const getMaterialDataRequest = (payload) => ({
  type: types.GET_MATERIAL_DATA_REQUEST,
  payload,
});

export const getMaterialDataRequestError = (payload) => ({
  type: types.GET_MATERIAL_DATA_REQUEST_ERROR,
  payload,
});

export const getMaterialDataRequestSuccess = (payload) => ({
  type: types.GET_MATERIAL_DATA_REQUEST_SUCCESS,
  payload,
});

export const getOriginDataRequest = (payload) => ({
  type: types.GET_ORIGIN_DATA_REQUEST,
  payload,
});

export const getOriginDataRequestError = (payload) => ({
  type: types.GET_ORIGIN_DATA_REQUEST_ERROR,
  payload,
});

export const getOriginDataRequestSuccess = (payload) => ({
  type: types.GET_ORIGIN_DATA_REQUEST_SUCCESS,
  payload,
});

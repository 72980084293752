import * as types from "./dealersActionsTypes";

const initialState = {
  dealers: [],
  dealersTotalNumber: 0,
  error: null,
  loading: false,
  processing: false,
  dealerNews: [],
  dealerNewsTotalNumber: 0,
  loadingNews: false,
  singleDealer: {},
  testimonials: null,
  singleDealerItems: {
    items: [],
    itemsCount: 0,
    isLoading: false,
  },
  mostPopularDealers: [],
  mostPopularDealersLoading: false,
  dealerNotFound: false,
};

const dealers = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DEALER_NOT_FOUND:
      return {
        ...state,
        dealerNotFound: action.payload,
      }

    case types.GET_LIST_OF_DEALERS_REQUEST_SUCCESS:
      return {
        ...state,
        dealers: action.payload.results,
        dealersTotalNumber: action.payload.count
      };

    case types.GET_DEALER_NEWS_REQUEST_SUCCESS:
      return {
        ...state,
        dealerNews: action.payload.results,
        dealerNewsTotalNumber: action.payload.count,
      };

    case types.GET_LIST_OF_DEALERS_REQUEST_ERROR:
    case types.GET_DEALER_NEWS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        specialitiesLoading: false,
      };

    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };

    case types.SHOW_DEALERS_NEWS_LOADING:
      return {
        ...state,
        loadingNews: true,
      };

    case types.HIDE_DEALERS_NEWS_LOADING:
      return {
        ...state,
        loadingNews: false,
      };

    case types.GET_SINGLE_DEALER_SUCCESS:
      return {
        ...state,
        singleDealer: action.payload,
      };

    case types.GET_SINGLE_DEALERS_ITEMS_REQUEST:
      return {
        ...state,
        singleDealerItems: {
          items: [],
          itemsCount: 0,
          isLoading: true,
        },
      };

    case types.GET_SINGLE_DEALER_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        testimonials: action.payload
      };

    case types.GET_SINGLE_DEALERS_ITEMS_SUCCESS:
      return {
        ...state,
        singleDealerItems: {
          items: action.payload.results,
          itemsCount: action.payload.count,
          isLoading: false,
        }
      };

    case types.GET_MOST_POPULAR_DEALERS_REQUEST_SUCCESS:
      return {
        ...state,
        mostPopularDealers: action.payload,
        error: null,
      };

    case types.GET_MOST_POPULAR_DEALERS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.SHOW_MOST_POPULAR_DEALERS_LOADING:
      return {
        ...state,
        mostPopularDealersLoading: true,
      };

    case types.HIDE_MOST_POPULAR_DEALERS_LOADING:
      return {
        ...state,
        mostPopularDealersLoading: false,
      };

    case types.CLEAR_DEALER_DATA:
      return {
        ...state,
        singleDealer: {},
        dealerNotFound: false,
      };

    default:
      return state;
  }
};

export default dealers;

import { convertBase64ToFile } from "./file";

export const cropImage = (imageUrl, cropperViewPort) => {
  const { width, height } = cropperViewPort;
  const img = document.createElement("img");
  img.crossOrigin = "Anonymous";
  img.src = `${imageUrl}?not-from-cache`


  return new Promise((resolve) => {
    img.onload = () => {
      const canvas = document.createElement("CANVAS");
      canvas.height = height;
      canvas.width = width;

      const context = canvas.getContext("2d");

      context.fillStyle = "#FFF";
      context.fillRect(0, 0, width, height);
      context.drawImage(img,
        (img.width - width) / 2, (img.height - height) / 2,
        width, height,
        0, 0,
        width, height
      );

      resolve(convertBase64ToFile(canvas.toDataURL("image/png"), `image-${new Date()}`));
    };
  })
}

export const createImage = (imageUrl) => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener("load", () => resolve(image));
  image.addEventListener("error", (error) => reject(error));
  image.crossOrigin = "Anonymous";
  image.src = `${imageUrl}?not-from-cache`;
})

export const getRadianAngle = (degreeValue) => {
  return (degreeValue * Math.PI) / 180;
}

export const rotateSize = (width, height, rotation) => {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

export const getCroppedImg = async (imageSrc, pixelCrop, viewportSize) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  canvas.width = viewportSize.width;
  canvas.height = viewportSize.height;
  const ctx = canvas.getContext("2d");

  ctx.fillStyle = "#FFFFFF";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    viewportSize.width,
    viewportSize.height
  );

  // As Base64 string
  return convertBase64ToFile(canvas.toDataURL("image/png"), `image-${new Date()}`);
}

export const createSEOTitle = (title, h1, isCustomSuffix, h1AsTitle, defaultTitle = "Artisans") => {
  if (h1AsTitle) {
    if (!h1) {
      return defaultTitle;
    }

    return isCustomSuffix ? `${h1} Antiques` : h1;
  }

  if (!title) {
    return defaultTitle;
  }

  return isCustomSuffix ? `${title} Antiques` : title;
};

export const formatCategoryItems = (count, addBrackets = false) => {
  if (typeof count === "number" && Number.isInteger(count)) {
    const countWithCommas = count.toLocaleString("en-US");
    const text = `${countWithCommas} item${count === 1 ? "" : "s"}`;

    return addBrackets ? `(${text})` : text;
  }

  return "";
};

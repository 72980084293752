import * as types from "./directoryActionsTypes";

const initialState = {
  items: [],
  totalCount: 0,
  error: null,
  loading: false,
};

const directory = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DIRECTORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.GET_DIRECTORY_LIST_SUCCESS:
      return {
        ...state,
        directories: action.payload.results,
        totalCount: action.payload.count,
        error: null,
        loading: false,
      };

    case types.GET_DIRECTORY_LIST_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default directory;

import * as types from "./artisansActionsTypes";

const initialState = {
  artisans: {},
  artisansTags: {},
  artisansItems: [],
  artisansItemsCount: 0,
  artisansItemsLoading: false,
  artisanData: {},
  artisanDataLoading: false,
  loading: false,
  artisanDataError: null,
  error: null,
};

const artisans = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ARTISANS_SUCCESS:
      return {
        ...state,
        artisans: action.payload,
        loading: false,
      };

    case types.GET_ARTISANS_TAGS_SUCCESS:
      return {
        ...state,
        artisansTags: action.payload,
        loading: false,
      };

    case types.GET_ARTISANS_TAGS_REQUEST:
    case types.GET_ARTISANS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_ARTISANS_ERROR:
    case types.GET_ARTISANS_TAGS_ERROR:
    case types.GET_ARTISANS_ITEMS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case types.GET_ARTISANS_ITEMS_SUCCESS:
      return {
        ...state,
        artisansItems: action.payload.results,
        artisansItemsCount: action.payload.count,
        error: null,
      };

    case types.SHOW_ARTISANS_ITEMS_LOADING:
      return {
        ...state,
        artisansItemsLoading: true,
      };

    case types.HIDE_ARTISANS_ITEMS_LOADING:
      return {
        ...state,
        artisansItemsLoading: false,
      };

    case types.GET_ARTISAN_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        artisanData: action.payload,
        artisanDataError: null
      };

    case types.GET_ARTISAN_DATA_REQUEST_ERROR:
      return {
        ...state,
        artisanDataError: action.payload,
      };

    case types.SHOW_ARTISAN_DATA_LOADING:
      return {
        ...state,
        artisanDataLoading: true,
      };

    case types.HIDE_ARTISAN_DATA_LOADING:
      return {
        ...state,
        artisanDataLoading: false,
      };

    default:
      return state;
  }
};

export default artisans;

import * as types from "./dealersActionsTypes";

export const getListOfDealersRequest = () => {
  return { type: types.GET_LIST_OF_DEALERS_REQUEST };
};

export const getDealerNewsRequest = (payload) => {
  return { type: types.GET_DEALER_NEWS_REQUEST, payload };
};

export const getSingleDealerRequest = (payload) => ({
  type: types.GET_SINGLE_DEALER_REQUEST,
  payload,
});

export const getSingleDealerTestimonialsRequest = (payload) => ({
  type: types.GET_SINGLE_DEALER_TESTIMONIALS_REQUEST,
  payload,
});

export const getSingleDealerItemsRequest = (payload, callback) => ({
  type: types.GET_SINGLE_DEALERS_ITEMS_REQUEST,
  payload,
  callback
});

export const getMostPopularDealersRequest = (payload) => ({
  type: types.GET_MOST_POPULAR_DEALERS_REQUEST,
  payload,
});

export const getMostPopularDealersRequestError = (payload) => ({
  type: types.GET_MOST_POPULAR_DEALERS_REQUEST_ERROR,
  payload,
});

export const getMostPopularDealersRequestSuccess = (payload) => ({
  type: types.GET_MOST_POPULAR_DEALERS_REQUEST_SUCCESS,
  payload,
});

export const clearDealerData = () => ({
  type: types.CLEAR_DEALER_DATA,
});

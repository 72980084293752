import { useState } from "react";
import { QueryClient } from "react-query";

export const useCreateQueryClient = () => {
  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          notifyOnChangeProps: "tracked",
          refetchOnWindowFocus: false,
          retry: false
        }
      }
    });
  });

  return queryClient;
};

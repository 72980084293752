import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const BUTTONS_MAP = [
  { title: "Join as Collector", url: "/registration/collector" },
  { title: "Join as Dealer", url: "/registration/dealers" },
  { title: "Join Our Business Directory", url: "/registration/trader" },
];

const RegistrationButtonsGroup = ({ closeMobileDrawer }) => {
  return (
    <div className="header--mobile-menu-footer">
      {BUTTONS_MAP.map(({ title, url }, index) => (
        <Link key={index} to={url} onClick={closeMobileDrawer}>
          <Button className="header--mobile-menu-footer-btn">{title}</Button>
        </Link>
      ))}
    </div>
  );
};

RegistrationButtonsGroup.propTypes = {
  closeMobileDrawer: PropTypes.func.isRequired,
};

export default RegistrationButtonsGroup;

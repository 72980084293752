import axios from "axios";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

import { API_URL } from "./constants";


let authTokenRequest = null;

const client = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" }
});

function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = requestNewToken();
    authTokenRequest.finally(resetAuthTokenRequest);
  }

  return authTokenRequest;
}

function requestNewToken() {
  const cookies = new Cookies();
  const refreshToken = cookies.get("refreshToken");

  return axios.post(`${API_URL}/auth/refresh/`, { refresh: refreshToken }).then(res => {
    const decodedAccessToken = jwtDecode(res.data.access);
    const accessTokenExpTime = decodedAccessToken && new Date(decodedAccessToken.exp * 1000);
    cookies.set("accessToken", res.data.access, { expires: accessTokenExpTime, path: "/" });
    cookies.set("refreshToken", res.data.refresh, { path: "/" });
  }).catch(error => {
    if (error.response.status === 401) {
      cookies.remove("accessToken", { path: "/", domain: process.env.REACT_APP_DOMAIN_NAME }); // set from CRM
      cookies.remove("accessToken", { path: "/" }); // set from storefront
      cookies.remove("refreshToken", { path: "/" });
      location.reload()
    }
  });
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

client.interceptors.response.use(r => r, error => {
  if (!error.response) {
    return Promise.reject(error);
  }

  const originalRequest = error.config;

  // Don't try to renew while login in
  if (error.response.status === 401 && originalRequest.url) {
    if (originalRequest.url === "/auth/") {
      return Promise.reject(error.response ? error.response : error);
    }
  }

  if (error.response.status === 401 && !originalRequest.__isRetryRequest) {
    originalRequest.__isRetryRequest = true;

    return getAuthToken().then(() => {
      const cookies = new Cookies();

      const accessToken = cookies.get("accessToken");
      originalRequest.headers.Authorization = `JWT ${accessToken}`;

      return client(originalRequest);
    }).catch(() => Promise.reject(Error("Failed to obtain renew token")));
  }

  return Promise.reject(error.response ? error.response : error);
});


const request = function(options) {
  const cookies = new Cookies();
  const accessToken = cookies.get("accessToken");

  const onSuccess = function(response) {
    return response.data;
  };
  const onError = function(error) {
    return Promise.reject(error);
  };

  if (accessToken) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers.Authorization = `JWT ${accessToken}`;
  }

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default request;

// SWR fetcher
export const swrFetcher = (url, config) => {
  return client.get(`${API_URL}${url}`, config).then(res => res.data)
}

export const GET_DEALERS_REQUEST = "GET_DEALERS_REQUEST";
export const GET_DEALERS_REQUEST_ERROR = "GET_DEALERS_REQUEST_ERROR";
export const GET_DEALERS_REQUEST_SUCCESS = "GET_DEALERS_REQUEST_SUCCESS";

export const GET_DEALERS_LOCATIONS_REQUEST = "GET_DEALERS_LOCATIONS_REQUEST";
export const GET_DEALERS_LOCATIONS_ERROR = "GET_DEALERS_LOCATIONS_ERROR";
export const GET_DEALERS_LOCATIONS_SUCCESS = "GET_DEALERS_LOCATIONS_SUCCESS";

export const GLOBAL_SEARCH_REQUEST = "GLOBAL_SEARCH_REQUEST";
export const GLOBAL_SEARCH_REQUEST_ERROR = "GLOBAL_SEARCH_REQUEST_ERROR";
export const GLOBAL_SEARCH_REQUEST_SUCCESS = "GLOBAL_SEARCH_REQUEST_SUCCESS";

export const SEARCH_EMAIL_PREFERENCES_REQUEST = "SEARCH_EMAIL_PREFERENCES_REQUEST";
export const SEARCH_EMAIL_PREFERENCES_REQUEST_ERROR = "SEARCH_EMAIL_PREFERENCES_REQUEST_ERROR";
export const SEARCH_EMAIL_PREFERENCES_REQUEST_SUCCESS = "SEARCH_EMAIL_PREFERENCES_REQUEST_SUCCESS";

export const CHECK_SEO_REDIRECT_REQUEST = "CHECK_SEO_REDIRECT_REQUEST";
export const CHECK_SEO_REDIRECT_REQUEST_ERROR = "CHECK_SEO_REDIRECT_REQUEST_ERROR";
export const CHECK_SEO_REDIRECT_REQUEST_SUCCESS = "CHECK_SEO_REDIRECT_REQUEST_SUCCESS";
export const RESET_SEO_REDIRECT = "RESET_SEO_REDIRECT";

export const SET_SELECTED_SPECIALITY = "SET_SELECTED_SPECIALITY";

export const GET_ALL_SEARCH_RESULTS_REQUEST = "GET_ALL_SEARCH_RESULTS_REQUEST";
export const GET_ALL_SEARCH_RESULTS_REQUEST_SUCCESS = "GET_ALL_SEARCH_RESULTS_REQUEST_SUCCESS";
export const GET_ALL_SEARCH_RESULTS_REQUEST_ERROR = "GET_ALL_SEARCH_RESULTS_REQUEST_ERROR";

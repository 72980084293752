import fetch from "./customFetch";


const periods = {
  getAllPeriods: async () => {
    try {
      return await fetch({
        url: "/storefront/periods/all/",
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async get({ page = 1, pageSize = 10 }) {
    try {
      return await fetch({
        url: `/storefront/periods/?page=${page}&page_size=${pageSize}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchPeriods({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("title", title);
    }

    try {
      return await fetch({
        url: `/periods/autocomplete/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getPeriodData(url) {
    return fetch({
      url: `/storefront/periods/${url}/`,
      method: "GET"
    });
  },

  async getCategoryRelatedPeriods(queryParams) {
    try {
      return fetch({
        url: `/storefront/periods/related/?${queryParams}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default periods;

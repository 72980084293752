import * as types from "./blogActionsTypes";


export const getBlogArticlesRequest = (payload) => {
  return { type: types.GET_BLOG_ARTICLES_REQUEST, payload };
};

export const getBlogAuthorsRequest = (payload) => {
  return { type: types.GET_BLOG_AUTHORS_REQUEST, payload };
};

export const getBlogCategoriesRequest = (payload) => {
  return { type: types.GET_BLOG_CATEGORIES_REQUEST, payload };
};

export const getBlogArticleByURLRequest = (payload) => {
  return { type: types.GET_BLOG_ARTICLE_BY_URL_REQUEST, payload };
};

export const getArchivedBlogArticlesRequest = (payload) => {
  return { type: types.GET_ARCHIVED_BLOG_ARTICLES_REQUEST, payload };
};

export const getArchivedBlogArticlesRequestError = (payload) => {
  return { type: types.GET_ARCHIVED_BLOG_ARTICLES_REQUEST_ERROR, payload };
};

export const getArchivedBlogArticlesRequestSuccess = (payload) => {
  return { type: types.GET_ARCHIVED_BLOG_ARTICLES_REQUEST_SUCCESS, payload };
};

import * as yup from "yup";

import {
  MESSAGE_EMPTY_FIELD,
  MESSAGE_REQUIRED_FIELD,
  MESSAGE_VALID_EMAIL,
  MESSAGE_VALID_PASSWORD,
  REGEXP_EMAIL
} from "../constants";

export const validationEmail = yup.string().required(MESSAGE_EMPTY_FIELD).matches(REGEXP_EMAIL, MESSAGE_VALID_EMAIL);

export const validationPassword = yup.string()
  .required(MESSAGE_REQUIRED_FIELD)
  .test(
    "password",
    MESSAGE_VALID_PASSWORD,
    (val) => /[+=()!@#$%^&*-]/.test(val) && /[A-Z]/.test(val) && val.length >= 8
  );

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./businessActionsTypes";
import business from "../../api/business";

import serverSagaWrapper from "../../helpers/serverSagaWrapper";


function* getBusinessDirectoriesSaga(action) {
  try {
    const response = yield call(business.getDirectoryList, action.payload);
    yield put({ type: types.GET_BUSINESS_DIRECTORIES_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_BUSINESS_DIRECTORIES_FAILED, error });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_BUSINESS_DIRECTORIES_REQUEST, serverSagaWrapper(getBusinessDirectoriesSaga)),
  ]);
}

export const navigation = {
  getTableQueryParams: location => {
    const queryParams = {};
    for (const [key, value] of new URLSearchParams(location.search)) {
      queryParams[key] = value;
    }

    const {
      orderBy,
      orderDirection,
      search,
      page,
      perPage,
      filter,
      dateFilter,
      category,
      archivedCategory,
    } = queryParams;

    return {
      order: { key: orderBy, direction: orderDirection },
      search: search || "",
      page: +page || 1,
      perPage: +perPage || 16,
      filter: filter || "",
      dateFilter: dateFilter || "",
      category: category || "",
      archivedCategory: archivedCategory || "",
    }
  },

  setDynamicKeys: (obj, location, history) => {
    const queryParams = new URLSearchParams(location.search);

    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        queryParams.set(key, obj[key]);
      }
      else {
        queryParams.delete(key);
      }
    });

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.replace(newLocation)
  }
};

export const safetyJSONParsing = (value) => {
  try {
    return JSON.parse(value) || {};
  } catch (_err) {
    return {};
  }
};

export const parseFontColor = (website) => {
  try {
    const websiteFont = JSON.parse(website.websiteFont);

    return websiteFont.color ? `--text: ${websiteFont.color};` : "";
  } catch (e) {
    return "";
  }
};

export const parseCoordinates = (map) => {
  try {
    const coords = JSON.parse(map);

    return { ...coords, latitude: coords?.latitude || "", longitude: coords?.longitude || "" };
  } catch (e) {
    return { latitude: "", longitude: "" }
  }
};

import { all, call, put, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import * as types from "./itemsActionsTypes";
import items from "../../api/items";
import profiles from "../../api/profiles";
import enquiries from "../../api/enquiries";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import {
  requestCallbackError,
  requestCallbackSuccess,
  callADealerError,
  callADealerSuccess,
  editItemStatusSuccess,
  editItemStatusError,
} from "./itemsActions";

import storefront from "../../api/storefront";
import { checkSeoRedirectSuccess } from "../storefront/storefrontActions";

function *getAllItems ({ payload }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(items.get, payload);
    yield put({ type: types.GET_ALL_ITEMS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ALL_ITEMS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *getAllItemsForPrivateRoutes ({ payload }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(items.getItemForPrivateRoutes, payload);
    yield put({ type: types.GET_ALL_ITEMS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ALL_ITEMS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *getItemData (action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const seoResponse = yield call(storefront.checkSeoRedirect, action.payload.pathname);
    if (!_.isEmpty(seoResponse)) {
      return yield put(checkSeoRedirectSuccess(seoResponse));
    }

    const response = yield call(items.getItemData, action.payload.url);
    yield put({ type: types.GET_ITEM_DATA_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ITEM_DATA_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *getItemDataForPrivateRoutes (action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(items.getItemDataForPrivateRoutes, action.payload);
    yield put({ type: types.GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *sendEmailToDealerAboutItem (action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(enquiries.sendEmailToDealerAboutItem, action.payload);
    yield put({ type: types.SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *sendEmailToDealerAboutDealer (action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(enquiries.sendEmailToDealerAboutDealer, action.payload);
    yield put({ type: types.SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* requestCallback (action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(items.requestCallback, action.ref, action.data);
    yield put(requestCallbackSuccess(response))
  } catch (error) {
    yield put(requestCallbackError(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* callADealer (action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(items.callADealer, action.ref, action.data);
    yield put(callADealerSuccess())
  } catch (error) {
    yield put(callADealerError(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* editItemStatus (action) {
  const dataToSend = {
    status: {
      wbStatus: action.status
    }
  };

  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.editItem, { id: action.ref, data: dataToSend });
    yield put(editItemStatusSuccess());

    yield getAllItemsForPrivateRoutes({ payload: action.params });
  } catch (error) {
    yield put(editItemStatusError(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* searchAntiques({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(items.searchAntiques, payload.params, payload.query);

    yield put({ type: types.SEARCH_ANTIQUES_REQUEST_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.SEARCH_ANTIQUES_REQUEST_ERROR, payload: error })
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_ITEM_DATA_REQUEST, serverSagaWrapper(getItemData)),
    yield takeLatest(types.GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST, serverSagaWrapper(getItemDataForPrivateRoutes)),
    yield takeLatest(types.GET_ALL_ITEMS_REQUEST, serverSagaWrapper(getAllItems)),
    yield takeLatest(types.GET_ALL_ITEMS_FOR_PRIVATE_ROUTES_REQUEST, serverSagaWrapper(getAllItemsForPrivateRoutes)),
    yield takeLatest(types.SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST, serverSagaWrapper(sendEmailToDealerAboutItem)),
    yield takeLatest(types.SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST, serverSagaWrapper(sendEmailToDealerAboutDealer)),
    yield takeLatest(types.CALLBACK_REQUEST, serverSagaWrapper(requestCallback)),
    yield takeLatest(types.CALL_A_DEALER_REQUEST, serverSagaWrapper(callADealer)),
    yield takeLatest(types.EDIT_ITEM_STATUS_REQUEST, editItemStatus),
    yield takeLatest(types.SEARCH_ANTIQUES_REQUEST, searchAntiques),
  ])
}

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./blogActionsTypes";
import blog from "../../api/blog";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import {
  getArchivedBlogArticlesRequestError,
  getArchivedBlogArticlesRequestSuccess
} from "./blogActions";
import storefront from "../../api/storefront";
import isEmpty from "lodash/isEmpty";
import { checkSeoRedirectSuccess } from "../storefront/storefrontActions";


function* getBlogArticles(action) {
  yield put({ type: types.SHOW_BLOG_ARTICLES_LOADING });

  try {
    const response = yield call(blog.getBlogArticles, action.payload);
    yield put({ type: types.GET_BLOG_ARTICLES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLOG_ARTICLES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_BLOG_ARTICLES_LOADING });
  }
}

function* getBlogAuthors(action) {
  yield put({ type: types.SHOW_BLOG_AUTHORS_LOADING });

  try {
    const response = yield call(blog.getBlogAuthors, action.payload);
    yield put({ type: types.GET_BLOG_AUTHORS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLOG_AUTHORS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_BLOG_AUTHORS_LOADING });
  }
}

function* getBlogCategories(action) {
  yield put({ type: types.SHOW_BLOG_CATEGORIES_LOADING });

  try {
    const response = yield call(blog.getBlogCategories, action.payload);
    yield put({ type: types.GET_BLOG_CATEGORIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLOG_CATEGORIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_BLOG_CATEGORIES_LOADING });
  }
}

function* getBlogArticleByURL(action) {
  yield put({ type: types.SHOW_BLOG_LOADING });
  try {
    const seoResponse = yield call(storefront.checkSeoRedirect, action.payload.pathname.replace("/", ""));
    if (!isEmpty(seoResponse)) {
      return yield put(checkSeoRedirectSuccess(seoResponse));
    }

    const response = yield call(blog.getBlogArticleByURL, action.payload);
    yield put({ type: types.GET_BLOG_ARTICLE_BY_URL_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLOG_ARTICLE_BY_URL_REQUEST_ERROR, payload: error.detail });
  } finally {
    yield put({ type: types.HIDE_BLOG_LOADING });
  }
}

function* getArchivedBlogCategories(action) {
  yield put({ type: types.SHOW_BLOG_ARCHIVES_LOADING });
  try {
    const response = yield call(blog.getArchivedBlogCategories, action.payload);
    yield put(getArchivedBlogArticlesRequestSuccess(response));
  } catch (error) {
    yield put(getArchivedBlogArticlesRequestError(error));
  } finally {
    yield put({ type: types.HIDE_BLOG_ARCHIVES_LOADING });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_BLOG_ARTICLES_REQUEST, serverSagaWrapper(getBlogArticles)),
    yield takeLatest(types.GET_BLOG_AUTHORS_REQUEST, serverSagaWrapper(getBlogAuthors)),
    yield takeLatest(types.GET_BLOG_CATEGORIES_REQUEST, serverSagaWrapper(getBlogCategories)),
    yield takeLatest(types.GET_BLOG_ARTICLE_BY_URL_REQUEST, serverSagaWrapper(getBlogArticleByURL)),
    yield takeLatest(types.GET_ARCHIVED_BLOG_ARTICLES_REQUEST, serverSagaWrapper(getArchivedBlogCategories)),
  ]);
}

import * as types from "./businessActionsTypes";

const initialState = {
  directoryList: {
    results: [],
    count: 0,
    isLoading: false,
    error: null,
  },
};

const business = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BUSINESS_DIRECTORIES_REQUEST:
      return {
        ...state,
        directoryList: { ...state.directoryList, isLoading: true, error: null }
      };

    case types.GET_BUSINESS_DIRECTORIES_SUCCESS:
      return {
        ...state,
        directoryList: { ...state.directoryList, isLoading: false, error: null }
      };

    case types.GET_BUSINESS_DIRECTORIES_FAILED:
      return {
        ...state,
        directoryList: { ...state.directoryList, isLoading: false, error: null }
      };

    case types.CLEAR_BUSINESS_DIRECTORIES_ACTION:
      return {
        ...state,
        directoryList: { results: [], count: 0, isLoading: false, error: null }
      }

    default:
      return state;
  }
};

export default business;

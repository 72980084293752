import fetch from "./customFetch";


const business = {
  async getDirectoryList({ page, perPage, category, duration, ukCounty }) {
    const query = new URLSearchParams();

    if (category) {
      query.set("category", category);
    }

    if (ukCounty) {
      query.set("uk_county", ukCounty);
    }

    if (duration) {
      query.set("duration", duration);
    }

    if (page && perPage) {
      query.set("page", page);
      query.set("page_size", perPage)
    }

    try {
      return await fetch({
        url: `/storefront/directory/?for_impressions=true&${query.toString()}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDirectoryCategoryList({ page, perPage, is_active }) {
    const query = new URLSearchParams();

    if (page && perPage) {
      query.set("page", page);
      query.set("page_size", perPage)
    }
    if (is_active || typeof is_active === "boolean") {
      query.set("is_active", is_active)
    }

    try {
      return await fetch({
        url: `/storefront/directory/categories/?${query.toString()}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default business;

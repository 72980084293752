import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const NorthernIrelandFlagIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31 28C31 29.1046 30.1046 30 29 30H3C1.89543 30 1 29.1046 1 28V4C1 2.89543 1.89543 2 3 2H29C30.1046 2 31 2.89543 31 4V28Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 30C14 30.5523 13.5523 31 13 31C12.4477 31 12 30.5523 12 30V21L14 24V30ZM18 24V30C18 30.5523 18.4477 31 19 31C19.5523 31 20 30.5523 20 30V21L18 24ZM23.909 20L31 20C31.5523 20 32 19.5523 32 19C32 18.4477 31.5523 18 31 18L22.8181 18L23.909 20ZM23.1537 14L31 14C31.5523 14 32 13.5523 32 13C32 12.4477 31.5523 12 31 12L24.0769 12L23.1537 14ZM20 11.1111V2C20 1.44772 19.5523 1 19 1C18.4477 1 18 1.44772 18 2V8.05556L20 11.1111ZM14 8V2C14 1.44772 13.5523 1 13 1C12.4477 1 12 1.44772 12 2V11L14 8ZM7.92303 12L1 12C0.447714 12 0 12.4477 0 13C0 13.5523 0.447716 14 1 14L8.84606 14L7.92303 12ZM9.18169 18L1 18C0.447714 18 0 18.4477 0 19C0 19.5523 0.447716 20 1 20L8.09084 20L9.18169 18Z"
          fill="currentColor"
        />
        <path
          d="M20 21L16 27L12 21L7 22L9.99983 16.5L7 10L12 11L16 5L20 11.1111L25 10L21.9998 16.5L25 22L20 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5945 20C16.6517 20 17.602 19.3367 17.9944 18.3267L18.977 15.79C18.9998 15.7311 19.0058 15.6668 18.9943 15.6046C18.9828 15.5423 18.9543 15.4848 18.9121 15.4386C18.8699 15.3924 18.8157 15.3596 18.756 15.3439C18.6963 15.3282 18.6335 15.3303 18.5749 15.35L18.3187 15.4367C18.1371 15.4967 17.9879 15.64 17.9166 15.8233L17.4593 17H17.216V13.0833C17.216 12.9728 17.1733 12.8668 17.0973 12.7887C17.0213 12.7106 16.9182 12.6667 16.8107 12.6667C16.7031 12.6667 16.6 12.7106 16.524 12.7887C16.448 12.8668 16.4053 12.9728 16.4053 13.0833V16H16.081V12.4167C16.081 12.3062 16.0382 12.2002 15.9622 12.122C15.8862 12.0439 15.7831 12 15.6756 12C15.568 12 15.4649 12.0439 15.3889 12.122C15.3129 12.2002 15.2702 12.3062 15.2702 12.4167V16H14.9459V12.9167C14.9459 12.8062 14.9032 12.7002 14.8271 12.622C14.7511 12.5439 14.648 12.5 14.5405 12.5C14.433 12.5 14.3298 12.5439 14.2538 12.622C14.1778 12.7002 14.1351 12.8062 14.1351 12.9167V16H13.8108V13.9167C13.8108 13.8062 13.7681 13.7002 13.692 13.622C13.616 13.5439 13.5129 13.5 13.4054 13.5C13.2979 13.5 13.1948 13.5439 13.1187 13.622C13.0427 13.7002 13 13.8062 13 13.9167V17.3333C13 18.8067 14.161 20 15.5945 20Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

NorthernIrelandFlagIcon.propTypes = {
  className: PropTypes.string,
};

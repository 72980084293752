import fetch from "./customFetch";


const dealers = {
  async getListOfAllDealers() {
    try {
      return await fetch({
        url: "/storefront/dealers/",
        method: "GET",
      });
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async getDealerNews({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/dealers/news/?page=${page}&page_size=${perPage}&dlz=true`,
        method: "GET",
      });
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async getDealerSpecialities({ page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize);
    }

    try {
      return await fetch({
        url: `/dealers/specialities/autocomplete/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealersLocations(params) {
    const { page, pageSize } = params || {};

    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize);
    }

    try {
      return await fetch({
        url: `/storefront/dealers/locations/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAbroadDealersLocations(params) {
    const { page, pageSize } = params || {};

    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize);
    }

    try {
      return await fetch({
        url: `/storefront/dealers/abroad-locations/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  getSingleDealer: async (dealer) => {
    try {
      return await fetch({
        url: `/storefront/dealers/${dealer}/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  getMostPopularDealers: async ({ page = 1, pageSize = 10 }) => {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize);
    }
    try {
      return await fetch({
        url: `/storefront/dealers/most-popular/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  requestApiToken: async (data) => {
    try {
      return await fetch({
        url: "/dealers/love-antiques-tokens/request-token/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  revokeApiToken: async () => {
    try {
      return await fetch({
        url: "/dealers/love-antiques-tokens/revoke-token/",
        method: "DELETE",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerTestimonials({ url, page = 1, pageSize = 5, ordering = "-date" }) {
    const queryString = new URLSearchParams();

    if (page) {
      queryString.set("page", page);
    }

    if (pageSize) {
      queryString.set("page_size", pageSize);
    }

    if (ordering) {
      queryString.set("ordering", ordering);
    }

    try {
      return await fetch({
        url: `/storefront/dealers/${url}/testimonials/?${queryString.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createDealerTestimonials({ url, data }) {
    try {
      return await fetch({
        url: `/storefront/dealers/${url}/testimonials/`,
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default dealers;

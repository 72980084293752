import * as types from "./ordersActionsTypes";

const initialState = {
  orders: [],
  ordersLoading: false,
  ordersCount: false,
  error: null,
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEALER_ORDERS_REQUEST_SUCCESS:
      return {
        ...state,
        orders: action.payload.results,
        ordersCount: action.payload.count,
      };

    case types.GET_DEALER_ORDERS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.DEALER_ORDERS_LOADING:
      return {
        ...state,
        ordersLoading: action.payload,
      };

    default:
      return state;
  }
};

export default orders;

import * as types from "./homeActionsTypes";


export const requestMostPopular = () => {
  return { type: types.REQUEST_MOST_POPULAR };
};

export const requestHomeItems = (page, pageSize = 16, showInHomepage, forImpressions) => {
  return {
    type: types.REQUEST_HOME_ITEMS,
    payload: { page, pageSize, showInHomepage, forImpressions }
  }
};

export const getCategoriesWithoutPaginationRequest = () => {
  return { type: types.GET_ALL_HOME_CATEGORIES_REQUEST };
};

export const getCategoriesWithoutPaginationError = (payload) => {
  return { type: types.GET_ALL_HOME_CATEGORIES_ERROR, payload };
};

export const getCategoriesWithoutPaginationSuccess = (payload) => {
  return { type: types.GET_ALL_HOME_CATEGORIES_SUCCESS, payload };
};

export const getAllHomeBlogsRequest = () => {
  return { type: types.GET_ALL_HOME_BLOGS_REQUEST };
};

export const getAllHomeBlogsError = payload => {
  return { type: types.GET_ALL_HOME_BLOGS_ERROR, payload };
};

export const getAllHomeBlogsSuccess = payload => {
  return { type: types.GET_ALL_HOME_BLOGS_SUCCESS, payload };
};

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const EnglandFlagIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.1822 1.73779H2.81775C1.26406 1.73779 0 3.00186 0 4.55554V27.4447C0 28.9984 1.26406 30.2624 2.81775 30.2624H29.1822C30.7359 30.2624 32 28.9984 32 27.4447V4.55554C32 3.00186 30.7359 1.73779 29.1822 1.73779ZM29.1822 28.3874H19.5891V19.5892H30.125V27.4447C30.125 27.9645 29.7021 28.3874 29.1822 28.3874ZM1.875 27.4447V19.5892H12.4109V28.3874H2.81775C2.29794 28.3874 1.875 27.9645 1.875 27.4447ZM2.81775 3.61279H12.4109V12.411H1.875V4.55554C1.875 4.03573 2.29794 3.61279 2.81775 3.61279ZM30.125 4.55554V12.411H19.5891V3.61279H29.1822C29.7021 3.61279 30.125 4.03573 30.125 4.55554ZM18.6516 17.7142C18.1338 17.7142 17.7141 18.1339 17.7141 18.6517V28.3874H14.2859V18.6517C14.2859 18.1339 13.8662 17.7142 13.3484 17.7142H1.875V14.286H13.3484C13.8662 14.286 14.2859 13.8662 14.2859 13.3485V3.61279H17.7141V13.3485C17.7141 13.8662 18.1338 14.286 18.6516 14.286H30.125V17.7142H18.6516Z" />
      </svg>
    </span>
  );
};

EnglandFlagIcon.propTypes = {
  className: PropTypes.string,
};

export const GET_TOP_ITEMS_REQUEST = "GET_TOP_ITEMS_REQUEST";
export const GET_TOP_ITEMS_SUCCESS = "GET_TOP_ITEMS_SUCCESS";
export const GET_TOP_ITEMS_ERROR = "GET_TOP_ITEMS_ERROR";

export const GET_ITEMS_BY_CATEGORY_REQUEST = "GET_ITEMS_BY_CATEGORY_REQUEST";
export const GET_ITEMS_BY_CATEGORY_SUCCESS = "GET_ITEMS_BY_CATEGORY_SUCCESS";
export const GET_ITEMS_BY_CATEGORY_ERROR = "GET_ITEMS_BY_CATEGORY_ERROR";

export const GET_HNL_CATEGORY_DATA_REQUEST = "GET_HNL_CATEGORY_DATA_REQUEST";
export const GET_HNL_CATEGORY_DATA_REQUEST_SUCCESS = "GET_HNL_CATEGORY_DATA_REQUEST_SUCCESS";
export const GET_HNL_CATEGORY_DATA_REQUEST_ERROR = "GET_HNL_CATEGORY_DATA_REQUEST_ERROR";

export const SET_HNL_ITEMS_LOADING = "SET_HNL_ITEMS_LOADING";
export const RESET_HNL_ITEMS_LOADING = "RESET_HNL_ITEMS_LOADING";

import React, { useEffect } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// redux
import { toggleAdvertisePublicAddressAction } from "../../redux/auth/authActions";

const AdvertisePublicAddressModal = ({
  authorizedUserProfile,
  isAdvertisePublicAddressModalOpened,
  toggleAdvertisePublicAddressModal,
}) => {
  const history = useHistory();

  const closeAdvertisePublicAddressModal = () => {
    sessionStorage.removeItem("advertisePublicAddress");
    toggleAdvertisePublicAddressModal(false);
  };

  const redirectToProfile = () => {
    closeAdvertisePublicAddressModal();
    history.push("/profile/my-details?tab=contact-info");
  };

  useEffect(() => {
    const isShowModal =
      authorizedUserProfile?.id &&
      sessionStorage.getItem("advertisePublicAddress") === "true";

    if (isShowModal) {
      toggleAdvertisePublicAddressModal(true);
    }
  }, [authorizedUserProfile]);

  useEffect(() => {
    return () => closeAdvertisePublicAddressModal();
  }, []);

  return (
    <Modal
      open={isAdvertisePublicAddressModalOpened}
      centered
      okText="Change visibility"
      cancelText="Keep my address private"
      onCancel={closeAdvertisePublicAddressModal}
      onOk={redirectToProfile}
      cancelButtonProps={{
        size: "large",
        className: "mt-1 mb-1 text-red",
        type: "ghost",
      }}
      okButtonProps={{ size: "large", className: "mt-1 mb-1" }}
      width={640}
      wrapClassName="advertise-modal--success"
    >
      <div className="callback-modal--body">
        <div className="callback-modal--body-header">
          <img
            src={`${process.env.REACT_APP_IMAGES_URL}loginLALogo.svg`}
            alt="LoveAntiques"
            title="LoveAntiques"
          />
        </div>
        <div className="callback-modal--body-main">
          <h2 className="text-center h2">
            Increase Visibility with a Public Address
          </h2>
          <div className="w-100-percent mt-6 text-center fw-600">
            Your address is private, but making it public can help you appear in
            more searches and get more inquiries.
          </div>
        </div>
      </div>
    </Modal>
  );
};

AdvertisePublicAddressModal.propTypes = {
  isAdvertisePublicAddressModalOpened: PropTypes.bool,
  toggleAdvertisePublicAddressModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    authorizedUserProfile: state.auth.authorizedUserProfile,
    isAdvertisePublicAddressModalOpened:
      state.auth.isAdvertisePublicAddressModalOpened,
  }),
  {
    toggleAdvertisePublicAddressModal: toggleAdvertisePublicAddressAction,
  }
)(AdvertisePublicAddressModal);

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

// helpers
import { formatPrice } from "../../../helpers";

// constants
import { NO_IMAGE_URL } from "../../../constants";

const SHOWN_RESULT_CATEGORIES_MAP = [
  "Category",
  "Period",
  "Material",
  "Origin",
  "Artisan",
  "Dealer",
  "HomeAndLiving",
];

const generateRedirectUrl = (searchResult) => {
  const { searchType, url, query } = searchResult || {};

  switch (searchType) {
    case "Suggestion":
      return `/search-results?search=${query}`;
    case "Dealer":
      return `/directory/dealers/${url}`;
    case "Artisan":
      return `/knowledge/artisan/${url}`;
    case "Item":
      return `/items/listings/${url}`;
    case "Category":
    case "HomeAndLiving":
      return `/${url}`;
    case "Period":
      return `/period/${url}`;
    case "Material":
      return `/knowledge/material/${url}`;
    case "More":
      return `/search-results?search=${query}`;
    default:
      return `/knowledge/origin/${url}`;
  }
};

const formatItemPrice = (searchResult) => {
  switch (true) {
    case searchResult.item_is_reserved:
      return "Reserved";
    case searchResult.make_this_price_poa:
      return "POA";
    default:
      return `£ ${formatPrice(searchResult.price)}`;
  }
};

const SearchResult = ({ searchResult }) => {
  const withType = SHOWN_RESULT_CATEGORIES_MAP.includes(
    searchResult.searchType
  );

  const label =
    searchResult.businessName ||
    searchResult.title ||
    searchResult.query ||
    searchResult.name;

  return (
    <Link
      to={generateRedirectUrl(searchResult)}
      className={classNames("header--search-result-item", {
        "header--search-result-item__with-type": withType,
        "header--search-result-item__item": searchResult.searchType === "Item",
      })}
    >
      {searchResult.searchType === "Item" ? (
        <>
          <img
            src={searchResult.image || NO_IMAGE_URL}
            className="header--search-result-item-image"
            alt="logo"
          />
          <div className="header--search-result-item-wrapper">
            <h3 className="header--search-result-item-title">
              {searchResult.title}
            </h3>
            <p className="header--search-result-item-description">
              {searchResult.description}
            </p>
            <p className="header--search-result-item-status">
              {formatItemPrice(searchResult)}
            </p>
          </div>
        </>
      ) : (
        <>
          <span className="header--search-result-item-label">{label}</span>
          {withType && (
            <span
              className={classNames(
                "header--search-result-type",
                `header--search-result-type__${searchResult.searchType.toLowerCase()}`
              )}
            >
              {searchResult.searchType}
            </span>
          )}
        </>
      )}
    </Link>
  );
};

SearchResult.propTypes = {
  searchResult: PropTypes.object.isRequired,
};

export default SearchResult;

import * as React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const TwitterIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0486 9.46875H28.2741L21.2278 17.5427L29.5182 28.5313H23.0278L17.9405 21.8682L12.1262 28.5313H8.89753L16.4337 19.8925L8.48438 9.47025H15.1401L19.7314 15.5595L25.0486 9.46875ZM23.9141 26.5963H25.702L14.1635 11.3032H12.2464L23.9141 26.5963Z"
            fill="white"
          />
        </g>
      </svg>
    </span>
  );
}

TwitterIcon.propTypes = {
  className: PropTypes.string,
};

export const initialState = {
  promises: {},
  shouldTrack: false,
  accessToken: "",
  responses: {},
};

const ssr = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SHOULD_TRACK_ACTION":
      return {
        ...state,
        shouldTrack: action.payload,
      };

    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload,
      }

    case "ADD_PROMISE": {
      return {
        ...state,
        promises: {
          ...state.promises,
          [action.key]: action.promise
        }
      }
    }

    case "RESET_PROMISES":
      return {
        ...state,
        promises: {}
      };

    case "ADD_API_RESPONSE":
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.key]: action.data
        }
      }

    case "RESET_RESPONSES": {
      return {
        ...state,
        responses: {},
      }
    }

    default:
      return state;
  }
};

export default ssr;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const pathNamesBlackList = ["/directory/dealers/"];
  const isPathInBlackList = pathNamesBlackList.some(notAllowedPathName => pathname.includes(notAllowedPathName));

  useEffect(() => {
    !isPathInBlackList && window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { getGoogleCaptchaKeyRequest } from "../../redux/auth/authActions";

const GoogleCaptchaProvider = ({
  children,
  googleCaptchaKey,
  getGoogleCaptchaKey
}) => {
  useEffect(() => {
    process.env.REACT_APP_USE_GOOGLE_SERVICES === "true" && getGoogleCaptchaKey();
  }, []);

  return (
    <>
      {!!googleCaptchaKey && (
        <Helmet>
          <script src={`https://www.google.com/recaptcha/api.js?render=${googleCaptchaKey}`} />
          <style>{".grecaptcha-badge { visibility: hidden; }"}</style>
        </Helmet>
      )}
      {children}
    </>
  );
}

export default connect(
  state => ({
    googleCaptchaKey: state.auth.googleCaptchaKey
  }),
  {
    getGoogleCaptchaKey: getGoogleCaptchaKeyRequest,
  }
)(GoogleCaptchaProvider);

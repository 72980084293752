import React from "react"
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LoveAntiquesLogo = ({ width }) => {
  const isWidthLarge = width > 1200;

  return (
    <Link to="/" {...(isWidthLarge && { className: "header--logo" })}>
      <img
        src={`${process.env.REACT_APP_IMAGES_URL}${
            isWidthLarge ? "la-new-logo.svg" : "la-new-logo-large.svg"
          }`}
        alt="LoveAntiques"
        width={isWidthLarge ? 144 : 294}
        height={isWidthLarge ? 40 : 38}
      />
    </Link>
  );
}

LoveAntiquesLogo.propTypes = {
  width: PropTypes.number
};

export default LoveAntiquesLogo
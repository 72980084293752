import * as React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const PinterestIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g fill="white">
          <path
            d="M16.0004 3.22042e-06C12.2698 -0.0023627 8.65564 1.29896 5.78283 3.67895C2.91002 6.05895 0.959165 9.36799 0.267589 13.0339C-0.423987 16.6999 0.187191 20.4923 1.99545 23.7553C3.8037 27.0184 6.69535 29.547 10.1704 30.904C10.0304 29.638 9.90241 27.692 10.2244 26.31C10.5164 25.06 12.1004 18.356 12.1004 18.356C12.1004 18.356 11.6224 17.398 11.6224 15.982C11.6224 13.756 12.9124 12.096 14.5184 12.096C15.8824 12.096 16.5424 13.12 16.5424 14.35C16.5424 15.722 15.6684 17.774 15.2164 19.676C14.8404 21.268 16.0164 22.568 17.5864 22.568C20.4304 22.568 22.6164 19.568 22.6164 15.24C22.6164 11.41 19.8624 8.732 15.9324 8.732C11.3804 8.732 8.70841 12.146 8.70841 15.674C8.70841 17.05 9.23841 18.524 9.89841 19.326C9.95525 19.3865 9.99544 19.4606 10.0151 19.5412C10.0347 19.6219 10.0331 19.7062 10.0104 19.786C9.88841 20.29 9.61841 21.378 9.56641 21.6C9.49641 21.892 9.33441 21.954 9.03041 21.814C7.03041 20.884 5.78241 17.962 5.78241 15.614C5.78241 10.568 9.45041 5.934 16.3544 5.934C21.9044 5.934 26.2184 9.888 26.2184 15.174C26.2184 20.688 22.7404 25.126 17.9164 25.126C16.2944 25.126 14.7704 24.284 14.2484 23.288L13.2524 27.092C12.8904 28.482 11.9144 30.224 11.2624 31.286C13.4624 31.9662 15.7832 32.1635 18.0664 31.8645C20.3496 31.5655 22.5414 30.7772 24.492 29.5534C26.4426 28.3296 28.106 26.6992 29.3687 24.7736C30.6314 22.848 31.4636 20.6725 31.8083 18.3957C32.1531 16.119 32.0024 13.7947 31.3665 11.5815C30.7306 9.36835 29.6245 7.31853 28.1237 5.57207C26.623 3.82562 24.7629 2.42368 22.6706 1.46203C20.5783 0.500383 18.3031 0.00168461 16.0004 3.22042e-06Z"
            fill="#CB2026"
          />
        </g>
      </svg>
    </span>
  );
}

PinterestIcon.propTypes = {
  className: PropTypes.string,
};

import * as types from "./storefrontActionsTypes";

const initialState = {
  dealers: [],
  dealersCount: 0,
  searchResult: {},
  allSearchResults: [],
  allSearchItemsCount: 0,
  error: null,
  isLoading: false,
  searchLoading: false,
  emailPreferencesSearchResult: {},
  emailPreferencesSearchLoading: false,
  redirectDataUrl: "",
  selectedSpeciality: null,
  dealersLocations: null,
  dealersLocationsLoading: false,
};

const storefront = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEALERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.GET_DEALERS_LOCATIONS_REQUEST:
      return {
        ...state,
        dealersLocations: null,
        dealersLocationsLoading: true,
      };

    case types.GET_DEALERS_LOCATIONS_SUCCESS:
      return {
        ...state,
        dealersLocations: { ...action.payload },
        dealersLocationsLoading: false,
      };

    case types.GET_DEALERS_LOCATIONS_ERROR:
      return {
        ...state,
        dealersLocations: null,
        dealersLocationsLoading: false,
      };

    case types.GLOBAL_SEARCH_REQUEST:
      return {
        ...state,
        searchLoading: true,
        error: null,
      };

    case types.SEARCH_EMAIL_PREFERENCES_REQUEST:
      return {
        ...state,
        emailPreferencesSearchLoading: true,
        error: null,
      };

    case types.GET_DEALERS_REQUEST_SUCCESS:
      return {
        ...state,
        dealers: action.payload.results,
        dealersCount: action.payload.count,
        isLoading: false,
        error: null,
      };

    case types.GLOBAL_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
        searchLoading: false,
        error: null,
      };

    case types.SEARCH_EMAIL_PREFERENCES_REQUEST_SUCCESS:
      return {
        ...state,
        emailPreferencesSearchResult: action.payload,
        emailPreferencesSearchLoading: false,
        error: null,
      };

    case types.GET_DEALERS_REQUEST_ERROR:
    case types.GLOBAL_SEARCH_REQUEST_ERROR:
    case types.GET_ALL_SEARCH_RESULTS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        searchLoading: false,
        allSearchItemsCount: 0
      };

    case types.SEARCH_EMAIL_PREFERENCES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        emailPreferencesSearchLoading: false,
      };

    case types.CHECK_SEO_REDIRECT_REQUEST_SUCCESS:
      return {
        ...state,
        redirectDataUrl: action.payload.toUrl,
      };

    case types.CHECK_SEO_REDIRECT_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.SET_SELECTED_SPECIALITY:
      return {
        ...state,
        selectedSpeciality: action.payload,
      };

    case types.GET_ALL_SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_ALL_SEARCH_RESULTS_REQUEST_SUCCESS:
      return {
        ...state,
        allSearchItemsCount: action.payload.count,
        allSearchResults: action.payload.results,
        isLoading: false,
      };

    case types.RESET_SEO_REDIRECT:
      return {
        ...state,
        redirectDataUrl: ""
      }

    default:
      return state;
  }
};

export default storefront;

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const AdmissionIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.439 4.56246C11.1791 4.3024 11.0268 3.95379 11.0128 3.58634C10.9988 3.21889 11.124 2.8597 11.3634 2.58058C11.3832 2.55737 11.3935 2.52753 11.3922 2.49705C11.3909 2.46656 11.3782 2.43769 11.3565 2.41621L9.97746 1.03558C9.95466 1.0128 9.92375 1 9.89152 1C9.85929 1 9.82838 1.0128 9.80558 1.03558L7.60746 3.23371C7.52636 3.31479 7.46526 3.41365 7.42902 3.52246C7.39292 3.63152 7.3319 3.73066 7.2508 3.81202C7.16971 3.89339 7.07077 3.95474 6.96183 3.99121C6.85296 4.02749 6.754 4.08858 6.67277 4.16965L1.03558 9.80558C1.0128 9.82838 1 9.85929 1 9.89152C1 9.92375 1.0128 9.95466 1.03558 9.97746L2.41465 11.3565C2.43613 11.3782 2.465 11.3909 2.49549 11.3922C2.52597 11.3935 2.55581 11.3832 2.57902 11.3634C2.85808 11.1237 3.21735 10.9983 3.58493 11.0123C3.9525 11.0262 4.30125 11.1785 4.56135 11.4386C4.82145 11.6987 4.97371 12.0474 4.98765 12.415C5.00159 12.7826 4.87618 13.1418 4.63652 13.4209C4.61672 13.4441 4.60643 13.4739 4.6077 13.5044C4.60898 13.5349 4.62173 13.5638 4.6434 13.5853L6.02246 14.9643C6.04526 14.9871 6.07617 14.9999 6.1084 14.9999C6.14063 14.9999 6.17154 14.9871 6.19433 14.9643L11.8318 9.32715C11.9129 9.24592 11.974 9.14696 12.0103 9.03808C12.0464 8.92903 12.1074 8.82989 12.1885 8.74852C12.2696 8.66715 12.3685 8.6058 12.4775 8.56933C12.5863 8.53309 12.6851 8.472 12.7662 8.3909L14.9643 6.19277C14.9871 6.16998 14.9999 6.13906 14.9999 6.10683C14.9999 6.0746 14.9871 6.04369 14.9643 6.0209L13.5853 4.64183C13.5638 4.62017 13.5349 4.60742 13.5044 4.60614C13.4739 4.60487 13.4441 4.61516 13.4209 4.63496C13.1422 4.87476 12.7832 5.00052 12.4158 4.98708C12.0483 4.97363 11.6995 4.82199 11.439 4.56246Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.82746 4.38898L7.31152 3.87305M9.20309 5.76461L8.85902 5.42086M10.5787 7.14055L10.235 6.79648M12.1265 8.68805L11.6106 8.17211"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};

AdmissionIcon.propTypes = {
  className: PropTypes.string,
};

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const JoinStorefront = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 29.75H45.5V47.25H10.5V29.75Z" fill="white" />
        <path opacity="0.2" d="M21 21H35V29.75H21V21Z" fill="#1FB6BD" />
        <path
          opacity="0.2"
          d="M49 21V24.5C49 26.3565 48.2625 28.137 46.9497 29.4497C45.637 30.7625 43.8565 31.5 42 31.5C40.1435 31.5 38.363 30.7625 37.0503 29.4497C35.7375 28.137 35 26.3565 35 24.5V21H21V24.5C21 26.3565 20.2625 28.137 18.9497 29.4497C17.637 30.7625 15.8565 31.5 14 31.5C12.1435 31.5 10.363 30.7625 9.05025 29.4497C7.7375 28.137 7 26.3565 7 24.5V21L10.1369 10.0187C10.2411 9.65434 10.4608 9.33361 10.763 9.10481C11.0652 8.87601 11.4335 8.7515 11.8125 8.75H44.1875C44.5676 8.75007 44.9374 8.87393 45.2409 9.10284C45.5444 9.33175 45.7651 9.65326 45.8697 10.0187L49 21Z"
          fill="#004F57"
        />
        <path
          d="M50.75 21C50.7509 20.8373 50.7288 20.6753 50.6844 20.5188L47.5453 9.5375C47.3343 8.80887 46.8933 8.16804 46.2881 7.7107C45.6829 7.25336 44.946 7.00405 44.1875 7H11.8125C11.054 7.00405 10.3171 7.25336 9.71188 7.7107C9.10669 8.16804 8.66568 8.80887 8.45469 9.5375L5.31781 20.5188C5.27262 20.6752 5.24979 20.8372 5.25 21V24.5C5.25 25.8584 5.56627 27.1981 6.17376 28.4131C6.78126 29.6281 7.66329 30.685 8.75 31.5V45.5C8.75 46.4283 9.11875 47.3185 9.77513 47.9749C10.4315 48.6312 11.3217 49 12.25 49H43.75C44.6783 49 45.5685 48.6312 46.2249 47.9749C46.8813 47.3185 47.25 46.4283 47.25 45.5V31.5C48.3367 30.685 49.2188 29.6281 49.8262 28.4131C50.4337 27.1981 50.75 25.8584 50.75 24.5V21ZM11.8125 10.5H44.1875L46.6856 19.25H9.32094L11.8125 10.5ZM22.75 22.75H33.25V24.5C33.25 25.8924 32.6969 27.2277 31.7123 28.2123C30.7277 29.1969 29.3924 29.75 28 29.75C26.6076 29.75 25.2723 29.1969 24.2877 28.2123C23.3031 27.2277 22.75 25.8924 22.75 24.5V22.75ZM19.25 22.75V24.5C19.25 25.8924 18.6969 27.2277 17.7123 28.2123C16.7277 29.1969 15.3924 29.75 14 29.75C12.6076 29.75 11.2723 29.1969 10.2877 28.2123C9.30312 27.2277 8.75 25.8924 8.75 24.5V22.75H19.25ZM43.75 45.5H12.25V33.075C12.8261 33.1911 13.4123 33.2498 14 33.25C15.3584 33.25 16.6981 32.9337 17.9131 32.3262C19.1281 31.7187 20.185 30.8367 21 29.75C21.815 30.8367 22.8719 31.7187 24.0869 32.3262C25.3019 32.9337 26.6416 33.25 28 33.25C29.3584 33.25 30.6981 32.9337 31.9131 32.3262C33.1281 31.7187 34.185 30.8367 35 29.75C35.815 30.8367 36.8719 31.7187 38.0869 32.3262C39.3019 32.9337 40.6416 33.25 42 33.25C42.5877 33.2498 43.1739 33.1911 43.75 33.075V45.5ZM42 29.75C40.6076 29.75 39.2723 29.1969 38.2877 28.2123C37.3031 27.2277 36.75 25.8924 36.75 24.5V22.75H47.25V24.5C47.25 25.8924 46.6969 27.2277 45.7123 28.2123C44.7277 29.1969 43.3924 29.75 42 29.75Z"
          fill="#004F57"
        />
      </svg>
    </span>
  );
};

JoinStorefront.propTypes = {
  className: PropTypes.string,
};

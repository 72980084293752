export const SET_REQUEST_TOKEN = "SET_REQUEST_TOKEN";

export const ALLOWED_SUCCESS = "ALLOWED_SUCCESS";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
export const RESET_MESSAGE_STATUS = "RESET_MESSAGE_STATUS";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";

export const OPEN_MOBILE_DROPDOWN = "OPEN_MOBILE_DROPDOWN";
export const CLOSE_MOBILE_DROPDOWN = "CLOSE_MOBILE_DROPDOWN";

export const SET_MOBILE_DROPDOWN_SELECTED_KEY = "SET_MOBILE_DROPDOWN_SELECTED_KEY";

export const CREATE_NEW_SEARCH_EMAIL_PREFERENCE = "CREATE_NEW_SEARCH_EMAIL_PREFERENCE";
export const SET_SEARCH_PREFERENCES_VALUE = "SET_SEARCH_PREFERENCES_VALUE";
export const CLEAR_SEARCH_PREFERENCES_VALUE = "CLEAR_SEARCH_PREFERENCES_VALUE";

export const NOTIFICATION_DRAWER_OPEN = "NOTIFICATION_DRAWER_OPEN";
export const NOTIFICATION_DRAWER_CLOSE = "NOTIFICATION_DRAWER_CLOSE";

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// components
import {
  EbayIcon,
  FacebookIcon,
  FacebookIcon2,
  GoogleMapsIcon,
  InstagramIcon,
  LinkedinIcon,
  PinterestIcon,
  TikTokIcon,
  TwitterIcon,
  YouTubeIcon,
  YouTubeIcon2,
} from "../../../icons";

// assets
import "./social-icon.scss";

const SOCIAL_ICON_IMAGE = {
  Ebay: EbayIcon,
  Facebook: FacebookIcon,
  Facebook2: FacebookIcon2,
  GoogleMaps: GoogleMapsIcon,
  Instagram: InstagramIcon,
  Linkedin: LinkedinIcon,
  Pinterest: PinterestIcon,
  TikTok: TikTokIcon,
  Twitter: TwitterIcon,
  YouTube: YouTubeIcon,
  YouTube2: YouTubeIcon2,
}


export const SocialIcon = ({
  type,
  url,
  className,
}) => {
  const Icon = SOCIAL_ICON_IMAGE[type];

  return typeof url === "string" && url.length > 3 ? (
    <div
      className={classNames("social-icon", className)}
      onClick={(evt) => evt.stopPropagation()}
    >
      <a
        href={url?.includes("://") ? url : `https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={type}
      >
        <Icon className="social-icon--image" />
      </a>
    </div>
  ) : null;
};

SocialIcon.propTypes = {
  type: PropTypes.oneOf([
    "Ebay",
    "Facebook",
    "Facebook2",
    "GoogleMaps",
    "Instagram",
    "Linkedin",
    "Pinterest",
    "TikTok",
    "Twitter",
    "YouTube",
    "YouTube2",
  ]),
  url: PropTypes.string,
  className: PropTypes.string,
};

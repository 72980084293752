import * as types from "./profilesActionsTypes";

export const initialState = {
  processing: false,
  addressProcessing: false,
  error: null,
  loading: false,
  favItems: [],
  favItemsIds: [],
  favItemsIdsCount: 0,
  favItemsCount: 0,
  favDealers: [],
  favDealersCount: 0,
  favFairs: [],
  favFairsCount: 0,
  favDirectories: [],
  favDirectoriesCount: 0,
  favItemsLoading: false,
  favItemsIdsLoading: false,
  favDealersLoading: false,
  favFairsLoading: false,
  favDirectoriesLoading: false,
  dealerEmailEnquiries: [],
  websiteEnquiriesCount: 0,
  websiteSubscriptionsCount: 0,
  dealerEmailEnquiriesCount: 0,
  dealerEmailEnquiriesCountPagination: 0,
  dealerEmailEnquiriesLoading: false,
  dealerDeliveryEnquiriesLoading: false,
  dealerPhoneEnquiries: [],
  dealerPhoneEnquiriesCount: 0,
  dealerPhoneEnquiriesLoading: false,
  dealerCallbackEnquiries: [],
  dealerCallbackEnquiriesCount: 0,
  dealerCallbackEnquiriesLoading: false,
  dealerEmailEnquiriesForChartCount: 0,
  dealerWebsiteEmailEnquiriesCount: 0,
  dealerEmailEnquiriesForChartLoading: false,
  dealerPhoneEnquiriesCountPagination: 0,
  dealerPhoneEnquiriesForChartCount: 0,
  dealerWebsitePhoneEnquiriesCount: 0,
  dealerPhoneEnquiriesForChartLoading: false,
  dealerCallbackEnquiriesForChartCount: 0,
  dealerCallbackEnquiriesForChartLoading: false,
  dealerDeliveryEnquiriesForChartCount: 0,
  unreadEmailEnquiriesCount: 0,
  unreadDeliveryEnquiriesCount: 0,
  unreadCallbackEnquiriesCount: 0,
  dealer: {},
  selectedAntiqueItem: {
    images: [],
    artisans: [],
    currencies: [],
    suggestedCategories: {
      periods: [],
      categories: [],
      origins: [],
      materials: []
    }
  },
  successModal: {
    isVisible: false,
    text: "",
  },
  newImageForAuthorizedUser: null,
  allEmailPreferences: [],
  allEmailPreferencesCount: 0,
  emailPreferencesLoading: false,
  emailPreferencesSearchItems: [],
  emailPreferencesSearchItemsCount: 0,
  emailPreferencesProcessing: false,
  dealerInvoices: [],
  dealerInvoicesCount: 0,
  dealerInvoicesLoading: false,
  dealerInvoicesProcessing: false,
  isImageRotating: false,
  dealerDefaultTexts: [],
  dealerDefaultTextCount: 0,
  selectedDefaultText: {},
  dealerDeliveryEnquiries: [],
  dealerDeliverEnquiriesCount: 0,
  imagesLoading: false,
  website: {
    blogs: [],
    template: {
      templateError: null,
      siteImages: {
        imageLogo: {},
        coverImage: {},
        favicon: {}
      },
      dealer: {}
    },
    info: {},
    singlePage: {},
    pages: [],
    marketplaces: {
      marketplacesProcessing: false,
      totalMarketplaces: 0,
      marketplaces: [],
      marketplacesError: null
    },
    subscriptions: []
  },
  holidayMode: {},
  tradeAssociations: [],
  tradeAssociationsError: null,
  dealerTradeAssociations: [],
  onlineSalesStatistics: 0,
};


const profiles = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };

    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.TOGGLE_PROFILE_ADDRESS_PROCESSING:
      return {
        ...state,
        addressProcessing: action.payload,
      };

    case types.ROTATE_ANTIQUE_IMAGE_PROCESSING:
      return {
        ...state,
        isImageRotating: action.payload,
      };

    case types.TOGGLE_FAV_ITEMS_LOADING:
      return {
        ...state,
        favItemsLoading: action.payload,
      };

    case types.TOGGLE_FAV_ITEMS_IDS_LOADING:
      return {
        ...state,
        favItemsIdsLoading: action.payload,
      };

    case types.TOGGLE_FAV_DEALERS_LOADING:
      return {
        ...state,
        favDealersLoading: action.payload,
      };

    case types.TOGGLE_FAV_FAIRS_LOADING:
      return {
        ...state,
        favFairsLoading: action.payload,
      };

    case types.TOGGLE_FAV_DIRECTORIES_LOADING:
      return {
        ...state,
        favDirectoriesLoading: action.payload,
      };

    case types.TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING:
      return {
        ...state,
        dealerEmailEnquiriesLoading: action.payload,
      };

    case types.TOGGLE_DEALER_DELIVERY_ENQUIRIES_LOADING:
      return {
        ...state,
        dealerDeliveryEnquiriesLoading: action.payload,
      };

    case types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_LOADING:
      return {
        ...state,
        dealerCallbackEnquiriesLoading: action.payload,
      };

    case types.TOGGLE_DEALER_PHONE_ENQUIRIES_LOADING:
      return {
        ...state,
        dealerPhoneEnquiriesLoading: action.payload,
      };

    case types.TOGGLE_DEALER_EMAIL_ENQUIRIES_FOR_CHART_LOADING:
      return {
        ...state,
        dealerEmailEnquiriesForChartLoading: action.payload,
      };

    case types.TOGGLE_DEALER_PHONE_ENQUIRIES_FOR_CHART_LOADING:
      return {
        ...state,
        dealerPhoneEnquiriesForChartLoading: action.payload,
      };

    case types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_LOADING:
      return {
        ...state,
        dealerCallbackEnquiriesForChartLoading: action.payload,
      };

    case types.GET_FAVORITE_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        favItems: action.payload.results,
        favItemsCount: action.payload.count,
        error: null
      }

    case types.GET_FAVORITE_ITEMS_IDS_REQUEST_SUCCESS:
      return {
        ...state,
        favItemsIds: action.payload.ids,
        favItemsIdsCount: action.payload.count,
        error: null
      }

    case types.GET_FAVORITE_DEALERS_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        favDealers: action.payload.results,
        favDealersCount: action.payload.count,
        error: null
      }

    case types.GET_FAVORITE_FAIRS_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        favFairs: action.payload.results,
        favFairsCount: action.payload.count,
        error: null
      }

    case types.GET_FAVORITE_DIRECTORIES_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        favDirectories: action.payload.results,
        favDirectoriesCount: action.payload.count,
        error: null
      }

    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        successModal: {
          isVisible: true,
          text: "Password change request successful."
        }
      };

    case types.UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_SUCCESS:
      return {
        ...state,
        error: null,
        newImageForAuthorizedUser: action.payload,
      };

    case types.CREATE_ITEM_SUCCESS:
    case types.EDIT_ITEM_SUCCESS:
    case types.ADD_FAVORITE_DEALER_SUCCESS:
    case types.REMOVE_FAVORITE_DEALER_SUCCESS:
    case types.ADD_FAVORITE_ITEM_SUCCESS:
    case types.REMOVE_FAVORITE_ITEM_SUCCESS:
    case types.FORGET_PASSWORD_REQUEST_SUCCESS:
    case types.RESET_PASSWORD_REQUEST_SUCCESS:
    case types.REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_SUCCESS:
    case types.DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS:
    case types.PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS:
    case types.MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_SUCCESS:
    case types.MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_SUCCESS:
    case types.MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_SUCCESS:
    case types.CREATE_NEW_EMAIL_PREFERENCE_REQUEST_SUCCESS:
    case types.DELETE_EMAIL_PREFERENCE_REQUEST_SUCCESS:
    case types.PATCH_EMAIL_PREFERENCE_REQUEST_SUCCESS:
    case types.DELETE_ITEM_SUCCESS:
    case types.CREATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.ROTATE_ANTIQUE_IMAGE_ERROR:
    case types.GET_CURRENCIES_ERROR:
    case types.CREATE_ITEM_ERROR:
    case types.EDIT_ITEM_ERROR:
    case types.SEARCH_ARTISAN_ERROR:
    case types.SET_ANTIQUE_IMAGE_ERROR:
    case types.DELETE_ANTIQUE_IMAGE_ERROR:
    case types.GET_DEALER_SPECIALITIES_ERROR:
    case types.ADD_DEALER_SPECIALITY_ERROR:
    case types.REMOVE_DEALER_SPECIALITY_ERROR:
    case types.GET_CURRENT_DEALER_STOCK_IMAGES_ERROR:
    case types.DELETE_CURRENT_DEALER_STOCK_IMAGE_ERROR:
    case types.CREATE_CURRENT_DEALER_STOCK_IMAGE_ERROR:
    case types.EDIT_CURRENT_DEALER_IMAGES_ERROR:
    case types.GET_CURRENT_DEALER_IMAGES_ERROR:
    case types.EDIT_CURRENT_DEALER_ERROR:
    case types.GET_CURRENT_DEALER_ERROR:
    case types.ADD_FAVORITE_DEALER_ERROR:
    case types.REMOVE_FAVORITE_DEALER_ERROR:
    case types.ADD_FAVORITE_ITEM_ERROR:
    case types.REMOVE_FAVORITE_ITEM_ERROR:
    case types.FORGET_PASSWORD_REQUEST_ERROR:
    case types.RESET_PASSWORD_REQUEST_ERROR:
    case types.UPDATE_PASSWORD_FAILED:
    case types.REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_ERROR:
    case types.GET_CURRENT_DEALER_ADDRESSES_REQUEST_ERROR:
    case types.CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_ERROR:
    case types.DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR:
    case types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_ERROR:
    case types.GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_ERROR:
    case types.GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_ERROR:
    case types.PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR:
    case types.MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_ERROR:
    case types.MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_ERROR:
    case types.MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_ERROR:
    case types.UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_ERROR:
    case types.GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_ERROR:
    case types.GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_ERROR:
    case types.GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_ERROR:
    case types.GET_ALL_EMAIL_PREFERENCES_REQUEST_ERROR:
    case types.CREATE_NEW_EMAIL_PREFERENCE_REQUEST_ERROR:
    case types.DELETE_EMAIL_PREFERENCE_REQUEST_ERROR:
    case types.PATCH_EMAIL_PREFERENCE_REQUEST_ERROR:
    case types.GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_ERROR:
    case types.DELETE_ITEM_ERROR:
    case types.GET_DEALER_INVOICES_REQUEST_ERROR:
    case types.CREATE_DEFAULT_TEXT_REQUEST_ERROR:
    case types.GET_ALL_DEFAULT_TEXTS_REQUEST_ERROR:
    case types.GET_SINGLE_DEALER_TEXT_REQUEST_ERROR:
    case types.PATCH_DEFAULT_TEXT_REQUEST_ERROR:
    case types.CREATE_DEALER_WEBSITE_REQUEST_ERROR:
    case types.UPDATE_DEALER_WEBSITE_REQUEST_ERROR:
    case types.GET_DEALER_WEBSITE_REQUEST_ERROR:
    case types.CREATE_DEALER_WEBSITE_INFO_REQUEST_ERROR:
    case types.UPDATE_DEALER_WEBSITE_INFO_REQUEST_ERROR:
    case types.GET_DEALER_WEBSITE_INFO_REQUEST_ERROR:
    case types.CREATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR:
    case types.UPDATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR:
    case types.GET_DEALER_WEBSITE_PAGE_REQUEST_ERROR:
    case types.DELETE_DEALER_WEBSITE_PAGE_REQUEST_ERROR:
    case types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_ERROR:
    case types.GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_ERROR:
    case types.GET_SUGGESTED_CATEGORIES_REQUEST_ERROR:
    case types.GET_HOLIDAY_MODE_DETAILS_REQUEST_ERROR:
    case types.MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_ERROR:
    case types.GET_TRADE_ASSOCIATIONS_REQUEST_ERROR:
    case types.GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR:
    case types.GET_ONLINE_SALES_STATISTICS_REQUEST_ERROR:
    case types.GET_CURRENT_DEALER_DELIVERY_FOR_CHART_ERROR:
    case types.GET_CURRENT_DEALER_DELIVERY_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case types.UPDATE_DEALER_WEBSITE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      }

    case types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerEmailEnquiries: action.payload.results,
        dealerEmailEnquiriesCount: action.payload.count,
        unreadEmailEnquiriesCount: action.payload.unreadEnquiryCount
      };

    case types.GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerPhoneEnquiries: action.payload.results,
        dealerPhoneEnquiriesCount: action.payload.count
      };

    case types.GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerCallbackEnquiries: action.payload.results,
        dealerCallbackEnquiriesCount: action.payload.count,
        unreadCallbackEnquiriesCount: action.payload.unreadEnquiryCount,
      };

    case types.GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS:
      return {
        ...state,
        dealerEmailEnquiriesForChartCount: action.payload.laCount,
        dealerEmailEnquiriesCountPagination: action.payload.count,
        dealerWebsiteEmailEnquiriesCount: action.payload.siteCount,
        websiteEnquiriesCount: state.dealerWebsitePhoneEnquiriesCount + action.payload.siteCount,
      };

    case types.GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS:
      return {
        ...state,
        dealerPhoneEnquiriesForChartCount: action.payload.laCount + (action.payload.siteCount || 0),
        dealerPhoneEnquiriesCountPagination: action.payload.count,
        dealerWebsitePhoneEnquiriesCount: action.payload.siteCount,
        websiteEnquiriesCount: state.dealerWebsiteEmailEnquiriesCount + action.payload.siteCount,
      };

    case types.GET_CURRENT_DEALER_DELIVERY_REQUEST_SUCCESS:
      return {
        ...state,
        dealerDeliveryEnquiries: action.payload.results,
        dealerDeliverEnquiriesCount: action.payload.count,
        unreadDeliveryEnquiriesCount: action.payload.unreadEnquiryCount,
      };

    case types.GET_CURRENT_DEALER_DELIVERY_FOR_CHART_SUCCESS:
      return {
        ...state,
        unreadDeliveryEnquiriesCount: action.payload.unreadEnquiryCount,
        dealerDeliveryEnquiriesForChartCount: action.payload.count,
      };

    case types.GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS:
      return {
        ...state,
        dealerCallbackEnquiriesForChartCount: action.payload.count,
      };

    case types.EDIT_CURRENT_DEALER_SUCCESS:
    case types.EDIT_CURRENT_DEALER_IMAGES_SUCCESS:
    case types.GET_CURRENT_DEALER_SUCCESS:
    case types.GET_CURRENT_DEALER_IMAGES_SUCCESS:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          ...action.payload
        }
      };

    case types.GET_DEALER_SPECIALITIES_SUCCESS:
    case types.ADD_DEALER_SPECIALITY_SUCCESS:
    case types.REMOVE_DEALER_SPECIALITY_SUCCESS:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          allSpecialities: action.payload
        }
      };

    case types.GET_CURRENT_DEALER_ADDRESSES_REQUEST_SUCCESS:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          addresses: action.payload.results,
          addressesCount: action.payload.count,
        },
        error: null
      };

    case types.GET_CURRENT_DEALER_STOCK_IMAGES_SUCCESS:
    case types.DELETE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS:
    case types.CREATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS:
    case types.UPDATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          stockImages: action.payload
        }
      };
    case types.MAKE_MAIN_PHOTO_FOR_ANTIQUE:
    case types.REMOVE_MAIN_PHOTO_FOR_ANTIQUE:
    case types.ROTATE_ANTIQUE_IMAGE_SUCCESS:
    case types.SET_ANTIQUE_IMAGE_SUCCESS:
    case types.DELETE_ANTIQUE_IMAGE_SUCCESS:
      return {
        ...state,
        selectedAntiqueItem: {
          ...state.selectedAntiqueItem,
          images: action.payload
        }
      };
    case types.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        selectedAntiqueItem: {
          ...state.selectedAntiqueItem,
          currencies: action.payload
        }
      };
    case types.CLEAR_SELECTED_ANTIQUE:
      return {
        ...state,
        error: null,
        selectedAntiqueItem: {
          ...initialState.selectedAntiqueItem
        }
      };
    case types.SEARCH_ARTISAN_SUCCESS:
      return {
        ...state,
        selectedAntiqueItem: {
          ...state.selectedAntiqueItem,
          artisans: action.payload
        }
      };
    case types.CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.CLOSE_SUCCESS_MODAL:
      return {
        ...state,
        error: null,
        successModal: {
          isVisible: false,
          text: "",
        }
      };

    case types.GET_ALL_EMAIL_PREFERENCES_REQUEST_SUCCESS:
      return {
        ...state,
        allEmailPreferences: action.payload.results,
        allEmailPreferencesCount: action.payload.count,
      };

    case types.SEARCH_EMAIL_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerEmailEnquiries: action.payload.results,
      };

    case types.SEARCH_PHONE_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerPhoneEnquiries: action.payload.results,
      };

    case types.SEARCH_CALLBACK_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerCallbackEnquiries: action.payload.results,
      };

    case types.SEARCH_DELIVERY_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerDeliveryEnquiries: action.payload.results,
      };

    case types.SEARCH_EMAIL_ENQUIRIES_REQUEST_ERROR:
    case types.SEARCH_PHONE_ENQUIRIES_REQUEST_ERROR:
    case types.SEARCH_CALLBACK_ENQUIRIES_REQUEST_ERROR:
    case types.SEARCH_DELIVERY_ENQUIRIES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case types.TOGGLE_EMAIL_PREFERENCES_LOADING:
      return {
        ...state,
        emailPreferencesLoading: action.payload,
      };

    case types.TOGGLE_EMAIL_PREFERENCES_PROCESSING:
      return {
        ...state,
        emailPreferencesProcessing: action.payload,
      };

    case types.GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_SUCCESS:
      return {
        ...state,
        emailPreferencesSearchItems: action.payload.results,
        emailPreferencesSearchItemsCount: action.payload.count,
      };

    case types.GET_DEALER_INVOICES_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        dealerInvoices: action.payload.results,
        dealerInvoicesCount: action.payload.count,
      };

    case types.TOGGLE_DEALER_INVOICES_LOADING:
      return {
        ...state,
        dealerInvoicesLoading: action.payload,
      };

    case types.TOGGLE_DEALER_INVOICES_PROCESSING:
      return {
        ...state,
        dealerInvoicesProcessing: action.payload,
      };

    case types.GET_ALL_DEFAULT_TEXTS_REQUEST_SUCCESS:
      return {
        ...state,
        dealerDefaultTexts: action.payload.results,
        dealerDefaultTextCount: action.payload.count,
      }

    case types.GET_SINGLE_DEALER_TEXT_REQUEST_SUCCESS:
      return {
        ...state,
        selectedDefaultText: action.payload,
      }

    case types.CLEAR_SELECTED_DEFAULT_TEXT:
      return {
        ...state,
        selectedDefaultText: {},
      };

    case types.SET_NEW_IMAGES_ORDER:
      return {
        ...state,
        selectedAntiqueItem: {
          ...state.selectedAntiqueItem,
          images: action.payload
        }
      };

    case types.CREATE_DEALER_WEBSITE_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          template: action.payload || { siteImages: [] }
        },
      };

    case types.GET_DEALER_WEBSITE_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          template: action.payload || { siteImages: [] }
        },
      };

    case types.GET_DEALER_WEBSITE_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          info: action.payload || state.website.info
        },
      };

    case types.GET_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          singlePage: action.payload || state.website.singlePage
        },
      };

    case types.UPDATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        website: {
          ...state.website,
          pages: state.website.pages.map(page => page.id === action.payload.id ? action.payload : page)
        },
      };

    case types.UPDATE_DEALER_WEBSITE_BLOG_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          blogs: state.website.blogs.map(page => page.id === action.payload.id ? action.payload : page)
        },
      };

    case types.CLEAR_DEALER_WEBSITE_PAGE:
      return {
        ...state,
        website: {
          ...state.website,
          singlePage: {}
        },
      };

    case types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          pages: action.payload || state.website.pages
        },
      };

    case types.GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          blogs: action.payload || state.website.blogs
        },
      };

    case types.GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        websiteSubscriptionsCount: action.payload.count || 0,
        website: {
          ...state.website,
          subscriptions: action.payload.results || state.website.subscriptions
        },
      };

    case types.DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          subscriptions: state.website.subscriptions.filter(subscription => {
            return subscription.id !== action.payload.id
          })
        },
      };

    case types.UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          subscriptions: state.website.subscriptions.map(subscription => {
            return subscription.id === action.payload.id ? action.payload : subscription
          })
        },
      };

    case types.CLEAR_DATA_ERROR:
      return {
        ...state,
        error: null
      };

    case types.SHOW_MARKETPLACES_LOADING:
      return {
        ...state,
        website: {
          ...state.website,
          marketplaces: {
            ...state.website.marketplaces,
            marketplacesProcessing: true
          }
        }
      }

    case types.HIDE_MARKETPLACES_LOADING:
      return {
        ...state,
        website: {
          ...state.website,
          marketplaces: {
            ...state.website.marketplaces,
            marketplacesProcessing: false
          }
        }
      }

    case types.GET_MARKETPLACES_REQUEST_ERROR:
    case types.CONNECT_MARKETPLACE_REQUEST_ERROR:
      return {
        ...state,
        website: {
          ...state.website,
          marketplaces: {
            ...state.website.marketplaces,
            marketplacesError: action.payload
          }
        }
      }

    case types.GET_MARKETPLACES_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          marketplaces: {
            ...state.website.marketplaces,
            totalMarketplaces: action.payload.count,
            marketplaces: action.payload.results,
            marketplacesError: null
          }
        }
      }

    case types.UNPUBLISH_DEALER_WEBSITE_REQUEST_ERROR:
    case types.PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_ERROR:
    case types.PUBLISH_DEALER_WEBSITE_REQUEST_ERROR:
      return {
        ...state,
        website: {
          ...state.website,
          template: {
            ...state.website.template,
            templateError: action.payload
          }
        }
      }

    case types.PUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS:
    case types.UNPUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS:
    case types.PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_SUCCESS:
      return {
        ...state,
        website: {
          ...state.website,
          template: {
            ...state.website.template,
            templateError: null,
            status: action.payload
          }
        }
      }

    case types.SHOW_IMAGES_LOADING:
      return {
        ...state,
        imagesLoading: true
      }

    case types.HIDE_IMAGES_LOADING:
      return {
        ...state,
        imagesLoading: false
      }

    case types.GET_SUGGESTED_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        selectedAntiqueItem: {
          ...state.selectedAntiqueItem,
          suggestedCategories: action.payload
        }
      }

    case types.GET_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS:
    case types.MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        holidayMode: action.payload
      }

    case types.GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        tradeAssociations: action.payload
      }
    case types.CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR:
      return {
        ...state,
        tradeAssociationsError: action.payload
      }

    case types.GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        dealerTradeAssociations: action.payload
      }

    case types.CLEAR_DEALER_TRADE_ASSOCIATIONS:
      return {
        ...state,
        tradeAssociationsError: null,
        dealerTradeAssociations: initialState.dealerTradeAssociations
      }

    case types.GET_ONLINE_SALES_STATISTICS_REQUEST_SUCCESS:
      return {
        ...state,
        onlineSalesStatistics: action.payload
      }
    default:
      return state;
  }
};

export default profiles;

import * as types from "./notificationsActionsTypes";

export const getAllNotificationsRequest = () => {
  return { type: types.NOTIFICATIONS_GET_ALL_REQUEST };
};

export const getAllNotificationsRequestError = (payload) => {
  return { type: types.NOTIFICATIONS_GET_ALL_REQUEST_ERROR, payload };
};

export const getAllNotificationsRequestSuccess = (payload) => {
  return { type: types.NOTIFICATIONS_GET_ALL_REQUEST_SUCCESS, payload };
};

export const markNotificationAsReadRequest = (payload) => {
  return { type: types.NOTIFICATION_MARK_AS_READ_REQUEST, payload };
}

export const markNotificationAsReadRequestError = (payload) => {
  return { type: types.NOTIFICATION_MARK_AS_READ_REQUEST_ERROR, payload };
}

export const markNotificationAsReadRequestSuccess = (payload) => {
  return { type: types.NOTIFICATION_MARK_AS_READ_REQUEST_SUCCESS, payload };
}

export const markNotificationAsUnreadRequest = (payload) => {
  return { type: types.NOTIFICATION_MARK_AS_UNREAD_REQUEST, payload };
}

export const markNotificationAsUnreadRequestError = (payload) => {
  return { type: types.NOTIFICATION_MARK_AS_UNREAD_REQUEST_ERROR, payload };
}

export const markNotificationAsUnreadRequestSuccess = (payload) => {
  return { type: types.NOTIFICATION_MARK_AS_UNREAD_REQUEST_SUCCESS, payload };
}

export const deleteNotificationRequest = (payload) => {
  return { type: types.NOTIFICATION_DELETE_REQUEST, payload };
}

export const deleteNotificationRequestError = (payload) => {
  return { type: types.NOTIFICATION_DELETE_REQUEST_ERROR, payload };
}

export const deleteNotificationRequestSuccess = (payload) => {
  return { type: types.NOTIFICATION_DELETE_REQUEST_SUCCESS, payload };
}

import React, { useEffect, useState } from "react";
import { Button, message, Modal, Popconfirm } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// components
import CheckMarkCircle from "../Icons/CheckMarkInCircle";

// helpers
import profilesAPI from "../../api/profiles";

// redux
import { toggleAdvertiseWBModalAction } from "../../redux/auth/authActions";

// constants
import { MESSAGE_TRY_AGAIN } from "../../constants";

// styles
import "./AdvertiseWBModal.scss";

const MODALS = [
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-1.png`,
    title: "Dealer websites now available from LoveAntiques.com",
    text:
      "Try our exclusive Website Builder feature. We have multiple customisable templates for you to choose " +
      "from, with features including a text logo creation tool, a fonts library, a gallery and " +
      "lots more to help your website stand out.",
    button: "Try It Out",
  },
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-2.png`,
    title:
      "Upload stock to your Dealer website and LoveAntiques.com simultaneously",
    text:
      "Save yourself time and effort by sharing your stock to your website and LoveAntiques.com with the click " +
      "of a button. As well as this, you can connect your stock to various other platforms with ease, " +
      "using our marketplace connections feature.",
    button: "Open Website Builder",
  },
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-3.png`,
    title: "Multiple Dealer website templates to choose from",
    text: "LoveAntiques.com offers free design and website builds with many templates and palettes to choose from.",
    button: "Create Your Website",
  },
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-4.png`,
    title: "Search Engine Optimised websites from LoveAntiques.com",
    text:
      "Give your business the best chance of ranking in search consoles; LoveAntiques.com fully optimises " +
      "its dealer websites generating more traffic for your business.",
    button: "Create Your Website",
  },
];

const AdvertiseWBModal = ({
  authorizedUserProfile,
  isAdvertiseWBModalOpened,
  toggleAdvertiseWBModal,
}) => {
  const modalIndex = Math.floor(Math.random() * 4);
  const MODAL_PROPS = MODALS[modalIndex];

  const closeAdvertiseWBModal = () => {
    sessionStorage.removeItem("advertiseWebsiteBuilder");
    toggleAdvertiseWBModal(false);
  };

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);

  useEffect(() => {
    if (
      authorizedUserProfile?.id &&
      sessionStorage.getItem("advertiseWebsiteBuilder") === "true"
    ) {
      toggleAdvertiseWBModal(true);
    }
  }, [authorizedUserProfile]);

  useEffect(() => {
    return () => closeAdvertiseWBModal();
  }, []);

  const requestAccess = () => {
    profilesAPI
      .requestDealerSiteBuilderAccess()
      .then(() => {
        closeAdvertiseWBModal();
        setIsSuccessModalOpened(true);
      })
      .catch(() => message.error(MESSAGE_TRY_AGAIN))
      .finally(closeAdvertiseWBModal);
  };

  return (
    <>
      <Modal
        open={isAdvertiseWBModalOpened}
        centered
        okText={MODAL_PROPS.button}
        onCancel={closeAdvertiseWBModal}
        footer={null}
        width={640}
        className="advertise-modal"
      >
        <img
          src={MODAL_PROPS.image}
          className="advertise-modal--image"
          alt=""
        />
        <div className="advertise-modal--content">
          <div className="advertise-modal--title">{MODAL_PROPS.title}</div>
          <div className="advertise-modal--text">{MODAL_PROPS.text}</div>
          <div className="flex justify-between">
            <Button
              size="large"
              className="ant-btn-default advertise-modal--btn header--auth-button--logout"
              onClick={closeAdvertiseWBModal}
            >
              Close
            </Button>
            <Popconfirm
              placement="top"
              title="Are you sure you want to send request for website builder?"
              okText="Yes"
              cancelText="No"
              onConfirm={requestAccess}
            >
              <Button size="large" className="submit-btn advertise-modal--btn">
                {MODAL_PROPS.button}
              </Button>
            </Popconfirm>
          </div>
        </div>
      </Modal>

      <Modal
        open={isSuccessModalOpened}
        wrapClassName="advertise-modal--success"
        centered
        onCancel={() => setIsSuccessModalOpened(false)}
        footer={null}
      >
        <div
          className="callback-modal--body"
          data-testid="lant-ask-question-modal-form"
        >
          <div className="callback-modal--body-header">
            <img
              src={`${process.env.REACT_APP_IMAGES_URL}loginLALogo.svg`}
              alt="LoveAntiques"
              title="LoveAntiques"
            />
          </div>
          <div className="callback-modal--body-main">
            <h2 className="text-center h2">
              <CheckMarkCircle />
              <br />
              Thank you for your interest
              <br />
              in Website builder.
            </h2>
            <div className="w-100-percent mt-6 text-center fw-600">
              Your website creation feature will be enabled soon.
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

AdvertiseWBModal.propTypes = {
  isAdvertiseWBModalOpened: PropTypes.bool,
  toggleAdvertiseWBModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    authorizedUserProfile: state.auth.authorizedUserProfile,
    isAdvertiseWBModalOpened: state.auth.isAdvertiseWBModalOpened,
  }),
  {
    toggleAdvertiseWBModal: toggleAdvertiseWBModalAction,
  }
)(AdvertiseWBModal);

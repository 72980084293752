// helpers
import { objectToQueryString } from "../helpers";
import fetch from "./customFetch";

const SHOW_IN_LANT = "LOVE_ANTIQUES,BOTH";
const SHOW_IN_WB = "DEALER_WEBSITE,BOTH";

const createSearchUrl = (
  { status, fromDate, toDate, platform, byRef, pageSize, ...rest },
  searchQuery
) => {
  const queryParams = {
    ...rest,
    from_date: fromDate,
    to_date: toDate,
    by_ref: byRef,
    query: searchQuery,
    page_size: pageSize,
  };

  if (platform === "all") {
    queryParams.la_status = status;
    queryParams.wb_status = status;
  } else if (platform === "la") {
    queryParams.la_status = status;
    queryParams.show_in = SHOW_IN_LANT;
  } else if (["ebay", "instagram", "facebook"].includes(platform)) {
    queryParams.show_in = "BOTH";
    queryParams.marketplace = platform;
  } else {
    queryParams.wb_status = status;
    queryParams.show_in = SHOW_IN_WB;
  }

  return objectToQueryString(queryParams);
};

const buildQuery = (args = {}) => {
  const {
    page = 1,
    pageSize = 10,
    dealer,
    ordering,
    priceRange,
    artisan,
    showInHomepage,
    forImpressions = false,
    status,
    fromDate,
    toDate,
    catalogElement,
    ...rest
  } = args;

  const params = {
    page,
    dealer,
    page_size: pageSize,
    price_min: priceRange && priceRange.length === 2 ? priceRange[0] : null,
    price_max: priceRange && priceRange.length === 2 ? priceRange[1] : null,
    is_sold: !artisan || dealer ? `${ordering === "is_sold"}` : null,
    show_in_homepage: !!showInHomepage,
    ordering,
    for_impressions: forImpressions,
    item_status: status,
    from_date: fromDate,
    to_date: toDate,
    catalog_element: catalogElement,
    ...rest,
  };

  if (artisan && !ordering) {
    params.ordering = "is_sold,make_this_price_poa";
  }

  return objectToQueryString(params);
};

const items = {
  async get(params) {
    const query = buildQuery({ ...params, show_in: SHOW_IN_LANT });

    try {
      return await fetch({
        url: `/storefront/items/?${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerItems(params) {
    const query = buildQuery({ ...params, show_in: SHOW_IN_WB });

    try {
      return await fetch({
        url: `/storefront/items/non-cached/?${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getItemForPrivateRoutes(args = {}) {
    const {
      page = 1,
      pageSize = 10,
      status,
      fromDate,
      toDate,
      priceRange,
      catalogElement,
      platform,
      ...rest
    } = args;

    const params = {
      page,
      page_size: pageSize,
      from_date: fromDate,
      to_date: toDate,
      price_min: priceRange && priceRange.length === 2 ? priceRange[0] : null,
      price_max: priceRange && priceRange.length === 2 ? priceRange[1] : null,
      catalog_element: catalogElement,
    };

    if (platform === "all") {
      params.status = status;
    } else if (platform === "la") {
      params.la_status = status;
      params.show_in = SHOW_IN_LANT;
    } else {
      params.wb_status = status;
      params.show_in = SHOW_IN_WB;
    }

    const query = objectToQueryString({ ...rest, ...params });
    try {
      return await fetch({
        url: `/profiles/dealer/items/?${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getItemDataById(id) {
    try {
      return await fetch({
        url: `/storefront/items/id/${id}/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getItemData(url) {
    try {
      return await fetch({
        url: `/storefront/items/${url}/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getItemsCSV({ status }) {
    const query = objectToQueryString({ status });
    try {
      return await fetch({
        url: `/profiles/dealer/items/download-items-csv/?${query}`,
        method: "GET",
        responseType: "blob",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getItemsPickUpSettings({ dealerId }) {
    try {
      return await fetch({
        url: `/profiles/dealer/pick-up-default-settings/${dealerId}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async patchItemsPickUpSettings({ dealerId, data }) {
    try {
      return await fetch({
        url: `/profiles/dealer/pick-up-default-settings/${dealerId}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async applyItemsPickUpSettings({ dealerId }) {
    try {
      return await fetch({
        url: `/profiles/dealer/pick-up-default-settings/${dealerId}/apply/`,
        method: "POST",
        data: {},
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getItemDataForPrivateRoutes(ref) {
    try {
      return await fetch({
        url: `/items/${ref}/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async requestCallback(ref, data) {
    try {
      return await fetch({
        url: `/items/${ref}/request-callback/`,
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async callADealer(data) {
    try {
      return await fetch({
        url: `/items/${data.itemRef}/dealer-call/`,
        method: "POST",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchAntiques(args = {}, searchQuery = null) {
    const query = createSearchUrl(args, searchQuery);
    const url = `/profiles/dealer/items/${
      searchQuery ? "search/" : ""
    }?${query.toString()}`;

    try {
      return await fetch({
        url,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async checkIfItemExist(data) {
    try {
      return await fetch({
        url: "/items/item-exists/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async requestDeliveryQuote({ url, data }) {
    try {
      return await fetch({
        url: `/storefront/items/${url}/request-delivery-quote/`,
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async toggleBulkUkFreeDelivery({ data, params = {} }) {
    const query = new URLSearchParams();
    const { for_all } = params;

    if (for_all) {
      query.set("for_all", "true");
    }

    try {
      return await fetch({
        url: `/profiles/dealer/items/toggle-selected-uk-free-delivery/?${query.toString()}`,
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async itemsBulkUpdate({ data, params = {}, search = null }) {
    const query = createSearchUrl(params, search);

    try {
      return await fetch({
        url: `/profiles/dealer/items/bulk-update/?${query.toString()}`,
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async postDeliveryOptionLogs(data) {
    try {
      return await fetch({
        url: "/settings/delivery-option-logs/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default items;

export const GET_MOST_POPULAR_MATERIALS_REQUEST = "GET_MOST_POPULAR_MATERIALS_REQUEST";
export const GET_MOST_POPULAR_MATERIALS_REQUEST_ERROR = "GET_MOST_POPULAR_MATERIALS_REQUEST_ERROR";
export const GET_MOST_POPULAR_MATERIALS_REQUEST_SUCCESS = "GET_MOST_POPULAR_MATERIALS_REQUEST_SUCCESS";

export const GET_LIST_OF_MATERIALS_REQUEST = "GET_LIST_OF_MATERIALS_REQUEST";
export const GET_LIST_OF_MATERIALS_REQUEST_ERROR = "GET_LIST_OF_MATERIALS_REQUEST_ERROR";
export const GET_LIST_OF_MATERIALS_REQUEST_SUCCESS = "GET_LIST_OF_MATERIALS_REQUEST_SUCCESS";

export const SHOW_LIST_OF_MATERIALS_REQUEST_LOADING = "SHOW_LIST_OF_MATERIALS_REQUEST_LOADING";
export const HIDE_LIST_OF_MATERIALS_REQUEST_LOADING = "HIDE_LIST_OF_MATERIALS_REQUEST_LOADING";

export const GET_MOST_POPULAR_ORIGINS_REQUEST = "GET_MOST_POPULAR_ORIGINS_REQUEST";
export const GET_MOST_POPULAR_ORIGINS_REQUEST_ERROR = "GET_MOST_POPULAR_ORIGINS_REQUEST_ERROR";
export const GET_MOST_POPULAR_ORIGINS_REQUEST_SUCCESS = "GET_MOST_POPULAR_ORIGINS_REQUEST_SUCCESS";

export const GET_LIST_OF_ORIGINS_REQUEST = "GET_LIST_OF_ORIGINS_REQUEST";
export const GET_LIST_OF_ORIGINS_REQUEST_ERROR = "GET_LIST_OF_ORIGINS_REQUEST_ERROR";
export const GET_LIST_OF_ORIGINS_REQUEST_SUCCESS = "GET_LIST_OF_ORIGINS_REQUEST_SUCCESS";

export const SEARCH_MATERIALS_REQUEST = "SEARCH_MATERIALS_REQUEST";
export const SEARCH_MATERIALS_REQUEST_ERROR = "SEARCH_MATERIALS_REQUEST_ERROR";
export const SEARCH_MATERIALS_REQUEST_SUCCESS = "SEARCH_MATERIALS_REQUEST_SUCCESS";

export const SHOW_MATERIALS_SEARCH_LOADING = "SHOW_MATERIALS_SEARCH_LOADING";
export const HIDE_MATERIALS_SEARCH_LOADING = "HIDE_MATERIALS_SEARCH_LOADING";

export const SEARCH_ORIGINS_REQUEST = "SEARCH_ORIGINS_REQUEST";
export const SEARCH_ORIGINS_REQUEST_ERROR = "SEARCH_ORIGINS_REQUEST_ERROR";
export const SEARCH_ORIGINS_REQUEST_SUCCESS = "SEARCH_ORIGINS_REQUEST_SUCCESS";

export const SHOW_ORIGINS_SEARCH_LOADING = "SHOW_ORIGINS_SEARCH_LOADING";
export const HIDE_ORIGINS_SEARCH_LOADING = "HIDE_ORIGINS_SEARCH_LOADING";

export const GET_ORIGIN_ITEMS_REQUEST = "GET_ORIGIN_ITEMS_REQUEST";
export const GET_ORIGIN_ITEMS_REQUEST_ERROR = "GET_ORIGIN_ITEMS_REQUEST_ERROR";
export const GET_ORIGIN_ITEMS_REQUEST_SUCCESS = "GET_ORIGIN_ITEMS_REQUEST_SUCCESS";

export const SHOW_ORIGIN_ITEMS_LOADING = "SHOW_ORIGIN_ITEMS_LOADING";
export const HIDE_ORIGIN_ITEMS_LOADING = "HIDE_ORIGIN_ITEMS_LOADING";

export const GET_ORIGIN_DATA_REQUEST = "GET_ORIGIN_DATA_REQUEST";
export const GET_ORIGIN_DATA_REQUEST_ERROR = "GET_ORIGIN_DATA_REQUEST_ERROR";
export const GET_ORIGIN_DATA_REQUEST_SUCCESS = "GET_ORIGIN_DATA_REQUEST_SUCCESS";

export const SHOW_ORIGIN_DATA_LOADING = "SHOW_ORIGIN_DATA_LOADING";
export const HIDE_ORIGIN_DATA_LOADING = "HIDE_ORIGIN_DATA_LOADING";

export const GET_MATERIAL_ITEMS_REQUEST = "GET_MATERIAL_ITEMS_REQUEST";
export const GET_MATERIAL_ITEMS_REQUEST_ERROR = "GET_MATERIAL_ITEMS_REQUEST_ERROR";
export const GET_MATERIAL_ITEMS_REQUEST_SUCCESS = "GET_MATERIAL_ITEMS_REQUEST_SUCCESS";

export const SHOW_MATERIAL_ITEMS_LOADING = "SHOW_MATERIAL_ITEMS_LOADING";
export const HIDE_MATERIAL_ITEMS_LOADING = "HIDE_MATERIAL_ITEMS_LOADING";

export const GET_MATERIAL_DATA_REQUEST = "GET_MATERIAL_DATA_REQUEST";
export const GET_MATERIAL_DATA_REQUEST_ERROR = "GET_MATERIAL_DATA_REQUEST_ERROR";
export const GET_MATERIAL_DATA_REQUEST_SUCCESS = "GET_MATERIAL_DATA_REQUEST_SUCCESS";

export const SHOW_MATERIAL_DATA_LOADING = "SHOW_MATERIAL_DATA_LOADING";
export const HIDE_MATERIAL_DATA_LOADING = "HIDE_MATERIAL_DATA_LOADING";

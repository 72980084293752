// helpers
import { objectToQueryString } from "../helpers";
import fetch from "./customFetch";


const catalogs = {
  async getMaterials(page = 1, pageSize = 8) {
    const query = new URLSearchParams();

    if (page) {
      query.set("page", page);
    }
    if (pageSize) {
      query.set("page_size", pageSize);
    }

    try {
      return await fetch({
        url: `/catalogs/material/sf/elements/?${query.toString()}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getMostPopularMaterials() {
    try {
      return await fetch({
        url: "/catalogs/material/sf/elements/most-popular/",
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAllMaterialsList({ page = 1, pageSize = 30, ordering, title, startsWith }) {
    const query = objectToQueryString({ page, page_size: pageSize, ordering, title, starts_with: startsWith });

    try {
      return await fetch({
        url: `/catalogs/material/sf/elements/all/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getMostPopularOrigin() {
    try {
      return await fetch({
        url: "/catalogs/origin/sf/elements/most-popular/",
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAllOriginList({ page = 1, pageSize, ordering, title, startsWith }) {
    const query = objectToQueryString({ page, page_size: pageSize, ordering, title, starts_with: startsWith });

    try {
      return await fetch({
        url: `/catalogs/origin/sf/elements/all/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchMaterials({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("title", title);
    }

    try {
      return await fetch({
        url: `/catalogs/material/elements/autocomplete/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async searchOrigins({ title, page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (title) {
      query.set("title", title);
    }

    try {
      return await fetch({
        url: `/catalogs/origin/elements/autocomplete/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getMaterialData(url) {
    return fetch({
      url: `/catalogs/material/sf/elements/${url}/`,
      method: "GET"
    });
  },

  async getOriginData(url) {
    return fetch({
      url: `/catalogs/origin/sf/elements/${url}/`,
      method: "GET"
    });
  },

  async getCategorySidebarItems({ category, page = 1, pageSize = 10, catalog }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }
    if (category) {
      query.set("category", category);
    }

    try {
      return await fetch({
        url: `/catalogs/${catalog}/sf/elements/related/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCategoryMaterials(queryParams) {
    try {
      return fetch({
        url: `/catalogs/material/sf/elements/related/?${queryParams}`,
        method: "GET"
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCategoryOrigins(queryParams) {
    try {
      return fetch({
        url: `/catalogs/origin/sf/elements/related/?${queryParams}`,
        method: "GET"
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getSimilarSearches(queryParams) {
    try {
      return fetch({
        url: `/seo/urls/storefront/?${queryParams}`,
        method: "GET"
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  }
};

export default catalogs;

import { all, put, call, takeLatest } from "redux-saga/effects";

import auth from "../../api/auth";
import favorites from "../../api/favorites";
import * as types from "./authActionsTypes";
import { UPDATE_UNREGISTERED_FAVOURITES_ACTION } from "../items/itemsActionsTypes";
import { getUnregisteredFavourites, setUnregisteredFavourites } from "../../helpers";
import { FAV_ITEMS_BASE_GROUP_NAME } from "../../constants";

function* addFavouriteItemsFromLocalStorageToGroup() {
  try {
    const favourites = getUnregisteredFavourites();

    if (favourites?.length) {
      const groups = yield call(favorites.getFavoriteGroups, { page: 1, pageSize: 1, ordering: "created_at" });
      let group = null;

      if (groups?.results?.length && groups.results[0]?.id) {
        const firstGroup = groups.results[0];

        group = firstGroup.name === FAV_ITEMS_BASE_GROUP_NAME
          ? firstGroup
          : yield call(favorites.putFavoriteGroups, firstGroup.id, { name: FAV_ITEMS_BASE_GROUP_NAME })
      } else {
        group = yield call(favorites.postFavoriteGroups, { name: FAV_ITEMS_BASE_GROUP_NAME });
      }

      if (group && group.id) {
        favourites.reverse().forEach((item) => {
          favorites.addItemToFavoriteGroups(item.ref, { ids: [group.id] });
        });

        setUnregisteredFavourites([]);
        yield put({ type: UPDATE_UNREGISTERED_FAVOURITES_ACTION, payload: [] });
      }
    }
  } catch (e) {
    console.error(e)
  }
}

function *login ({ data, callback }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.login, data);

    /* Set new favourite item to the user */
    yield* addFavouriteItemsFromLocalStorageToGroup();

    yield put({ type: types.LOGIN_REQUEST_SUCCESS, response });

    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    const errorData = error.message ? error.message : error;
    yield put({ type: types.LOGIN_REQUEST_ERROR, error: errorData });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *logout () {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(auth.logout);
    yield put({ type: types.LOGOUT_SUCCESS });
  } catch (error) {
    yield put({ type: types.LOGOUT_FAILED, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *registerVisitor ({ data }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    yield call(auth.registerVisitor, data);
    yield put({ type: types.REGISTER_VISITOR_SUCCESS });
  } catch (error) {
    const errorData = error.data ? error.data : error;
    yield put({ type: types.REGISTER_VISITOR_ERROR, error: errorData });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *registerTrader ({ data }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    yield call(auth.registerTrader, data);
    yield put({ type: types.REGISTER_TRADER_REQUEST_SUCCESS });
  } catch (error) {
    const errorData = error.message ? error.message : error;
    yield put({ type: types.REGISTER_TRADER_REQUEST_ERROR, error: errorData });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *registerDealer ({ data, callback }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    yield call(auth.registerDealer, data);
    yield put({ type: types.REGISTER_DEALER_REQUEST_SUCCESS });

    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    const errorData = error.message ? error.message : error;
    yield put({ type: types.REGISTER_DEALER_REQUEST_ERROR, error: errorData });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *activateAccountSaga({ data }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(auth.activateAccount, data);

    /* Set new favourite item to the user */
    yield* addFavouriteItemsFromLocalStorageToGroup();

    yield put({ type: types.LOGIN_REQUEST_SUCCESS, response });
    yield put({ type: types.ACTIVATE_ACCOUNT_SUCCESS });
  } catch (error) {
    const errorData = error.message ? error.message : error.data;
    yield put({ type: types.ACTIVATE_ACCOUNT_ERROR, error: errorData });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *closeSmsNotificationModal({ payload }) {
  try {
    const response = yield call(auth.setSmsNotificationChecked, payload);
    yield put({ type: types.CLOSE_NOTIFICATION_MODAL_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.CLOSE_NOTIFICATION_MODAL_FAILED, payload: err });
  }
}

function * getGoogleCaptchaKeySaga() {
  try {
    const response = yield call(auth.getGoogleCaptchaKey);
    yield put({ type: types.GET_GOOGLE_CAPTCHA_KEY_SUCCESS, payload: response });
  } catch (err) {
    console.error(err);
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.LOGIN_REQUEST, login),
    yield takeLatest(types.LOGOUT_REQUEST, logout),
    yield takeLatest(types.REGISTER_VISITOR_REQUEST, registerVisitor),
    yield takeLatest(types.REGISTER_TRADER_REQUEST, registerTrader),
    yield takeLatest(types.REGISTER_DEALER_REQUEST, registerDealer),
    yield takeLatest(types.ACTIVATE_ACCOUNT_REQUEST, activateAccountSaga),
    yield takeLatest(types.CLOSE_NOTIFICATION_MODAL_REQUEST, closeSmsNotificationModal),
    yield takeLatest(types.GET_GOOGLE_CAPTCHA_KEY_REQUEST, getGoogleCaptchaKeySaga),
  ])
}

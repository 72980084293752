import * as types from "./periodsActionsTypes";

const initialState = {
  allPeriods: [],
  error: null,
  isMainLoading: false,
  isListLoading: false,
  periodsSearchResult: [],
  periodsSearchLoading: false,
  periodData: {},
  periodDataLoading: false,
  periodItems: [],
  periodItemsCount: 0,
  periodItemsLoading: false,
};

const periods = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_OF_PERIODS_REQUEST:
      return {
        ...state,
        isListLoading: true,
        error: null,
      };

    case types.GET_LIST_OF_PERIODS_REQUEST_SUCCESS:
      return {
        ...state,
        allPeriods: action.payload,
        isListLoading: false,
        error: null,
      };

    case types.GET_LIST_OF_PERIODS_REQUEST_ERROR:
    case types.SEARCH_PERIODS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        isMainLoading: false,
        isListLoading: false,
        periodsSearchLoading: false,
      };

    case types.SEARCH_PERIODS_REQUEST_SUCCESS:
      return {
        ...state,
        periodsSearchResult: action.payload.results,
        error: null,
      };

    case types.SHOW_SEARCH_LOADING:
      return {
        ...state,
        periodsSearchLoading: true,
      };

    case types.HIDE_SEARCH_LOADING:
      return {
        ...state,
        periodsSearchLoading: false,
      };

    case types.SHOW_PERIOD_DATA_LOADING:
      return {
        ...state,
        periodDataLoading: true,
      };

    case types.HIDE_PERIOD_DATA_LOADING:
      return {
        ...state,
        periodDataLoading: false,
      };

    case types.GET_PERIOD_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        periodData: action.payload,
        error: null
      };

    case types.GET_PERIOD_DATA_REQUEST_ERROR:
    case types.GET_PERIOD_ITEMS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_PERIOD_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        periodItems: action.payload.results,
        periodItemsCount: action.payload.count,
      };

    case types.SHOW_PERIOD_ITEMS_LOADING:
      return {
        ...state,
        periodItemsLoading: true,
      };

    case types.HIDE_PERIOD_ITEMS_LOADING:
      return {
        ...state,
        periodItemsLoading: false,
      };

    default:
      return state;
  }
};

export default periods;

import { useLocation, useHistory } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const queryParams = {};

  for (const [key, value] of params) {
    queryParams[key] = value;
  }

  const setQueryParams = (obj, shouldReplace = false) => {
    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        params.set(key, obj[key]);
      } else {
        params.delete(key);
      }
    });

    const newLocation = { ...location, search: `?${params.toString()}` };
    if (shouldReplace) {
      history.replace(newLocation);
    } else {
      history.push(newLocation)
    }
  }

  return [queryParams, setQueryParams];
}

export default useQueryParams;

export const GET_ITEM_DATA_REQUEST = "GET_ITEM_DATA_REQUEST";
export const GET_ITEM_DATA_REQUEST_ERROR = "GET_ITEM_DATA_REQUEST_ERROR";
export const GET_ITEM_DATA_REQUEST_SUCCESS = "GET_ITEM_DATA_REQUEST_SUCCESS";

export const GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST = "GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST";
export const GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_ERROR = "GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_ERROR";
export const GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_SUCCESS = "GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_SUCCESS";
export const CLEAR_ITEM_DATA_FOR_PRIVATE_ROUTES = "CLEAR_ITEM_DATA_FOR_PRIVATE_ROUTES";

export const GET_ALL_ITEMS_REQUEST = "GET_ALL_ITEMS_REQUEST";
export const GET_ALL_ITEMS_REQUEST_ERROR = "GET_ALL_ITEMS_REQUEST_ERROR";
export const GET_ALL_ITEMS_REQUEST_SUCCESS = "GET_ALL_ITEMS_REQUEST_SUCCESS";

export const GET_ALL_ITEMS_FOR_PRIVATE_ROUTES_REQUEST = "GET_ALL_ITEMS_FOR_PRIVATE_ROUTES_REQUEST";

export const SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST = "SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST";
export const SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_ERROR = "SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_ERROR";
export const SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_SUCCESS = "SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_SUCCESS";

export const SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST = "SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST";
export const SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_ERROR = "SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_ERROR";
export const SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_SUCCESS = "SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_SUCCESS";

export const TOGGLE_PROCESSING = "TOGGLE_ITEMS_PROCESSING";
export const OPEN_SUCCESS_MODAL = "OPEN_ITEMS_SUCCESS_MODAL";
export const CLOSE_SUCCESS_MODAL = "CLOSE_ITEMS_SUCCESS_MODAL";

export const SHOW_LOADING = "SHOW_ITEMS_LOADING";
export const HIDE_LOADING = "HIDE_ITEMS_LOADING";

export const CALLBACK_REQUEST = "CALLBACK_REQUEST";
export const CALLBACK_REQUEST_ERROR = "CALLBACK_REQUEST_ERROR";
export const CALLBACK_REQUEST_SUCCESS = "CALLBACK_REQUEST_SUCCESS";

export const CALL_A_DEALER_REQUEST = "CALL_A_DEALER_REQUEST";
export const CALL_A_DEALER_ERROR = "CALL_A_DEALER_ERROR";
export const CALL_A_DEALER_SUCCESS = "CALL_A_DEALER_SUCCESS";
export const CALL_A_DEALER_RESET = "CALL_A_DEALER_RESET";

export const CLEAR_ITEM_DATA = "CLEAR_ITEM_DATA";

export const RESET_CONTACT_FORM_STATUS = "RESET_CONTACT_FORM_STATUS";

export const EDIT_ITEM_STATUS_REQUEST = "EDIT_ITEM_STATUS_REQUEST";
export const EDIT_ITEM_STATUS_REQUEST_ERROR = "EDIT_ITEM_STATUS_REQUEST_ERROR";
export const EDIT_ITEM_STATUS_REQUEST_SUCCESS = "EDIT_ITEM_STATUS_REQUEST_SUCCESS";

export const SEARCH_ANTIQUES_REQUEST = "SEARCH_ANTIQUES_REQUEST";
export const SEARCH_ANTIQUES_REQUEST_SUCCESS = "SEARCH_ANTIQUES_REQUEST_SUCCESS";
export const SEARCH_ANTIQUES_REQUEST_ERROR = "SEARCH_ANTIQUES_REQUEST_ERROR";

export const UPDATE_UNREGISTERED_FAVOURITES_ACTION = "UPDATE_UNREGISTERED_FAVOURITES_ACTION";

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const OtherWorldFlagIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="3" width="30" height="26" rx="3" stroke="currentColor" strokeWidth="2" />
        <path d="M16 7C11.0376 7 7 11.0376 7 16C7 20.9624 11.0376 25 16 25C20.9624 25 25 20.9624 25 16C25 11.0376 20.9624 7 16 7ZM8.8 16C8.8 15.19 8.94063 14.4138 9.18813 13.6881L10.6 15.1L12.4 16.9V18.7L14.2 20.5L15.1 21.4V23.1381C11.5551 22.6926 8.8 19.6652 8.8 16ZM21.697 20.3853C21.1097 19.9127 20.2188 19.6 19.6 19.6V18.7C19.6 18.2226 19.4104 17.7648 19.0728 17.4272C18.7352 17.0896 18.2774 16.9 17.8 16.9H14.2V14.2C14.6774 14.2 15.1352 14.0104 15.4728 13.6728C15.8104 13.3352 16 12.8774 16 12.4V11.5H16.9C17.3774 11.5 17.8352 11.3104 18.1728 10.9728C18.5104 10.6352 18.7 10.1774 18.7 9.7V9.32987C21.3347 10.3997 23.2 12.985 23.2 16C23.1998 17.5881 22.671 19.1309 21.697 20.3853Z" fill="currentColor" />
      </svg>
    </span>
  );
};

OtherWorldFlagIcon.propTypes = {
  className: PropTypes.string,
};

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";


const TagManager = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined" && process.env.REACT_APP_USE_GOOGLE_SERVICES === "true") {
      const spaTagManagerScriptHandling = document.createElement("script");
      const facebookPixelScriptHandling = document.createElement("script");

      spaTagManagerScriptHandling.innerHTML = `
      window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          originalLocation: document.location.protocol + '//' +
                  document.location.hostname +
                  document.location.pathname +
                  document.location.search
        });`;

      facebookPixelScriptHandling.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${process.env.REACT_APP_FACEBOOK_PIXEL_ID}'); 
      fbq('track', 'PageView');
      `;

      document.head.appendChild(spaTagManagerScriptHandling);
      document.head.appendChild(facebookPixelScriptHandling);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const tagManagerIframe = document.createElement("iframe");
      tagManagerIframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-PKMKXLN";
      tagManagerIframe.height = "0";
      tagManagerIframe.width = "0";
      tagManagerIframe.style.display = "none";
      tagManagerIframe.style.visibility = "hidden";

      const tagManagerNoScript = document.createElement("noscript");
      tagManagerNoScript.appendChild(tagManagerIframe);
      tagManagerNoScript.id = "gtmNoScript";

      document.body.appendChild(tagManagerNoScript);
    }
  }, [pathname]);

  return <React.Fragment />
}

export default TagManager;

import * as types from "./notificationsActionsTypes";

const initialState = {
  error: null,
  isLoading: false,
  total: 0,
  next: null,
  previous: null,
  data: [],
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case types.NOTIFICATIONS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.NOTIFICATIONS_GET_ALL_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case types.NOTIFICATIONS_GET_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.count,
        data: action.payload.results,
      };

    case types.NOTIFICATION_MARK_AS_READ_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.NOTIFICATION_MARK_AS_READ_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case types.NOTIFICATION_MARK_AS_READ_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.notifications.count,
        data: action.payload.notifications.results,
      };

    case types.NOTIFICATION_MARK_AS_UNREAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.NOTIFICATION_MARK_AS_UNREAD_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case types.NOTIFICATION_MARK_AS_UNREAD_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.notifications.count,
        data: action.payload.notifications.results,
      };

    case types.NOTIFICATION_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.NOTIFICATION_DELETE_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case types.NOTIFICATION_DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.notifications.count,
        data: action.payload.notifications.results,
      };

    default:
      return state;
  }
};

export default notifications;

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const GlobeIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM1.6 8C1.6 7.28 1.725 6.59 1.945 5.945L3.2 7.2L4.8 8.8V10.4L6.4 12L7.2 12.8V14.345C4.049 13.949 1.6 11.258 1.6 8ZM13.064 11.898C12.542 11.478 11.75 11.2 11.2 11.2V10.4C11.2 9.97565 11.0314 9.56869 10.7314 9.26863C10.4313 8.96857 10.0243 8.8 9.6 8.8H6.4V6.4C6.82435 6.4 7.23131 6.23143 7.53137 5.93137C7.83143 5.63131 8 5.22435 8 4.8V4H8.8C9.22435 4 9.63131 3.83143 9.93137 3.53137C10.2314 3.23131 10.4 2.82435 10.4 2.4V2.071C12.742 3.022 14.4 5.32 14.4 8C14.3998 9.41163 13.9298 10.783 13.064 11.898Z"
        />
      </svg>
    </span>
  );
};

GlobeIcon.propTypes = {
  className: PropTypes.string,
};

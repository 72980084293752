import { all, put, call, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import periods from "../../api/periods";
import items from "../../api/items";
import * as types from "./periodsActionsTypes";
import {
  getListOfPeriodsSuccess,
  getListOfPeriodsError,
  searchPeriodsRequestError,
  searchPeriodsRequestSuccess,
  getPeriodDataRequestSuccess,
  getPeriodDataRequestError,
  getPeriodItemsRequestSuccess,
  getPeriodItemsRequestError,
} from "./periodsActions";

import storefront from "../../api/storefront";

import serverSagaWrapper from "../../helpers/serverSagaWrapper";
import { checkSeoRedirectSuccess } from "../storefront/storefrontActions";


function *getAllPeriods(action) {
  try {
    const seoResponse = yield call(storefront.checkSeoRedirect, action.payload.pathname.replace("/", ""));
    if (!_.isEmpty(seoResponse)) {
      return yield put(checkSeoRedirectSuccess(seoResponse));
    }

    const response = yield call(periods.getAllPeriods);
    yield put(getListOfPeriodsSuccess(response));
  } catch (error) {
    yield put(getListOfPeriodsError(error.message));
  }
}

function *searchCategories({ payload }) {
  yield put({ type: types.SHOW_SEARCH_LOADING });
  try {
    const response = yield call(periods.searchPeriods, payload);
    yield put(searchPeriodsRequestSuccess(response));
  } catch (error) {
    yield put(searchPeriodsRequestError(error.message));
  } finally {
    yield put({ type: types.HIDE_SEARCH_LOADING });
  }
}

function *getPeriodData({ payload }) {
  yield put({ type: types.SHOW_PERIOD_DATA_LOADING });
  try {
    const seoResponse = yield call(storefront.checkSeoRedirect, payload.pathname);
    if (!_.isEmpty(seoResponse)) {
      return yield put(checkSeoRedirectSuccess(seoResponse));
    }

    const response = yield call(periods.getPeriodData, payload.url);
    yield put(getPeriodDataRequestSuccess(response));
  } catch (error) {
    yield put(getPeriodDataRequestError(error.data.detail));
  } finally {
    yield put({ type: types.HIDE_PERIOD_DATA_LOADING });
  }
}

function *getPeriodItems({ payload }) {
  yield put({ type: types.SHOW_PERIOD_ITEMS_LOADING });
  try {
    const response = yield call(items.get, payload);
    yield put(getPeriodItemsRequestSuccess(response));
  } catch (error) {
    yield put(getPeriodItemsRequestError(error.message));
  } finally {
    yield put({ type: types.HIDE_PERIOD_ITEMS_LOADING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_LIST_OF_PERIODS_REQUEST, serverSagaWrapper(getAllPeriods)),
    yield takeLatest(types.SEARCH_PERIODS_REQUEST, serverSagaWrapper(searchCategories)),
    yield takeLatest(types.GET_PERIOD_DATA_REQUEST, serverSagaWrapper(getPeriodData)),
    yield takeLatest(types.GET_PERIOD_ITEMS_REQUEST, serverSagaWrapper(getPeriodItems)),
  ])
}

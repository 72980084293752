import * as React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


export const GoogleMapsIcon = ({ className, ...rest }) => {
  return (
    <span className={classNames("svg-icon", className)} {...rest}>
      <svg width="1em" height="1em" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5817_53070)">
          <path
            d="M13.6152 27.4874C14.6103 28.7554 15.516 30.0912 16.3256 31.485C17.0169 32.7959 17.3051 33.6847 17.8104 35.2641C18.1202 36.1367 18.4002 36.3971 19.0022 36.3971C19.6583 36.3971 19.956 35.9541 20.1859 35.2689C20.6635 33.7774 21.0382 32.6389 21.6294 31.5634C22.7895 29.4868 24.2309 27.6416 25.6473 25.868C26.0309 25.3661 28.5099 22.442 29.626 20.1347C29.626 20.1347 30.9978 17.6002 30.9978 14.0605C30.9978 10.7494 29.6449 8.453 29.6449 8.453L25.7501 9.49604L23.3847 15.7258L22.7996 16.5849L22.6826 16.7405L22.527 16.9353L22.2537 17.2464L21.8635 17.6367L19.7571 19.3528L14.4912 22.3933L13.6152 27.4874Z"
            fill="#34A853"
          />
          <path
            d="M8.18359 19.7085C9.46878 22.6442 11.9472 25.2247 13.624 27.4894L22.5304 16.9393C22.5304 16.9393 21.2756 18.5803 18.9995 18.5803C16.4643 18.5803 14.4161 16.5558 14.4161 14.003C14.4161 12.2524 15.4693 11.0497 15.4693 11.0497L9.42346 12.6698L8.18359 19.7085Z"
            fill="#FBBC04"
          />
          <path
            d="M22.6279 2.54248C25.5858 3.49623 28.1177 5.49842 29.6491 8.45098L22.5311 16.9332C22.5311 16.9332 23.5843 15.7089 23.5843 13.9692C23.5843 11.3569 21.3846 9.4054 19.0084 9.4054C16.7613 9.4054 15.4707 11.0437 15.4707 11.0437V5.69999L22.6279 2.54248Z"
            fill="#4285F4"
          />
          <path
            d="M9.81445 6.29051C11.5813 4.17671 14.6901 2 18.9758 2C21.0551 2 22.6217 2.54587 22.6217 2.54587L15.4652 11.0484H10.3948L9.81445 6.29051Z"
            fill="#1A73E8"
          />
          <path
            d="M8.18238 19.7085C8.18238 19.7085 7 17.3932 7 14.0388C7 10.8685 8.23243 8.09717 9.81389 6.29047L15.4687 11.0491L8.18238 19.7085Z"
            fill="#EA4335"
          />
        </g>
        <defs>
          <clipPath id="clip0_5817_53070">
            <rect width="24" height="34.4062" fill="white" transform="translate(7 2)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

GoogleMapsIcon.propTypes = {
  className: PropTypes.string,
};
